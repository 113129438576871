import React, { useEffect, useState, useCallback } from 'react';
import { HashRouter as Router, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import Home from './pages/Home';
import Team from './pages/Team';
import Sidebar from './components/Sidebar/Sidebar';
import PlayerViewSidebar from './components/Sidebar/PlayerViewSidebar';
import ViewMatches from './pages/ViewMatches';
import CreatePlayer from './components/DeprecatedFeatures/PlayerCRUD/CreatePlayer';
import UpdatePlayer from './components/DeprecatedFeatures/PlayerCRUD/UpdatePlayer';
import Ladder from './components/DeprecatedFeatures/Ladder/Ladder';
import MatchesList from './components/DeprecatedFeatures/MatchesList/MatchesList';
import UpdateMatch from './components/DeprecatedFeatures/MatchesList/UpdateMatch';
import UpdateDoubles from './components/DeprecatedFeatures/MatchesList/UpdateDoubles';
import PickleballCalendar from './components/Calendar/Calendar';
import Player from './components/Player/Player';
import RoundRobin from './components/DeprecatedFeatures/RoundRobin/RoundRobin';
import RoundRobinTester from './components/DeprecatedFeatures/RoundRobin/RoundRobinTester';
import DJKhaled from './components/DeprecatedFeatures/DJKhaled/DJKhaled';
import SingleElim from './components/DeprecatedFeatures/Tournament/SingleElim';
import Login from './components/Login/Login';
import SignUp from './components/SignUp/SignUp';
import PlayerDashboard from './components/PlayerDashboard/PlayerDashboard';
import Event from './components/Event/AdminView/Event';
import CreateEvent from './components/Event/AdminView/CreateBracket';
import EventPlayerView from './components/Event/PlayerView/BracketPlayerView';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase';
import { PickersActionBar } from '@mui/x-date-pickers';
import UpdateEvent from './components/Event/AdminView/UpdateAndViewBracket';
import Payment from './components/Payment/Payment';
import { makeStyles } from '@material-ui/core/styles'; // Add this import statement
import { useContext } from 'react';
import Bracket from './components/Event/AdminView/Brackets';
import GroupEventPlayerView from './components/Event/PlayerView/EventPlayerView';
import EmbeddedApps from './components/EmbeddedApps/EmbeddedApps';
import CreateGroupEvent from './components/Event/AdminView/CreateEvent';

import Memberships from './components/Memberships/Memberships';
import ViewBracket from './components/Event/PlayerView/ViewBracketRegistration';

const TIMEOUT_DURATION = 60 * 60 * 1000; // 15 minutes in milliseconds

const useIdleTimer = (onTimeout) => {
  let timeoutId;

  const resetTimer = useCallback(() => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(onTimeout, TIMEOUT_DURATION);
  }, [onTimeout]);

  useEffect(() => {
    document.addEventListener('mousemove', resetTimer);
    document.addEventListener('keydown', resetTimer);
    document.addEventListener('scroll', resetTimer);
    document.addEventListener('click', resetTimer);

    return () => {
      clearTimeout(timeoutId);
      document.removeEventListener('mousemove', resetTimer);
      document.removeEventListener('keydown', resetTimer);
      document.removeEventListener('scroll', resetTimer);
      document.removeEventListener('click', resetTimer);
    };
  }, [resetTimer]);
};


const useStyles = makeStyles((theme) => ({
  mainContainer: {
    marginLeft: '200px', // Adjust the sidebar width accordingly
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
    },
  },
}));

const INSTITUTION_PERMISSIONS = {
  'ASU': ['ALL'], 
  'GCU': ['/home', '/players', '/create-matches', '/djkhaled', '/create-player', '/edit-match/:matchId', '/edit-doubles-match/:matchId', '/edit/:playerId', '/ladder', '/all-matches', '/calendar', '/events', '/create-event', '/edit-event/:eventId', '/event-registration'],
  'UofA': ['/home', '/round-robin', '/create-round-robin', '/players', '/create-matches', '/djkhaled', '/create-player', '/edit-match/:matchId', '/edit-doubles-match/:matchId', '/edit/:playerId', '/ladder', '/all-matches', '/events', '/create-event', '/edit-event/:eventId', '/event-registration'],
  'IPA': ['ALL'],
  'DEMO': ['ALL']
};

const MainRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const currentURL = window.location.href;
  const { institutionId, permissions } = React.useContext(InstitutionContext);
  const classes = useStyles();

  const isLoginOrSignUpRoute = ['/login', '/sign-up', '/'].includes(location.pathname);

  const hasPermission = (routePath) => {
    return permissions.includes('ALL') || permissions.includes(routePath);
  }

  const handleTimeout = () => {
    alert('You have been inactive for 15 minutes and will be logged out.');
    console.log('User has been inactive for 15 minutes, logging out...');
    navigate('/login');
  };

  useIdleTimer(handleTimeout);

  

  const shouldShowSidebar = [
    '/home',
    '/dupr',
    '/players',
    '/brackets/:eventId',
    '/create-matches',
    '/create-round-robin',
    '/djkhaled',
    '/round-robin',
    '/create-player',
    '/edit-match/:matchId', // Example dynamic route pattern
    '/edit-doubles-match/:matchId', // Example dynamic route pattern
    '/edit/:playerId', // Example dynamic route pattern
    '/ladder',
    '/all-matches',
    '/calendar',
    '/events',
    '/create-event/:eventId',
    '/edit-event/:eventId',
    '/event-registration',
    '/payment/:eventId',
    '/create-group-event',
    '/bracket-registration/:eventId',
    '/membership',
    '/view-teams/:eventId'
  ];
  
  const shouldShowSidebarPatterns = shouldShowSidebar.map(route => {
    // Replace dynamic segments with regex patterns and escape slashes
    const regexPattern = route.replace(/:\w+/g, '\\d+').replace(/\//g, '\\/');
    return new RegExp(`^${regexPattern}$`);
  });

  // Check if any regex pattern matches the current path
  const shouldDisplaySidebar = shouldShowSidebarPatterns.some(pattern => pattern.test(location.pathname));



  useEffect(() => {
    const unsubscribe = auth.onIdTokenChanged(async (user) => {
      if (user) {
        const token = await user.getIdToken();
        localStorage.setItem('firebaseToken', token);
        setIsAuthenticated(true);
        setUserEmail(user.email);
        localStorage.setItem('isAuthenticated', 'true');
        localStorage.setItem('userEmail', user.email);
        if (location.pathname === '/' || location.pathname === '/sign-up') {
          // Redirect from login or sign-up page to home when already authenticated
          navigate('/home');
        }
      } else {
        setIsAuthenticated(false);
        setUserEmail('');
        localStorage.removeItem('isAuthenticated');
        localStorage.removeItem('userEmail');
        if (location.pathname !== '/' && location.pathname !== '/sign-up') {
          // Redirect to login page if not authenticated
          navigate('/');
        }
      }
    });
  
    return () => unsubscribe();
  }, [navigate, location.pathname]);
  
  


  const canAccessAllRoutes = (userEmail === 'asupickleball@gmail.com') || (userEmail === 'demo.admin@gmail.com') || (userEmail === 'ben@pickleball-insights.com');
  const shouldShowPlayerViewSidebar = !canAccessAllRoutes && userEmail !== '';


  return (
    <>
      {shouldDisplaySidebar && (
        <>
          {shouldShowPlayerViewSidebar ? <PlayerViewSidebar /> : <Sidebar />}
        </>
      )}
      <div className={isLoginOrSignUpRoute ? '' : classes.mainContainer}>
      <Routes>
        {canAccessAllRoutes && (
          <>
            {hasPermission('/players') && <Route path="/players" element={<Player />} />}
            {hasPermission('/create-matches') && <Route path="/create-matches" element={<ViewMatches />} />}
            {hasPermission('/create-round-robin') && <Route path="/create-round-robin" element={<RoundRobin />} />}
            {hasPermission('/djkhaled') && <Route path="/djkhaled" element={<DJKhaled />} />}
            {hasPermission('/round-robin') && <Route path="/round-robin" element={<RoundRobinTester />} />}
            {hasPermission('/create-player') && <Route path="/create-player" element={<CreatePlayer />} />}
            {hasPermission('/edit-match/:matchId') && <Route path="/edit-match/:matchId" element={<UpdateMatch />} />}
            {hasPermission('/edit-doubles-match/:matchId') && <Route path="/edit-doubles-match/:matchId" element={<UpdateDoubles />} />}
            {hasPermission('/edit/:playerId') && <Route path="/edit/:playerId" element={<UpdatePlayer />} />}
            {hasPermission('/all-matches') && <Route path="/all-matches" element={<MatchesList />} />}
            {hasPermission('/events') && <Route path="/events" element={<Event />} />}
            {hasPermission('/brackets/:eventId') && <Route path="/brackets/:eventId" element={<Bracket />} />}
            {hasPermission('/create-event/:eventId') && <Route path="/create-event/:eventId" element={<CreateEvent />} />}
            {hasPermission('/edit-event/:eventId') && <Route path="/edit-event/:eventId" element={<UpdateEvent />} />}
            {hasPermission('/calendar') && <Route path="/calendar" element={<PickleballCalendar />} />}
            {hasPermission('/event-registration') && <Route path="/event-registration" element={<EventPlayerView email_id={userEmail} />} />}
            {hasPermission('/ladder') && <Route path="/ladder" element={<Ladder />} />}
            {hasPermission('/create-group-event') && <Route path="/create-group-event" element={<CreateGroupEvent />} />}
            {hasPermission('/dupr') && <Route path="/dupr" element={<EmbeddedApps url = {"https://mydupr.com/dashboard"}/>} />}
            
          </>
        )}
        <>
          {shouldShowPlayerViewSidebar ? <Route path="/home" element={<PlayerDashboard email={userEmail} />} /> : <Route path="/home" element={<Home />} />}
        </>
        <Route path="/calendar" element={<PickleballCalendar />} />
        
        <Route path="/event-registration" element={<GroupEventPlayerView />} />
        <Route path="/bracket-registration/:eventId" element={<EventPlayerView email_id = {userEmail}/>} />
        <Route path="/ladder" element={<Ladder />} />
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path = "/payment/:eventId" element={<Payment emailId={userEmail} />} />
        <Route path="/dupr" element={<EmbeddedApps url = {"https://mydupr.com/dashboard"}/>} />
        <Route path="/membership" element={<Memberships email={userEmail}/>} />
        <Route path="/view-teams/:eventId" element={<ViewBracket />} />
      </Routes>
      </div>
    </>
  );
};

const InstitutionContext = React.createContext();

const App = () => {
  const classes = useStyles();


  // Check localStorage for an existing institutionId, default to 'IPA' if not found
  const storedInstitutionId = localStorage.getItem('institutionId') || 'ASU';

  const [institutionId, setInstitutionId] = useState(storedInstitutionId);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Update localStorage whenever the institutionId changes
  useEffect(() => {
    localStorage.setItem('institutionId', institutionId);
  }, [institutionId]);

  const institutionPermissions = INSTITUTION_PERMISSIONS[institutionId] || [];

  return (
    <InstitutionContext.Provider value={{ institutionId, permissions: institutionPermissions, setInstitutionId }}>
      <Router>
        <MainRoutes isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      </Router>
    </InstitutionContext.Provider>
  );
};

export default App;
export { InstitutionContext };
