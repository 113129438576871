import React, { useState, useEffect, useContext } from 'react';
import { InstitutionContext } from '../../../App';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import axiosInstance from '../../../axiosInstance';
import makeAnimated from 'react-select/animated';

export default function CreateGroupEvent() {
  const [currentStep, setCurrentStep] = useState(1);

  const [selectedInstitutions, setSelectedInstitutions] = useState([]);
  const animatedComponents = makeAnimated();
  const { institutionId, permissions } = useContext(InstitutionContext);


  const institutionsOptions = [
    { value: 'ASU', label: 'Sun Devil Pickleball Club' },
    { value: 'GCU', label: 'GCU Pickleball Club' },
    { value: 'UofA', label: 'U of A Pickleball Club' },
    { value: 'DEMO', label: 'Demo Institution' },

    // ... you can add more institutions here
  ];

  useEffect(() => {
    if (institutionId) {
      const selected = institutionsOptions.filter(option => institutionId.includes(option.value));
      // console.log(selected)
      setSelectedInstitutions(selected);
    }
  }, [institutionId]);



  const handleInstitutionChange = (selectedOptions) => {
    setSelectedInstitutions(selectedOptions);
  };



  const [formData, setFormData] = useState({
    name: "",
    startdate: "",
    endate: "",
    location: "",
    description: "",
    institutionId: "",
  });
  const navigate = useNavigate();

  const totalSteps = 4; // Adjust based on the number of fields

  // Progress Bar calculation: (Current Step / Total Steps) * 100 to get a percentage
  const progressBarWidth = `${(currentStep / totalSteps) * 100}%`;

  const formatDate = (datetimeStr) => {
    if (!datetimeStr) return "";

    // Create a date object from the datetime-local input value
    const dateObj = new Date(datetimeStr);

    // Options for the toLocaleString to display a more friendly date format
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };

    // Format the date object into a readable string
    return dateObj.toLocaleString("en-US", options);
  };

  const displayDescriptionWithParagraphs = (description) => {
    return description.split('\n').map((text, index) => (
      <p key={index}>{text}</p>
    ));
  };


  const nextStep = () => {
    if (currentStep < totalSteps) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Transform the selected institutions into a comma-separated string of their values


    const selectedInstitutionIds = selectedInstitutions.map(i => i.value).join(', ');
    // console.log(selectedInstitutionIds.length);

    if (selectedInstitutionIds.length === 0) {
      setSelectedInstitutions(institutionId);
      // console.log("here")
    }
    // Update formData with the institutionId from the context
    const updatedFormData = {
      ...formData,
      institutionId: selectedInstitutionIds, // Set institutionId from the context
    };

    // console.log(updatedFormData);


    try {
      console.log(updatedFormData)
      const response = await axiosInstance.post(`/group_event`, updatedFormData);
      // console.log("Success:", response.data);
      navigate("/events");
      // Handle success response
    } catch (error) {
      console.error("Error:", error);
      // Handle errors here
    }
  };

  const goToSummaryStep = () => {
    if (currentStep < totalSteps) {
      setCurrentStep(currentStep + 1);
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <div>
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700 mb-4"
            >
              What's your event called?
            </label>
            <input
              className="w-full px-5 py-3 text-gray-700 bg-gray-200 rounded"
              id="name"
              name="name"
              type="text"
              required
              placeholder="Enter Event Name"
              value={formData.name}
              onChange={handleChange}
            />
          </div>
        );
      case 2:
        return (
          <div>
            <label
              htmlFor="startdate"
              className="block text-sm font-medium text-gray-700 mb-4"
            >
              What time does your event start?
            </label>
            <input
              className="w-full px-5 py-3 text-gray-700 bg-gray-200 rounded"
              id="startdate"
              name="startdate"
              type="datetime-local"
              required
              placeholder="Start Date and Time"
              value={formData.startdate}
              onChange={handleChange}
            />
            <label
              htmlFor="endate"
              className="block text-sm font-medium text-gray-700 mt-4 mb-4"
            >
              What time does your event end?
            </label>
            <input
              className="w-full px-5 py-3 text-gray-700 bg-gray-200 rounded"
              id="endate"
              name="endate"
              type="datetime-local"
              required
              placeholder="End Date and Time"
              value={formData.endate}
              onChange={handleChange}
            />
          </div>
        );
      case 3:
        return (
          <div>
            <label
              htmlFor="location"
              className="block text-sm font-medium text-gray-700 mb-4"
            >
              Where is your event held?
            </label>
            <input
              className="w-full px-5 py-3 text-gray-700 bg-gray-200 rounded"
              id="location"
              name="location"
              type="text"
              required
              placeholder="Enter Event Location"
              value={formData.location}
              onChange={handleChange}
            />
            <div className="mt-4">
              <label
                htmlFor="selectedInstitutions"
                className="block text-sm font-medium text-gray-700 mb-2"
              >
                Are there any other clubs that you want to invite? Remember to include your own club.
              </label>
              <Select
                closeMenuOnSelect={false}
                components={makeAnimated()}
                isMulti
                options={institutionsOptions}
                onChange={handleInstitutionChange}
                placeholder="Search and select institutions..."
                className="text-base w-full border-gray-300 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary25: 'lightblue',
                    primary: 'blue',
                  },
                })}
              />
            </div>
          </div>
        );
      case 4:
        return (
          <div>
            <label
              htmlFor="description"
              className="block text-sm font-medium text-gray-700 mb-4"
            >
              Give a short description for members (minimum 100 characters).
            </label>
            <textarea
              className="w-full px-5 py-3 text-gray-700 bg-gray-200 rounded"
              id="description"
              name="description"
              required
              minLength={100}
              placeholder="Enter a short description of the event"
              value={formData.description}
              onChange={handleChange}
              rows={5} // Set the number of rows to make the textbox bigger
            />
          </div>
        );
      case 5: // Assuming case 5 is your summary step
        return (
          <div>
            <h3 className="text-lg font-medium text-gray-900">
              Summary of your Event
            </h3>
            <ul className="mt-4">
              <li>
                <strong>Event Name:</strong> {formData.name}
              </li>
              <li>
                <strong>Start Date and Time:</strong>{" "}
                {formatDate(formData.startdate)}
              </li>
              <li>
                <strong>End Date and Time:</strong>{" "}
                {formatDate(formData.endate)}
              </li>
              <li>
                <strong>Location:</strong> {formData.location}
              </li>
              <li>
                <strong>Description:</strong> {formData.description}
              </li>
            </ul>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50">
      {/* Title */}
      
      <h1 className="text-4xl font-semibold py-4">Create Group Event</h1>

      <div className="w-full max-w-4xl bg-white rounded-lg shadow-md">
        {" "}
        {/* Increased width to max-w-4xl */}
        <div className="w-full h-2 bg-gray-200">
          <div
            className="h-2 bg-blue-600"
            style={{ width: progressBarWidth }}
          ></div>
        </div>
        <form className="p-8" onSubmit={handleSubmit}>
          <div className="mb-4">{renderStep()}</div>
          <div className="flex justify-between mt-6">
            {currentStep > 1 && (
              <button
                className="inline-flex items-center py-2 px-4 rounded-md text-sm font-medium text-gray-600 bg-gray-300 hover:bg-gray-400"
                type="button"
                onClick={prevStep}
              >
                <span className="material-icons">chevron_left</span>
                Prev
              </button>
            )}
            {currentStep < totalSteps ? (
              <button
                className="inline-flex items-center py-2 px-4 rounded-md text-sm font-medium text-white bg-blue-600 hover:bg-blue-700"
                type="button"
                onClick={goToSummaryStep} // Use the new function for the last "Next" button
              >
                Next
                <span className="material-icons">chevron_right</span>
              </button>
            ) : (
              <button
                className="inline-flex items-center py-2 px-4 rounded-md text-sm font-medium text-white bg-green-600 hover:bg-green-700"
                type="submit"
              >
                Submit
                <span className="material-icons">check</span>
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}
