import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Grid,
  TextField,
  Typography,
  MenuItem,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers';
import { useContext } from 'react';
import { InstitutionContext } from '../../../App';

const useStyles = makeStyles({
  filterContainer: {
    marginBottom: '16px',
  },
  filterOption: {
    marginRight: '16px',
    marginBottom: '16px',
  },
  cardContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '16px',
  },
  card: {
    width: '370px',
    borderRadius: '20px', // Add border radius for rounded corners
    overflow: 'hidden', // Hide any overflow content
  },
  completedCard: {
    backgroundColor: '#e5f7f1', // Light green color for completed matches
  },
  winnerText: {
    fontSize: '1.1rem',
    fontWeight: 'bold',
    marginBottom: '0.5rem',
  },
  opponentText: {
    marginBottom: '0.5rem',
  },
  infoText: {
    fontSize: '0.9rem',
  },
});

const statusOptions = ['ALL', 'COMPLETED', 'INPROGRESS'];
const typeOptions = ['ALL', 'SINGLES', 'DOUBLES'];

function Matches() {
  const [matches, setMatches] = useState([]);
  const [doublesMatches, setDoublesMatches] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [statusText, setStatusText] = useState('ALL');
  const [datetimeFilter, setDatetimeFilter] = useState(null);
  const [matchType, setMatchType] = useState('ALL');
  const {institutionId, permissions} = useContext(InstitutionContext);

  const classes = useStyles();

  useEffect(() => {
    fetchMatches();
  }, []);

  const fetchMatches = async () => {
    try {
      const response = await axios.get(`https://picknats-staging.azurewebsites.net/matches?institutionId=${institutionId}`);
      setMatches(response.data.data);

      const doublesResponse = await axios.get(`https://picknats-staging.azurewebsites.net/doubles_matches?institutionId=${institutionId}`);
      setDoublesMatches(doublesResponse.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const deleteMatch = async (matchId) => {
    try {
      await axios.delete(`https://picknats-staging.azurewebsites.net/matches/${matchId}`);
      setMatches(matches.filter((match) => match.id !== matchId));
    } catch (error) {
      console.error(error);
    }
  };

  const deleteDoublesMatch = async (doubles_matchId) => {
    try {
      await axios.delete(`https://picknats-staging.azurewebsites.net/doubles_matches/${doubles_matchId}`);
      setDoublesMatches(doublesMatches.filter((match) => match.id !== doubles_matchId));
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const handleStatusChange = (event) => {
    setStatusText(event.target.value);
  };

  const handleDatetimeChange = (dateTime) => {
    const selectedDate = dateTime ? dateTime.toDate() : null;
    setDatetimeFilter(selectedDate);
  };

  const applyFilters = () => {
    const filteredMatches = matches.filter((match) => {
      const matchesSearchText =
        (match.opponent1Name &&
          match.opponent1Name.toLowerCase().includes(searchText.toLowerCase())) ||
        (match.opponent2Name &&
          match.opponent2Name.toLowerCase().includes(searchText.toLowerCase()));
      const matchesStatusText =
        statusText === 'ALL' ||
        (match.status && match.status.toLowerCase().includes(statusText.toLowerCase()));
      const matchesDatetime =
        !datetimeFilter ||
        (match.date && new Date(match.date).toDateString() === datetimeFilter.toDateString());
      return matchesSearchText && matchesStatusText && matchesDatetime;
    });

    const filteredDoublesMatches = doublesMatches.filter((doubles_match) => {
      const matchesSearchText =
        (doubles_match.team1Name &&
          doubles_match.team1Name.toLowerCase().includes(searchText.toLowerCase())) ||
        (doubles_match.team2Name &&
          doubles_match.team2Name.toLowerCase().includes(searchText.toLowerCase()));
      const matchesStatusText =
        statusText === 'ALL' ||
        (doubles_match.status && doubles_match.status.toLowerCase().includes(statusText.toLowerCase()));
      const matchesDatetime =
        !datetimeFilter ||
        (doubles_match.date && new Date(doubles_match.date).toDateString() === datetimeFilter.toDateString());
      return matchesSearchText && matchesStatusText && matchesDatetime;
    });

    if (matchType === 'SINGLES') {
      return { matches: filteredMatches, doublesMatches: [] };
    } else if (matchType === 'DOUBLES') {
      return { matches: [], doublesMatches: filteredDoublesMatches };
    } else if (matchType === 'ALL') {
      return { matches: filteredMatches, doublesMatches: filteredDoublesMatches };
    }

    return { matches: [], doublesMatches: [] }; // Return empty arrays if match type is not selected
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Container maxWidth="lg" style={{ paddingTop: '50px' }}>
        <div className={classes.filterContainer}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={6}>
              <TextField
                label="Search Opponent"
                variant="outlined"
                size="small"
                value={searchText}
                onChange={handleSearchChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                label="Search Status"
                variant="outlined"
                size="small"
                select
                value={statusText}
                onChange={handleStatusChange}
                fullWidth
                className={classes.filterOption}
              >
                {statusOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={2}>
              <TextField
                label="Match Type"
                variant="outlined"
                size="small"
                select
                value={matchType}
                onChange={(e) => setMatchType(e.target.value)}
                fullWidth
                className={classes.filterOption}
              >
                {typeOptions.map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={2}>
              <DateTimePicker
                label="Select Date"
                value={datetimeFilter}
                onChange={handleDatetimeChange}
                renderInput={(props) => <TextField {...props} />}
                fullWidth
                className={classes.filterOption}
              />
            </Grid>
          </Grid>
        </div>

        <div className={classes.cardContainer}>
          {applyFilters().matches.map((match) => (
            <Card
              key={match.id}
              className={`${classes.card} ${match.status === 'COMPLETED' ? classes.completedCard : ''}`}
              style={{backgroundColor: `${match.status === 'COMPLETED' ? "#71C562" : '#7EBDC3'}`, borderRadius: "10px"}}
            >
              <CardContent>
                {match.winnerName && (
                  <Typography className={classes.winnerText}>
                    Winner: <strong>{match.winnerName}</strong>
                  </Typography>
                )}
                <Typography className={classes.opponentText}>Opponent 1: {match.opponent1Name}</Typography>
                <Typography className={classes.opponentText}>Opponent 2: {match.opponent2Name}</Typography>
                <Typography className={classes.infoText}>Score: {match.score}</Typography>
                <Typography className={classes.infoText}>
                  Date: {new Date(match.date).toLocaleDateString()}
                </Typography>
                <Typography className={classes.infoText}>Status: {match.status}</Typography>
              </CardContent>
              <CardActions>
                <Button
                  component={Link}
                  to={`/edit-match/${match.id}`}
                  variant="outlined"
                  size="small"
                  style={{ marginRight: '8px' }}
                >
                  Edit
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  style={{ color: 'red' }}
                  onClick={() => deleteMatch(match.id)}
                >
                  Delete
                </Button>
              </CardActions>
            </Card>
          ))}

          {applyFilters().doublesMatches.map((doubles_match) => (
            <Card
              key={doubles_match.id}
              className={`${classes.card} ${doubles_match.status === 'COMPLETED' ? classes.completedCard : ''}`}
              style={{backgroundColor: `${doubles_match.status === 'COMPLETED' ? "#71C562" : '#7EBDC3'}`, borderRadius: "10px"}}
            >
              <CardContent>
                {doubles_match.winnerName && (
                  <Typography className={classes.winnerText}>
                    Winner: <strong>{doubles_match.winnerName}</strong>
                  </Typography>
                )}
                <Typography className={classes.opponentText}>Opponent 1: {doubles_match.team1Name}</Typography>
                <Typography className={classes.infoText}>Opponent 2: {doubles_match.team2Name}</Typography>
                <Typography className={classes.infoText}>Score: {doubles_match.score}</Typography>
                <Typography className={classes.infoText}>
                  Date: {new Date(doubles_match.date).toLocaleDateString()}
                </Typography>
                <Typography className={classes.infoText}>Status: {doubles_match.status}</Typography>
              </CardContent>
              <CardActions>
                <Button
                  component={Link}
                  to={`/edit-doubles-match/${doubles_match.id}`}
                  variant="outlined"
                  size="small"
                  style={{ marginRight: '8px' }}
                >
                  Edit
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  style={{ color: 'red' }}
                  onClick={() => deleteDoublesMatch(doubles_match.id)}
                >
                  Delete
                </Button>
              </CardActions>
            </Card>
          ))}
        </div>
      </Container>
    </LocalizationProvider>
  );
}

export default Matches;
