import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Avatar, Grid, Paper, Typography, makeStyles, Card, CardContent, Divider, Fade, LinearProgress, Box } from '@material-ui/core';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { InstitutionContext } from '../../App';
import { useContext } from 'react';
import { Link } from "react-router-dom"
import axiosInstance from '../../axiosInstance';
ChartJS.register(ArcElement, Tooltip, Legend);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor: '#fbfbfb',
    height: '100vh',
  },
  dashboardTitle: {
    fontWeight: '600',
    color: '#444444',
    letterSpacing: '0.05em',
  },
  profileContainer: {
    padding: theme.spacing(2),
    borderRadius: '15px',
    boxShadow: '0px 4px 15px rgba(55, 64, 161, 0.11)',
    backgroundColor: '#ffffff',
    marginBottom: '15px'
  },
  profileDetails: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  userName: {
    fontWeight: '500',
    color: '#3f51b5',
  },
  userEmail: {
    color: '#777777',
    fontSize: '0.9em',
  },
  statisticsContainer: {
    marginTop: '15px',
    padding: theme.spacing(2),
    borderRadius: '15px',
    boxShadow: '0px 4px 15px rgba(55, 64, 161, 0.11)',
    backgroundColor: '#ffffff',
  },
  statisticsHeader: {
    color: '#444444',
    fontWeight: '600',
    letterSpacing: '0.05em',
  },
  matchesContainer: {
    padding: theme.spacing(2),
    borderRadius: '15px',
    boxShadow: '0px 4px 15px rgba(55, 64, 161, 0.11)',
    backgroundColor: '#ffffff',
    maxHeight: '70vh',
    overflow: 'auto',
  },
  matchesHeader: {
    color: '#444444',
    fontWeight: '600',
    letterSpacing: '0.05em',
  },
  matchCard: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: '10px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.05)',
    backgroundColor: '#ffffff',
    transition: '0.3s',
    '&:hover': {
      transform: 'translateY(-3px)',
      boxShadow: '0px 4px 30px rgba(0, 0, 0, 0.08)',
    },
  },
  matchDate: {
    color: '#777777',
    fontSize: '0.85em',
  },
  notesContainer: {
    padding: theme.spacing(2),
    borderRadius: '15px',
    boxShadow: '0px 4px 15px rgba(55, 64, 161, 0.11)',
    backgroundColor: '#ffffff',
    marginTop: theme.spacing(2),
  },
  notesHeader: {
    color: '#444444',
    fontWeight: '600',
    letterSpacing: '0.05em',
  },
  notesContent: {
    color: '#777777',
    fontSize: '1em',
  },
}));

export default function PlayerDashboard({ email }) {
  const classes = useStyles();
  const [playerData, setPlayerData] = useState(null);
  const [matchesData, setMatchesData] = useState(null);
  const [eventsData, setEventData] = useState(null)
  const {institutionId, permissions} = useContext(InstitutionContext);

  

  const shortcuts = [
    { label: 'Upcoming Events', route: '/event-registration', icon: '🔗', description: 'View upcoming events' },
    { label: 'Calendar', route: '/calendar', icon: '📅', description: 'View your match history' },
    // Add more shortcuts as needed
  ];



  useEffect(() => {
    // console.log(institutionId)
    const fetchData = async () => {
      try {
        const playerResponse = await axiosInstance.get(`/players/email/${email}`);
        const playerData = playerResponse.data;
        setPlayerData(playerData);
        const eventResponse = await axiosInstance.get(`/events/player/${playerData.id}`);
        setEventData(eventResponse.data); // Assuming the response data is correct for events
        // console.log(eventsData)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [email]);// Add playerData as a dependency to re-run the hook when playerData changes
  

  if (!playerData) {
    return <Typography>We're having some trouble retrieving your information. Press another tab in the Sidebar and try again. If you think this is a problem, please contact anindyasharma705@gmail.com</Typography>;
  }

  // Pie chart data and options
  const pieChartData = {
    labels: ['Wins', 'Losses'],
    datasets: [
      {
        data: [playerData.win, playerData.loss],
        backgroundColor: ['#4caf50', '#f44336'],
      },
    ],
  };

  const pieChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
  };

  const filteredEvents = eventsData && eventsData.data ? eventsData.data.filter((event) => !event.isdeleted) : [];

  if (playerData.membership_status === 'Unconfirmed') {
    return <div>Your account is still being confirmed by the club administrators. If you think this is an issue, please contact anindyasharma705@gmail.com</div>
  }
  return (
    <div className="flex flex-wrap justify-center items-start min-h-screen bg-gray-100 p-5">
      {/* Profile and Shortcuts Section */}
      <div className="mt-4 w-full md:w-1/3 lg:w-1/4 p-4">
        <Paper className={classes.profileContainer}>
          {/* Profile Information */}
          <Avatar src={playerData.avatarUrl} className={classes.avatar} />
          <div className={classes.profileDetails}>
            <Typography variant="h5" className={classes.userName}>
              {`${playerData.firstName} ${playerData.lastName}`}
            </Typography>
            <Typography variant="body1" className={classes.userEmail}>
              {playerData.email}
            </Typography>
            <Typography variant="body1" className={classes.userEmail}>
              Current Membership: {playerData.membership_status}
            </Typography>
          </div>
        </Paper>

        {/* Shortcuts */}
        <h4 className="text-2xl font-semibold text-gray-700 mb-4">Shortcuts</h4>
        <div className="space-y-4">
          {shortcuts.map((shortcut, index) => (
            <Link
              to={shortcut.route}
              key={index}
              title={shortcut.description}
              className="block transform hover:scale-105 transition duration-300 ease-in-out"
            >
              <div className="flex items-center p-3 bg-white rounded-lg shadow-md hover:bg-blue-50">
                <div className="text-2xl mr-3">{shortcut.icon}</div>
                <div>
                  <h6 className="font-medium">{shortcut.label}</h6>
                  <p className="text-sm text-gray-600">{shortcut.description}</p>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>

      {/* Main Content Section */}
      <div className="mt-4 w-full md:w-2/3 lg:w-3/4 p-4">
        {/* Upcoming Events Section */}
        <Paper className={classes.matchesContainer}>
          <Typography variant="h6" className={classes.matchesHeader}>
            Your Upcoming Events
          </Typography>
          {filteredEvents.length > 0 ? (
            filteredEvents.map((event, index) => (
              <div key={index} className="my-4 p-3 bg-white rounded-lg shadow-md">
                <Typography variant="body1">{event.name}</Typography>
                <Typography variant="body2">
                  {`Start Date: ${new Date(event.startdate).toLocaleDateString()}`}
                </Typography>
                <Typography variant="body2">{`Location: ${event.location}`}</Typography>
              </div>
            ))
          ) : (
            <Typography variant="body2" className="my-4 p-3">
              No upcoming events. Refresh the page if you just signed up for one.
            </Typography>
          )}
        </Paper>
      </div>
    </div>
  );
}
