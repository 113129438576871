import React, { useContext } from 'react';
import { Select, MenuItem, InputLabel, FormControl, FormHelperText } from '@mui/material';
import { InstitutionContext } from '../../App';

const InstitutionDropdown = () => {
  const { institutionId, setInstitutionId } = useContext(InstitutionContext);

  const handleInstitutionChange = (event) => {
    setInstitutionId(event.target.value);
  };

  return (
    <FormControl fullWidth variant="outlined" margin="normal">
      <InputLabel id="institution-select-label">Select Institution</InputLabel>
      <Select
        labelId="institution-select-label"
        id="institution-select"
        value={institutionId}
        onChange={handleInstitutionChange}
        label="Select Institution" // This ensures the label is correctly animated
        style={{ backgroundColor: 'white', borderRadius: 4 }} // Custom styles
      >
        <MenuItem value="IPA">Intercollegiate Pickleball Association</MenuItem>
        <MenuItem value="ASU">Sun Devil Pickleball Club</MenuItem>
        <MenuItem value="GCU">GCU Pickleball Club</MenuItem>
        <MenuItem value="DEMO">Demo Institution</MenuItem>
        <MenuItem value="UofA">University of Arizona Pickleball Club</MenuItem>

        {/* Add more institutions as needed */}
      </Select>
    </FormControl>
  );
};

export default InstitutionDropdown;
