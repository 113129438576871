import { PaymentElement } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { Button, makeStyles } from '@material-ui/core';
import axios from "axios";
import { InstitutionContext } from '../../App';
import { useContext } from 'react';
import axiosInstance from "../../axiosInstance";


const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: '#4caf50',
    color: 'white',
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
    '&:hover': {
      backgroundColor: '#45a049',
    },
  },
}));

export default function CheckoutForm({ emailId, eventId, teamMembers, teamName }) {
  const stripe = useStripe();
  const elements = useElements();
  const classes = useStyles();
  const [isFull, setIsFull] = useState(false)
  const {institutionId, permissions} = useContext(InstitutionContext);

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const formatTeamData = () => {
    // console.log(teamMembers);
    const playerEmails = teamMembers.map(member => member.email);
    const duprIds = teamMembers.map(member => `DUPR ID: ${member.duprId}, Player Name: ${member.name}`);

    return {
      playerids: duprIds, // Currently ignoring playerIds as per your instruction
      playeremails: playerEmails,
      name: teamName, // Static name for now
      institutionId: institutionId // Replace with actual institutionId if needed
    };
};

  async function getPlayerIds(teamMembers) {
  
    for (const member of teamMembers) {
      try {
        const response = await axiosInstance.get(`/players/email/${member.email}`);
        const data = {
            playerid: response.data.id,
            eventid: eventId,
            structure: "Individual Sign Up For Team"
        }
        await axiosInstance.post(`/ep-map-entry`, data);

      } catch (error) {
        // If there's an Internal Server Error, ignore this email
        if (error.response && error.response.status === 404) {
          // console.log(`Email not found in database: ${member.email}`);
          const ghostPlayerData = {
              firstName: 'GHOST',
              middleInitials: 'G',
              lastName: 'GHOST',
              email: member.email,
              rating: 0.0,
              win: '0',
              loss: '0',
              notes: 'GHOST',
              membership_status: 'GHOST',
              institutionId: institutionId
          }
          // console.log(ghostPlayerData)
          const response = await axiosInstance.post(`/players`, ghostPlayerData);
          const data = {
            playerid: response.data.id,
            eventid: eventId,
            structure: "Individual Sign Up For Team"
          }
          await axiosInstance.post(`/ep-map-entry`, data);
          continue;
        }
        // Handle other potential errors
        throw error;
      }
    }  
  }
  


  useEffect(async () => {
    const event = await axiosInstance.get(`/events/${eventId}`);
    const event_data = event.data;
    if (event_data.capacity === event_data.count) {
      // console.log("full event")
      setIsFull(true)
    } else {
      // console.log("not full")
    }
  }, [])
  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("here");
  
    try {

      const event = await axiosInstance.get(`/events/${eventId}`);
      const event_data = event.data;
      const response = await axiosInstance.get(`/players/email/${emailId}`);
      const player_id = response.data.id;
      // console.log(teamMembers);

      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
  
      setIsProcessing(true);
  
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // return_url: `${window.location.origin}/#/home`,
        },
        redirect: 'if_required'
      });

      if (!error) {
        if (event_data.teamsize == 1) {
          // console.log('individual event bro!')
          const startDate = event_data.startdate
          const formatted = (new Date(startDate).getUTCMonth() + 1).toString().padStart(2, '0') + '/' + new Date(startDate).getUTCDate().toString().padStart(2, '0') + '/' +  new Date(startDate).getUTCFullYear() + ' ' + (new Date(startDate).getUTCHours() % 12 || 12) + ':' + new Date(startDate).getUTCMinutes().toString().padStart(2, '0') + ' ' + (new Date(startDate).getUTCHours() >= 12 ? 'PM' : 'AM');
          // await axios.post(`${process.env.REACT_APP_API_URL}/send_email/${emailId}?event=${event_data.name}&location=${event_data.location}&date=${formatted}`)
          window.location.href = `${window.location.origin}/#/home`;

          // console.log(response.data)
          const data = {
            playerid: player_id,
            eventid: eventId,
            structure: "INDIVIDUAL"
          };
    
        // Attempt to create the entry for the player for the event
          await axiosInstance.post(`/ep-map-entry`, data);
          // If successful, update the count and proceed with payment
          event_data.count = event_data.count + 1;
          await axiosInstance.put(`/events/${eventId}`, event_data);
        } else {
          const startDate = event_data.startdate
          const formatted = (new Date(startDate).getUTCMonth() + 1).toString().padStart(2, '0') + '/' + new Date(startDate).getUTCDate().toString().padStart(2, '0') + '/' +  new Date(startDate).getUTCFullYear() + ' ' + (new Date(startDate).getUTCHours() % 12 || 12) + ':' + new Date(startDate).getUTCMinutes().toString().padStart(2, '0') + ' ' + (new Date(startDate).getUTCHours() >= 12 ? 'PM' : 'AM');
          // await axios.post(`${process.env.REACT_APP_API_URL}/send_email/${emailId}?event=${event_data.name}&location=${event_data.location}&date=${formatted}`)
          window.location.href = `${window.location.origin}/#/home`;
          const teamData = formatTeamData();
          const response = await axiosInstance.post(`/team`, teamData);
          // console.log(response.data)
          const data = {
            playerid: response.data.id,
            eventid: eventId,
            structure: "Team"
          };

    
        // Attempt to create the entry for the player for the event
          await axiosInstance.post(`/ep-map-entry`, data);
          
          // If successful, update the count and proceed with payment
          event_data.count = event_data.count + 1;
          await axiosInstance.put(`/events/${eventId}`, event_data);

          getPlayerIds(teamMembers);
        }
      }  
  
      if (error) {
        if (error.type === "card_error" || error.type === "validation_error") {
          setMessage(error.message);
          // console.log("failure");
        } else {
          setMessage("An unexpected error occurred.");
          // console.log("failure");
        }
      } 
  
      setIsProcessing(false);
    } catch (error) {
      alert("You have already signed up for this event.");
    }
  };
  

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <Button className={classes.button} type="submit" disabled={isProcessing || !stripe || !elements || isFull}>
        {isProcessing ? "Processing ... " : "Pay now"}
      </Button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}
