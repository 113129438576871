import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const Dashboard = () => {
  const shortcuts = [
    { label: 'Players', route: '/players', icon: '👥', description: 'View Players' },
    { label: 'Calendar', route: '/calendar', icon: '📅', description: 'View the calendar' },
    { label: 'Events', route: '/events', icon: '🎉', description: 'View events' },
    { label: 'DUPR', route: '/dupr', icon: '⭐', description: 'View DUPR' },
    // You can add more shortcuts here if needed
  ];

  // External links
  const instagramLink = 'https://www.instagram.com/intercollegiatepickleball/';
  const websiteLink = 'https://ipaofficial.org/';

  useEffect(() => {
  }, []);

  return (
  <div className="flex flex-wrap justify-center items-start min-h-screen bg-gray-100 p-3 pt-10 md:pt-5">
      <div className="w-full md:w-1/3 lg:w-1/4 p-2 md:p-4">
        <h4 className="text-xl md:text-2xl font-semibold text-gray-700 mb-3 md:mb-4">Shortcuts</h4>
        <div className="space-y-3 md:space-y-4">
          {shortcuts.map((shortcut, index) => (
            <Link
              to={shortcut.route}
              key={index}
              title={shortcut.description}
              className="block transform hover:scale-105 transition duration-300 ease-in-out"
            >
              <div className="flex items-center p-3 bg-white rounded-lg shadow-md hover:bg-blue-50">
                <div className="text-2xl mr-3">{shortcut.icon}</div>
                <div>
                  <h6 className="font-medium">{shortcut.label}</h6>
                  <p className="text-sm text-gray-600">{shortcut.description}</p>
                </div>
              </div>
            </Link>
          ))}
          {/* Instagram Link */}
          <a
            href={instagramLink}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center p-3 bg-white rounded-lg shadow-md hover:bg-pink-100"
          >
            <div className="text-2xl mr-3">📸</div>
            <div>
              <h6 className="font-medium">Instagram</h6>
              <p className="text-sm text-gray-600">Our Instagram</p>
            </div>
          </a>
          {/* Website Link */}
          <a
            href={websiteLink}
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center p-3 bg-white rounded-lg shadow-md hover:bg-green-100"
          >
            <div className="text-2xl mr-3">🌐</div>
            <div>
              <h6 className="font-medium">Our Website</h6>
              <p className="text-sm text-gray-600">Website</p>
            </div>
          </a>
          </div>
      </div>
      <div className="w-full h-auto md:w-2/3 lg:w-3/4 p-2 md:p-4">
        <iframe
          src="https://embed.styledcalendar.com/#vZX6UYUPOBBprbjiGQY5"
          title="Calendar"
          className="w-full h-96 md:h-[37rem] rounded-lg shadow-lg border-0"
          allowTransparency="true"
          frameBorder="0"
          scrolling="no"
        ></iframe>
      </div>
    </div>
  );
};

export default Dashboard;
