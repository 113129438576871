import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { MdPerson, MdFileCopy } from "react-icons/md";
import * as XLSX from "xlsx";
import { FaFileAlt } from "react-icons/fa";
import {
  TextField,
  Button,
  Typography,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Event, Person } from "@material-ui/icons";
import "react-datepicker/dist/react-datepicker.css";
import { IconButton } from "@material-ui/core";
import FileCopy from "@material-ui/icons/FileCopy";
import {
  TableCell,
  TableBody,
  TableRow,
  TableContainer,
  Paper,
  Table,
  TableHead,
} from "@material-ui/core";
import axiosInstance from "../../../axiosInstance";

function extractDuprId(playerId) {
  const parts = playerId.split(", ");
  return parts[0].split(": ")[1]; // Assuming the format is always "DUPR ID: [id]"
}

function extractPlayerName(playerId) {
  const parts = playerId.split(", ");
  return parts[1].split(": ")[1]; // Assuming the format is always "Player Name: [name]"
}

const ViewBracket = () => {
  const { eventId } = useParams();
  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [location, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [capacity, setCapacity] = useState("");
  const [cost, setCost] = useState("");
  const [playersData, setPlayersData] = useState([]);
  const [count, setCount] = useState("");
  const [isdeleted, setIsDeleted] = useState("");
  const [memberships, setMemberships] = useState("");
  const [institutionId, setInstitutionId] = useState("");
  const [teamSize, setTeamSize] = useState("");
  const [teamsData, setTeamsData] = useState([]);
  const [copySuccess, setCopySuccess] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    fetchEventData(eventId);
    if (teamSize > 1) {
      fetchTeamsData(eventId);
    } else {
      fetchPlayersData(eventId);
    }
  }, [eventId, teamSize]); // Add teamSize as a dependency

  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    const wsName = "Players Data";

    let data = [];
    if (teamSize > 1) {
      teamsData.forEach((team) => {
        team.playeremails.forEach((email) => {
          data.push({
            TeamName: team.name,
            PlayerEmail: email,
          });
        });
      });
    } else {
      data = playersData.map((player) => ({
        Name: player.firstName + " " + player.lastName,
        Email: player.email,
      }));
    }

    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, wsName);

    XLSX.writeFile(wb, "PlayersData.xlsx");
  };

  const fetchTeamsData = async (eventId) => {
    try {
      const response = await axiosInstance.get(
        `/team/event/${eventId}`
      );
      setTeamsData(response.data.data);
    } catch (error) {
      console.error("Error fetching teams data:", error);
    }
  };

  const copyToClipboard = (text) => {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
  };

  const handleCopyEmails = () => {
    // Convert the player emails into a single string, separated by commas
    const emailString = playersData.map((player) => player.email).join(", ");
    copyToClipboard(emailString);
    setCopySuccess("Emails copied to clipboard!");
  };

  const fetchEventData = async (eventId) => {
    try {
      const response = await axiosInstance.get(
        `/events/${eventId}`
      );
      const eventData = response.data;
      setName(eventData.name);
      setStartDate(eventData.startdate);
      setEndDate(eventData.endate);
      setLocation(eventData.location);
      setDescription(eventData.description);
      setCapacity(eventData.capacity);
      setCost(eventData.cost);
      setCount(eventData.count);
      setIsDeleted(eventData.isdeleted);
      setMemberships(eventData.memberships);
      setInstitutionId(eventData.institutionId);
      setTeamSize(eventData.teamsize);
      // console.log(eventData.teamsize);
    } catch (error) {
      console.error("Error fetching event:", error);
    }
  };

  const fetchPlayersData = async (eventId) => {
    try {
      const response = await axiosInstance.get(
        `/players/event/${eventId}`
      );
      const playersData = response.data.data;
      setPlayersData(playersData);
    } catch (error) {
      console.error("Error fetching players data:", error);
    }
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleLocationChange = (e) => {
    setLocation(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleCapacityChange = (e) => {
    setCapacity(e.target.value);
  };

  const handleCostChange = (e) => {
    setCost(e.target.value);
  };

  const updatedEvent = {
    name: name,
    startdate: startDate,
    endate: endDate,
    location,
    description,
    capacity: Number(capacity),
    cost: Number(cost),
    count: Number(count),
    isdeleted: isdeleted,
    memberships: memberships,
    teamsize: teamSize,
    eventtype: "Placeholder",
    institutionId,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(updatedEvent);
    try {
      // console.log(updatedEvent);
      const url = `/events/${eventId}`;
      await axiosInstance.put(url, updatedEvent);
      navigate("/events");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="container mx-auto max-w-7xl p-8 bg-white shadow-lg rounded-lg">
      <header className="text-4xl font-bold text-gray-800 mb-6">
        Team Registration Information
      </header>
      <div className="overflow-x-auto">
        <table className="min-w-full text-left">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th className="px-4 py-2 text-left">Team Name</th>
              <th className="px-4 py-2 text-left">Player Emails</th>
              <th className="px-4 py-2 text-left">Player Name</th>
            </tr>
          </thead>
          <tbody className="text-sm divide-y divide-gray-200">
            {/* Loop through teams or players */}
            {teamsData.map((team, index) => (
              <React.Fragment key={team.id}>
                {team.playeremails.map((email, emailIndex) => (
                  <tr key={`${team.id}-${emailIndex}`}>
                    {emailIndex === 0 && (
                      <td
                        className="px-4 py-2 text-left"
                        rowSpan={team.playeremails.length}
                      >
                        {team.name}
                      </td>
                    )}
                    <td className="px-4 py-2 text-left">{email}</td>
                    {/* Check if playerIds is not empty and render accordingly */}
                    {team.playerids && team.playerids.length > 0 ? (
                      team.playerids.map((playerId, idIndex) => (
                        <React.Fragment key={idIndex}>
                          {idIndex === emailIndex && (
                            <>
                              <td className="px-4 py-2 text-left">
                                {extractPlayerName(playerId)}
                              </td>
                            </>
                          )}
                        </React.Fragment>
                      ))
                    ) : emailIndex === 0 ? (
                      <React.Fragment>
                        <td className="px-4 py-2 text-left">N/A</td>
                        <td className="px-4 py-2 text-left">N/A</td>
                      </React.Fragment>
                    ) : null}
                    {/* Always show delete button for the first row of each team */}
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ViewBracket;
