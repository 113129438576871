import React from 'react'
import styled from 'styled-components'
import CreatePlayer from '../components/DeprecatedFeatures/PlayerCRUD/CreatePlayer'
import PlayerSearch from '../components/DeprecatedFeatures/MatchCreator/PlayerSearch'

const ViewMatches = () => {
    return (
            <PlayerSearch />
    )
}

export default ViewMatches
