import React, { useState } from 'react';
import axios from 'axios';
import {
  Typography,
  Grid,
  Paper,
  TextField,
  makeStyles,
  Box,
  Button,
} from '@material-ui/core';
import { useContext } from 'react';
import { InstitutionContext } from '../../../App';


const useStyles = makeStyles((theme) => ({
  matchPaper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    backgroundColor: "#D3D3D3",
  },
  playerName: {
    fontWeight: 'bold',
  },
  scoreInput: {
    width: '80px',
    marginRight: theme.spacing(2),
  },
  court: {
    backgroundColor: '#ffffff',
    border: '1px solid #000',
    position: 'relative',
    height: '300px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  courtEndLeft: {
    width: '37.5%',
    backgroundColor: '#3CB371',
    border: '1px dashed #000',
    padding: theme.spacing(0.5),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    position: 'relative',
  },
  courtEndRight: {
    width: '37.5%',
    backgroundColor: '#3CB371',
    border: '1px dashed #000',
    padding: theme.spacing(0.5),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    position: 'relative',
  },
  courtMiddle: { // New class
    width: '25%',
    backgroundColor: '#87CEEB', // Change this color as needed
  },
  midLine: {
    position: 'absolute',
    width: '99%',
    height: '1px',
    backgroundColor: '#000',
    top: '50%',
  },
  net: {
    width: '2px',
    height: '100%',
    backgroundColor: '#000',
    position: 'absolute',
    top: '0',
    left: '50%',
  },
  playerBox: {
    textAlign: 'right',
  },
  scoreBox: {
    backgroundColor: '#fff',
    padding: theme.spacing(0.5),
  },
  endMatchButton: {
    marginTop: theme.spacing(2),
  },
  endedMatch: {
    backgroundColor: theme.palette.success.light,
  },
}));

const Match = ({ match, index, handleScoreChange }) => {
  const classes = useStyles();
  const [isMatchEnded, setIsMatchEnded] = useState(false);
  const [player1Score, setPlayer1Score] = useState('');
  const [player2Score, setPlayer2Score] = useState('');
  const [isScoreValid, setIsScoreValid] = useState(true);
  const {institutionId, permissions} = useContext(InstitutionContext);


  const handleEndMatch = async () => {
    if (player1Score === '' || player2Score === '') {
      setIsScoreValid(false);
      return;
    }
  
    const player1ScoreNum = parseInt(player1Score);
    const player2ScoreNum = parseInt(player2Score);
    const winner = player1ScoreNum > player2ScoreNum ? match.player1.id : match.player2.id;
    const winnerName = player1ScoreNum > player2ScoreNum ? match.player1.firstName + ' ' + match.player1.lastName : 
      match.player2.firstName + ' ' + match.player2.lastName;
    const score = `${player1ScoreNum}-${player2ScoreNum}`;
  
    const result = {
      opponent1: match.player1.id,
      opponent2: match.player2.id,
      score: score,
      winner: winner,
      date: new Date().toISOString(),
      location: 'string', // Update with the actual location if needed
      status: 'COMPLETED',
      opponent1Name: match.player1.firstName + ' ' + match.player1.lastName,
      opponent2Name: match.player2.firstName + ' ' + match.player2.lastName,
      winnerName: winnerName,
      institutionId: institutionId
    };
  
    try {
      const response = await axios.put(`https://picknats-staging.azurewebsites.net/matches/${match.id}`, result);
      // console.log(response.data);
      setIsMatchEnded(true);
    } catch (error) {
      console.error(error);
    }    
  };
  

  const handlePlayer1ScoreChange = (event) => {
    setPlayer1Score(event.target.value);
    handleScoreChange(index, 'player1', event);
    setIsScoreValid(true);
  };

  const handlePlayer2ScoreChange = (event) => {
    setPlayer2Score(event.target.value);
    handleScoreChange(index, 'player2', event);
    setIsScoreValid(true);
  };

  return (
    <Grid item xs={12}>
      <Paper
        elevation={3}
        className={`${classes.matchPaper} ${isMatchEnded && classes.endedMatch}`}
      >
        <Typography variant="h6" align="center">
          Game {index + 1}
        </Typography>
        <Box className={classes.court}>
          <Box className={classes.courtEndLeft}>
            <div className={classes.midLine} />
            <Box className={classes.playerBox}>
              <Typography variant="h6" className={classes.playerName}>
                {match.player1.firstName} {match.player1.lastName}
              </Typography>
              <TextField
                label="P. 1"
                variant="outlined"
                className={classes.scoreInput}
                onChange={handlePlayer1ScoreChange}
                disabled={isMatchEnded}
                error={!isScoreValid && player1Score === ''}
                helperText={!isScoreValid && player1Score === '' ? 'Score is required' : ''}
              />
            </Box>
          </Box>
          <Box className={classes.courtMiddle} /> 
          <Box className={classes.net} />
          <Box className={classes.courtEndRight}>
            <div className={classes.midLine} />
            <Box className={classes.playerBox}>
              <Typography variant="h6" className={classes.playerName}>
                {match.player2.firstName} {match.player2.lastName}
              </Typography>
              <TextField
                label="P. 2"
                variant="outlined"
                className={classes.scoreInput}
                onChange={handlePlayer2ScoreChange}
                disabled={isMatchEnded}
                error={!isScoreValid && player2Score === ''}
                helperText={!isScoreValid && player2Score === '' ? 'Score is required' : ''}
              />
            </Box>
          </Box>
        </Box>
        {!isMatchEnded && (
          <Button
            variant="contained"
            color="primary"
            fullWidth
            className={classes.endMatchButton}
            onClick={handleEndMatch}
            style = {{backgroundColor: "#42A5F5"}}
          >
            End Match
          </Button>
        )}
      </Paper>
    </Grid>
  );
};

export default Match;
