import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Email } from '@mui/icons-material';
import { Box, Button, Card, CardActions, CardContent, Container, Slider, TextField, Typography, Grid } from '@mui/material';
import {CircularProgress} from '@mui/material';
import { InstitutionContext } from '../../App';
import { useContext } from 'react';
import axiosInstance from '../../axiosInstance';


function Player() {
  const [players, setPlayers] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [filters, setFilters] = useState({
    rating: [2, 8],
  });
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Add loading state
  const {institutionId, permissions} = useContext(InstitutionContext);

  
  useEffect(() => {
    
    fetchPlayers();
  }, []);

  const fetchPlayers = async () => {
    try {
      const response = await axiosInstance.get(`/players?institutionalId=${institutionId}`)
      setPlayers(response.data.data);
      setIsLoading(false); // Update loading state when data is fetched

    } catch (error) {
      console.error(error);
      setIsLoading(false); // Update loading state in case of an error

    }
  };

  const deleteUser = async (playerId) => {
    if (window.confirm('Are you sure you want to delete this player?')) {
      try {
        await axiosInstance.delete(`/players/${playerId}`);
        // console.log('Player deleted successfully');
        fetchPlayers();
      } catch (error) {
        console.error(error);
      }
    }
  };

  const applyFilters = () => {
    const filteredPlayers = players.filter((player) => {
      const fullName = `${player.firstName} ${player.lastName}`.toLowerCase();
      const matchesSearchText =
        fullName.includes(searchText.toLowerCase()) || player.email.toLowerCase().includes(searchText.toLowerCase());
      const matchesRating = player.rating >= filters.rating[0] && player.rating <= filters.rating[1];
      return matchesSearchText && matchesRating;
    });
    return filteredPlayers;
  };

  const resetFilters = () => {
    setSearchText('');
    setFilters({
      rating: [2, 5],
    });
    fetchPlayers();
  };

  const handleRatingFilterChange = (event, newValue) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      rating: newValue,
    }));
  };

  const handleEmailPlayers = () => {
    const emailAddresses = selectedPlayers.map((player) => player.email).join(';');
    const subject = 'Selected Players';
    const mailtoLink = `mailto:${encodeURIComponent(emailAddresses)}?subject=${encodeURIComponent(subject)}`;
    window.open(mailtoLink);
  };

  const handleEmailAllPlayers = () => {
    const emailAddresses = players.map((player) => player.email).join(';');
    const subject = 'All Players';
    const mailtoLink = `mailto:${encodeURIComponent(emailAddresses)}?subject=${encodeURIComponent(subject)}`;
    window.open(mailtoLink);
  };

  return (
    <Container maxWidth="lg" style={{ padding: '24px', backgroundColor: '#ffffff', minHeight: '100vh' }}>
      <header className="w-full pt-7 pb-4 text-left text-black text-4xl font-bold ">
        Player Database
      </header>
      <div style={{ marginBottom: '24px', display: 'flex', alignItems: 'center' }}>
        <TextField
          label="Search players"
          variant="outlined"
          size="small"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          style={{ marginRight: '16px', flex: 1 }}
        />
        <div style={{ marginLeft: '15px', marginBottom: '8px', width: '20%' }}>
          <div style={{ marginBottom: '8px' }}>Rating</div>
          <Slider
            value={filters.rating}
            onChange={handleRatingFilterChange}
            valueLabelDisplay="auto"
            min={2}
            max={8}
            step={0.5}
          />
        </div>
      </div>
      <div style={{ height: '500px', overflowY: 'auto' }}>
        {isLoading ? ( // Conditional rendering based on loading state
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
          </div>
        ) : (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        {applyFilters().map((item) => (
          <Card key={item.id} style={{ height: '100%', backgroundColor: '#FFFFFF', boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.15)', borderRadius: '4px', display: 'flex', flexDirection: 'column' }}>
            <CardContent style={{ flexGrow: 1, paddingBottom: '0px !important' }}>
              <Typography style={{ fontSize: '1.2rem', fontWeight: 'bold', color: '#333333', marginBottom: '8px' }}>
                {item.firstName} {item.lastName}
              </Typography>
              <Typography style={{ fontSize: '1rem', color: '#666666', marginBottom: '4px' }}>
                {item.email}
              </Typography>
              <Typography style={{ fontSize: '1rem', color: '#666666', marginBottom: '4px' }}>
                Rating: {item.rating}
              </Typography>
              <Typography style={{ fontSize: '1rem', color: '#666666', marginBottom: '4px' }}>
                Win/Loss: {item.win}/{item.loss}
              </Typography>
              <Typography style={{ fontSize: '1rem', color: '#666666', marginBottom: '4px' }}>
                Membership Status: {item.membership_status}
              </Typography>
            </CardContent>
            <CardActions style={{ padding: '8px', borderTop: '1px solid #E0E0E0', display: 'flex', justifyContent: 'space-between' }}>
              {/* <Button component={Link} to={`/edit/${item.id}`} variant="outlined" size="small">
                View
              </Button> */}
              <Button variant="outlined" size="small" color="error" onClick={() => deleteUser(item.id)}>
                Delete
              </Button>
              <Button
                startIcon={<Email />}
                onClick={() => {
                  if (selectedPlayers.includes(item)) {
                    setSelectedPlayers((prevPlayers) => prevPlayers.filter((player) => player !== item));
                  } else {
                    setSelectedPlayers((prevPlayers) => [...prevPlayers, item]);
                  }
                }}
                style={selectedPlayers.includes(item) ? { color: '#333333', backgroundColor: '#FFC627', borderRadius: '20px', padding: '10px 20px', boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.15)' } : { color: '#FFFFFF', backgroundColor: '#336699', borderRadius: '20px', padding: '10px 20px', boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.15)' }}
                disabled={!item.email}
              >
                {selectedPlayers.includes(item) ? 'Selected' : 'Select'}
              </Button>
            </CardActions>
          </Card>
        ))}
        </div>
        )}</div>
      <Box style={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
        <Button
          variant="contained"
          onClick={handleEmailPlayers}
          style={{
            color: '#FFFFFF',
            backgroundColor: selectedPlayers.length > 0 ? '#336699' : '#7B6C6C', // Change to gray when selectedPlayers.length is 0
            borderRadius: '20px',
            padding: '10px 20px',
            boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.15)',
          }}
          disabled={selectedPlayers.length === 0}
        >
          Email Selected Players
        </Button>
        <Button
          variant="contained"
          onClick={handleEmailAllPlayers}
          style={{ color: '#FFFFFF', backgroundColor: '#336699', borderRadius: '20px', padding: '10px 20px', boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.15)' }}
        >
          Email All Players
        </Button>
        <Button
          variant="contained"
          onClick={resetFilters}
          style={{ color: '#FFFFFF', backgroundColor: '#7B6C6C', borderRadius: '20px', padding: '10px 20px', boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.15)' }}
        >
          Reset Filters
        </Button>
      </Box>
    </Container>
  );
}

export default Player;
