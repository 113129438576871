import React, { useState, useEffect, useContext } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import axiosInstance from "../../axiosInstance";
import { InstitutionContext } from "../../App";
import { useNavigate } from "react-router-dom";

const stripePromise = loadStripe("pk_live_51OVNSfI7JfzA7bw8ANQsG6nKiSmuxSf9rFOkrRUthPiF2Rh31lFENSmZ5pqfED5Dd7rNp6jq1z8WWmLObDPG9u8U00o1NJKvYR");

const CheckoutForm = ({ email, institutionId }) => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate(); // Hook to navigate programmatically
  const [loading, setLoading] = useState(false);  // Loading state
  const [buttonText, setButtonText] = useState("Pay $50"); // New state for button text



  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setButtonText("Processing..."); // Change button text to 'Processing...'

    if (!stripe || !elements) {
      return;
    }
  
    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: { email: email },
    });
  
    if (error) {
      console.log(error);
      // Handle showing the error to the user here
      alert("Your payment didn't go through due to incorrect information. Try again.")
      setLoading(false);
      setButtonText("Pay $50"); // Revert button text on error

    } else {
      try {
        const paymentIntentResponse = await axiosInstance.post(`/create_payment_intent`, {
          amount: 5000,
          email: email,
          name: "Sports Club Member",
          location: "Sun Devil Pickleball Club",
          date: "Spring 2024",
          institutionId: institutionId,
          paymentMethodId: paymentMethod.id,
        });
  
        // Confirm the payment on the client-side
        const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(paymentIntentResponse.data.clientSecret, {
          payment_method: paymentMethod.id,
        });
  
        if (confirmError) {
          alert("Your payment didn't go through due to incorrect information. Try again.")
          setLoading(false);
          setButtonText("Pay $50"); // Revert button text on error
    
          // Handle showing the confirmation error to the user here
        } else if (paymentIntent.status === 'succeeded') {
          updatePlayerMembershipStatus(email, "Premium");
          setLoading(false); // Stop loading in case of an error
        } else {
          setLoading(false);
          setButtonText("Pay $50"); // Revert button text if payment status is not succeeded
        }
      } catch (error) {
        console.error("Error processing payment:", error);
        setLoading(false);
        setButtonText("Pay $50"); // Revert button text if payment status is not succeeded

        // Handle showing the error to the user here
      }
    }
  };
    
  const updatePlayerMembershipStatus = async (playerEmail, newStatus) => {
    try {
      // First, get the current player data
      const getPlayerResponse = await axiosInstance.get(`/players/email/${playerEmail}`);
      const playerData = getPlayerResponse.data;

      // Update the membership status
      const updatedPlayerData = {
        ...playerData,
        membership_status: newStatus
      };

      // Then, send the updated data back to the server
      const updateResponse = await axiosInstance.put(`/players/email/${playerEmail}`, updatedPlayerData);
      console.log("Updated Player Data:", updateResponse.data);

      // Navigate to the /home page after successful update
      navigate('/home');
    } catch (error) {
      console.error("Error updating player membership status:", error);

    }
  };

      
  return (
    <form onSubmit={handleSubmit} className="mt-4">
      <CardElement 
        options={{ style: { base: { fontSize: '16px', color: '#424770', '::placeholder': { color: '#aab7c4' } } } }} 
        className="p-3 bg-white rounded shadow"
      />
      
      <button 
        type="submit" 
        disabled={!stripe}
        className="mt-4 w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        {buttonText}
      </button>
    </form>
  );
};

const MembershipPlans = ({ email }) => {
  const { institutionId } = useContext(InstitutionContext);
  const [playerData, setPlayerData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPlayerData = async () => {
      try {
        const response = await axiosInstance.get(`/players/email/${email}`);
        setPlayerData(response.data);
      } catch (error) {
        console.error("Error fetching player data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPlayerData();
  }, [email]);

  if (loading) {
    return <div>Loading...</div>; // Or any other loading indicator
  }

  if (!playerData || (institutionId !== "DEMO" && institutionId !== "ASU") || playerData.membership_status !== "Basic") {
    return (
      <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center">
        <div className="w-full max-w-md p-6 bg-white rounded-lg shadow-xl text-center">
          <h2 className="text-2xl font-semibold text-gray-800 mb-4">Access Restricted</h2>
          <p className="text-gray-600">
            You are already a Sports Club Member or your institution has not enabled memberships yet.
          </p>
        </div>
      </div>
    );
  }

  const Spinner = () => (
    <div className="flex justify-center items-center">
      <div className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );

  if (loading) {
    return <Spinner />;
  }

  


  return (
    <div className="min-h-screen bg-gray-100 flex flex-col items-center justify-center py-10">
      <div className="w-full max-w-md p-6 bg-white rounded-lg shadow-xl">
        <h2 className="text-2xl font-semibold text-gray-800 text-center">Sports Club Member</h2>
        <p className="mt-2 text-gray-600 text-center">
          If you are a member of the Sun Devil Pickleball Club Team, please pay your membership fee here. Membership fees are valid by semester. The current period is Spring 2024.
        </p>
        <Elements stripe={stripePromise}>
          <CheckoutForm email={email} institutionId={institutionId} />
        </Elements>
      </div>
    </div>
  );

};

export default MembershipPlans;
