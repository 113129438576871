import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { TextField, Button, Typography, Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
}));

const UpdateMatch = () => {
  const { matchId } = useParams();
  const [opponent1, setOpponent1] = useState(0);
  const [opponent2, setOpponent2] = useState(0);
  const [score, setScore] = useState('');
  const [winner, setWinner] = useState(0);
  const [date, setDate] = useState('');
  const [status, setStatus] = useState('');
  const [location, setLocation] = useState('');
  const [opponent1Name, setOpponent1Name] = useState('');
  const [opponent2Name, setOpponent2Name] = useState('');
  const [winnerName, setWinnerName] = useState('');
  const [institutionId, setInstitutionId] = useState('')

  const navigate = useNavigate();
  const classes = useStyles();

  useEffect(() => {
    fetchMatch(matchId);
  }, [matchId]);

  const fetchMatch = async (matchId) => {
    try {
      const response = await fetch(`https://picknats-staging.azurewebsites.net/matches/${matchId}`);
      const match = await response.json();
      setOpponent1(match.opponent1);
      setOpponent2(match.opponent2);
      setScore(match.score);
      setWinner(match.winner);
      setDate(match.date);
      setStatus(match.status);
      setLocation(match.location);
      setOpponent1Name(match.opponent1Name);
      setOpponent2Name(match.opponent2Name);
      setWinnerName(match.winnerName);
      setInstitutionId(match.institutionId)
    } catch (error) {
      console.error('Error fetching match:', error);
    }
  };

  const handleOpponent1Change = (e) => {
    setOpponent1(e.target.value);
  };

  const handleOpponent2Change = (e) => {
    setOpponent2(e.target.value);
  };

  const handleScoreChange = (e) => {
    setScore(e.target.value);
  };

  const handleWinnerChange = (e) => {
    setWinner(e.target.value);
  };

  const handleDateChange = (e) => {
    setDate(e.target.value);
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleLocationChange = (e) => {
    setLocation(e.target.value);
  };

  const handleWinnerNameChange = (e) => {
    setWinnerName(e.target.value);
  };

  const updatedMatch = {
    opponent1,
    opponent2,
    score,
    winner,
    date,
    status,
    location,
    opponent1Name,
    opponent2Name,
    winnerName,
    institutionId
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (updatedMatch.winnerName !== opponent1Name && updatedMatch.winnerName !== opponent2Name) {
      alert("Winner's name must be either Opponent 1's name or Opponent 2's name");
      return;
    }

    if (updatedMatch.status !== 'COMPLETED' && updatedMatch.status !== 'INPROGRESS') {
      alert('Either INPROGRESS or COMPLETED');
      return;
    }

    if (!updatedMatch.opponent1) {
      alert('Please fill in opponent 1');
      return;
    }
    if (!updatedMatch.opponent2) {
      alert('Please fill in opponent 2');
      return;
    }
    if (!updatedMatch.score) {
      alert('Please fill in score');
      return;
    }
    if (!updatedMatch.winner) {
      alert('Please fill in winner');
      return;
    }
    if (!updatedMatch.date) {
      alert('Please fill in date');
      return;
    }
    if (!updatedMatch.status) {
      alert('Please fill in status');
      return;
    }
    if (!updatedMatch.location) {
      alert('Please fill in location');
      return;
    }
    if (!updatedMatch.winnerName) {
      alert("Please fill in winner's name");
      return;
    }
    if (!updatedMatch.opponent1Name) {
      alert("Please fill in opp1's name");
      return;
    }
    if (!updatedMatch.opponent2Name) {
      alert("Please fill in opp2's name");
      return;
    }

    // console.log(updatedMatch);

    try {
      const url = `https://picknats-staging.azurewebsites.net/matches/${matchId}`;
      await axios.put(url, updatedMatch);
      navigate('/all-matches');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container maxWidth="md" className={classes.container}>
      <form className={classes.form} onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Opponent 1 ID"
              variant="outlined"
              fullWidth
              className={classes.textField}
              value={opponent1}
              onChange={handleOpponent1Change}
            />
            <TextField
              label="Opponent 1 Name"
              variant="outlined"
              fullWidth
              className={classes.textField}
              value={opponent1Name}
              onChange={handleOpponent1Change}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Opponent 2 ID"
              variant="outlined"
              fullWidth
              className={classes.textField}
              value={opponent2}
              onChange={handleOpponent2Change}
            />
            <TextField
              label="Opponent 2 Name"
              variant="outlined"
              fullWidth
              className={classes.textField}
              value={opponent2Name}
              onChange={handleOpponent2Change}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
          <TextField
              label="Winner Name"
              variant="outlined"
              fullWidth
              className={classes.textField}
              value={winnerName}
              onChange={handleWinnerNameChange}
            />
            
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Winner"
              variant="outlined"
              fullWidth
              className={classes.textField}
              value={winner}
              onChange={handleWinnerChange}
            />
          </Grid>
          <Grid item xs={12}>
          <TextField
              label="Score"
              variant="outlined"
              fullWidth
              className={classes.textField}
              value={score}
              onChange={handleScoreChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Location"
              variant="outlined"
              fullWidth
              className={classes.textField}
              value={location}
              onChange={handleLocationChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Date"
              variant="outlined"
              fullWidth
              className={classes.textField}
              value={date}
              onChange={handleDateChange}
              disabled
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Status"
              variant="outlined"
              fullWidth
              className={classes.textField}
              value={status}
              onChange={handleStatusChange}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              className={classes.submitButton}
            >
              Update
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default UpdateMatch;
