import React, { useState } from "react";
import { Box, Button } from '@material-ui/core';
import styled from 'styled-components';

const Round = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '0 10px',
});

const PlayerButton = styled(Button)({
  marginBottom: '10px',
});

const TournamentBracket = () => {
  const initialPlayers = ["Player 1", "Player 2", "Player 3", "Player 4", "Player 5", "Player 6", "Player 7", "Player 8"];
  const [bracket, setBracket] = useState([initialPlayers, [], [], []]);

  const advancePlayer = (player, round) => {
    const newBracket = [...bracket];
    newBracket[round+1].push(player);
    setBracket(newBracket);
  }

  return (
    <Box display="flex" justifyContent="space-around">
      <h2>Tournament Bracket</h2>
      {bracket.map((round, i) => (
        <Round key={i}>
          {round.map((player, j) => (
            <PlayerButton 
              variant="contained" 
              color="primary" 
              key={j} 
              onClick={() => advancePlayer(player, i)}
            >
              {player}
            </PlayerButton>
          ))}
        </Round>
      ))}
    </Box>
  );
};

export default TournamentBracket;
