import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MdPerson, MdFileCopy } from "react-icons/md";
import * as XLSX from "xlsx";
import { FaFileAlt } from "react-icons/fa";
import axiosInstance from "../../../axiosInstance";
import {
  TextField,
  Button,
  Typography,
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Event, Person } from "@material-ui/icons";
import "react-datepicker/dist/react-datepicker.css";
import { IconButton } from "@material-ui/core";
import FileCopy from "@material-ui/icons/FileCopy";
import { MdDelete } from "react-icons/md";
import {
  TableCell,
  TableBody,
  TableRow,
  TableContainer,
  Paper,
  Table,
  TableHead,
} from "@material-ui/core";

const UpdateEvent = () => {
  const { eventId } = useParams();
  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [location, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [capacity, setCapacity] = useState("");
  const [cost, setCost] = useState("");
  const [playersData, setPlayersData] = useState([]);
  const [count, setCount] = useState("");
  const [isdeleted, setIsDeleted] = useState("");
  const [memberships, setMemberships] = useState("");
  const [institutionId, setInstitutionId] = useState("");
  const [teamSize, setTeamSize] = useState("");
  const [teamsData, setTeamsData] = useState([]);
  const [copySuccess, setCopySuccess] = useState("");

  const navigate = useNavigate();

  function extractDuprId(playerId) {
    const parts = playerId.split(", ");
    return parts[0].split(": ")[1]; // Assuming the format is always "DUPR ID: [id]"
  }

  function extractPlayerName(playerId) {
    const parts = playerId.split(", ");
    return parts[1].split(": ")[1]; // Assuming the format is always "Player Name: [name]"
  }

  const deleteTeam = async (teamId) => {
    try {
      await axiosInstance.delete(`/event/${eventId}/player/${teamId}?structure=Team`);


      // Retrieve the team to get the player emails
      const team = teamsData.find((team) => team.id === teamId);
      if (!team) {
        console.error("Team not found");
        return;
      }

      // For each email, retrieve the player ID and then delete the player
      for (const email of team.playeremails) {
        try {
          const playerResponse = await axiosInstance.get(`/players/email/${email}`);
          const playerId = playerResponse.data.id; // Assuming the response contains the player's id
      
          await axiosInstance.delete(`/event/${eventId}/player/${playerId}?structure=Individual Sign Up For Team`);
        } catch (error) {
          console.error("Error in processing team player emails:", error);
          // Handle error (e.g., show a message to the user)
        }
      }
      

      // Updating the teamsData state after deletion
      const updatedTeams = teamsData.filter((team) => team.id !== teamId);
      setTeamsData(updatedTeams);

      const updatedEvent = {
        name: name,
        startdate: startDate,
        endate: endDate,
        location,
        description,
        capacity: Number(capacity),
        cost: Number(cost),
        count: Number(count) - 1,
        isdeleted: isdeleted,
        memberships: memberships,
        teamsize: teamSize,
        eventtype: "Placeholder",
        institutionId,
      };
      await axiosInstance.put(`/events/${eventId}`, updatedEvent);
      // Refresh the team list or show a success message
    } catch (error) {
      console.error("Error deleting team:", error);
      // Handle the error, maybe show a message to the user
    }
  };

  useEffect(() => {
    fetchEventData(eventId);
    if (teamSize > 1) {
      fetchTeamsData(eventId);
    } else {
      // console.log("herehereherheh")
      fetchPlayersData(eventId);
    }
  }, [eventId, teamSize]); // Add teamSize as a dependency

  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    const wsName = name + " Player Data";
  
    let data = [];
    if (teamSize > 1) {
      teamsData.forEach((team) => {
        team.playeremails.forEach((email, index) => {
          // Assuming playerids array is parallel to playeremails and contains the player's DUPR ID
          const playerId = team.playerids ? team.playerids[index] : "N/A";
          data.push({
            Team: team.name,
            Email: email,
            Name: extractPlayerName(playerId), // Extract player name from the playerId string
            DUPR_ID: extractDuprId(playerId), // Extract DUPR ID from the playerId string
          });
        });
      });
    } else {
      data = playersData.map((player) => ({
        Name: player.firstName + " " + player.lastName,
        Email: player.email,
        DUPR_ID: player.duprId, // Assuming DUPR ID is a property of the player object
      }));
    }
  
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(wb, ws, wsName);
  
    XLSX.writeFile(wb, name + " Player Data.xlsx");
  };
  

  const fetchTeamsData = async (eventId) => {
    try {
      const response = await axiosInstance.get(`/team/event/${eventId}`);

      setTeamsData(response.data.data);
    } catch (error) {
      console.error("Error fetching teams data:", error);
    }
  };

  const copyToClipboard = (text) => {
    const textarea = document.createElement("textarea");
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
  };

  const handleCopyEmails = () => {
    // Convert the player emails into a single string, separated by commas
    const emailString = playersData.map((player) => player.email).join(", ");
    copyToClipboard(emailString);
    setCopySuccess("Emails copied to clipboard!");
  };

  const fetchEventData = async (eventId) => {
    try {
      const response = await axiosInstance.get(`/events/${eventId}`);

      const eventData = response.data;
      setName(eventData.name);
      setStartDate(eventData.startdate);
      setEndDate(eventData.endate);
      setLocation(eventData.location);
      setDescription(eventData.description);
      setCapacity(eventData.capacity);
      setCost(eventData.cost);
      setCount(eventData.count);
      setIsDeleted(eventData.isdeleted);
      setMemberships(eventData.memberships);
      setInstitutionId(eventData.institutionId);
      setTeamSize(eventData.teamsize);
      // console.log(eventData.teamsize);
    } catch (error) {
      console.error("Error fetching event:", error);
    }
  };

  const fetchPlayersData = async (eventId) => {
    try {
      const response = await axiosInstance.get(`/players/event/${eventId}`);

      const playersData = response.data.data;
      setPlayersData(playersData);
      // console.log(playersData)
    } catch (error) {
      console.error("Error fetching players data:", error);
    }
  };

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleLocationChange = (e) => {
    setLocation(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleCapacityChange = (e) => {
    setCapacity(e.target.value);
  };

  const handleCostChange = (e) => {
    setCost(e.target.value);
  };

  const updatedEvent = {
    name: name,
    startdate: startDate,
    endate: endDate,
    location,
    description,
    capacity: Number(capacity),
    cost: Number(cost),
    count: Number(count),
    isdeleted: isdeleted,
    memberships: memberships,
    teamsize: teamSize,
    eventtype: "Placeholder",
    institutionId,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(updatedEvent);
    try {
      // console.log(updatedEvent);
      const url = `/events/${eventId}`;
    await axiosInstance.put(url, updatedEvent);

      navigate("/events");
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="container mx-auto p-8 bg-white shadow-lg rounded-lg">
      <header className="text-4xl font-bold text-gray-800 mb-6">
        Information for {name}
      </header>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div className="lg:col-span-2">
          <form onSubmit={handleSubmit} className="space-y-6">
            {/* Event Name Input */}
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="eventName"
                >
                  Event Name
                </label>
                <input
                  id="eventName"
                  type="text"
                  placeholder="Enter event name"
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  value={name}
                  onChange={handleNameChange}
                />
              </div>
            </div>

            {/* Date and Time Inputs */}
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="startdate"
                >
                  Start Date and Time
                </label>
                <input
                  id="startdate"
                  type="datetime-local"
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="enddate"
                >
                  End Date and Time
                </label>
                <input
                  id="enddate"
                  type="datetime-local"
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
            </div>

            {/* Location Input */}
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="location"
                >
                  Location
                </label>
                <input
                  id="location"
                  type="text"
                  placeholder="Enter event location"
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  value={location}
                  onChange={handleLocationChange}
                />
              </div>
            </div>

            {/* Description Textarea */}
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="description"
                >
                  Description
                </label>
                <textarea
                  id="description"
                  placeholder="Enter event description"
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  rows="4"
                  value={description}
                  onChange={handleDescriptionChange}
                />
              </div>
            </div>

            {/* Capacity and Cost Inputs */}
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="capacity"
                >
                  Capacity
                </label>
                <input
                  id="capacity"
                  type="text"
                  placeholder="Enter event capacity"
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  value={capacity}
                  onChange={handleCapacityChange}
                />
              </div>
              <div className="w-full md:w-1/2 px-3">
                <label
                  className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                  htmlFor="cost"
                >
                  Cost
                </label>
                <input
                  id="cost"
                  type="text"
                  placeholder="Enter event cost"
                  className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  value={cost}
                  onChange={handleCostChange}
                />
              </div>
            </div>

            {/* Update Event Button */}
            <div className="flex flex-wrap -mx-3 mb-6">
              <div className="w-full px-3">
                <button
                  type="submit"
                  className="w-full p-3 text-white bg-blue-600 rounded-lg hover:bg-blue-700 transition duration-200 ease-in-out transform hover:scale-105"
                >
                  Update Event
                </button>
              </div>
            </div>
          </form>
        </div>

        {/* Registered Players Section */}
        <div className="w-full lg:col-span-2 overflow-x-auto">
          <div className="mb-6">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center">
                <MdPerson className="text-xl text-gray-600" />
                <h5 className="text-xl text-gray-800 ml-2">
                  Registered Players
                </h5>
              </div>
              <button
                onClick={exportToExcel}
                className="p-2 bg-gray-200 rounded-full hover:bg-gray-300 transition duration-200"
              >
                <FaFileAlt className="text-green-600" />
              </button>
            </div>
            {copySuccess && (
              <p className="text-green-600 mb-4">{copySuccess}</p>
            )}
            <div className="overflow-x-auto">
              <div className="max-h-120 overflow-y-auto">
              <table className="min-w-full text-left">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                  <tr>
                    {/* Conditionally render headers */}
                    {teamsData.length > 0 ? (
                      <>
                        <th className="px-4 py-2 text-left">Team Name</th>
                        <th className="px-4 py-2 text-left">Player Emails</th>
                        <th className="px-4 py-2 text-left">DUPR ID</th>
                        <th className="px-4 py-2 text-left">Player Name</th>
                      </>
                    ) : (
                      <>
                        <th className="px-4 py-2 text-left">Name</th>
                        <th className="px-4 py-2 text-left">Email</th>
                        <th className="px-4 py-2 text-left">DUPR ID</th>
                      </>
                    )}
                    <th className="px-4 py-2">Actions</th>
                  </tr>
                </thead>
                <tbody className="text-sm divide-y divide-gray-200">
                  {/* Conditionally render teamsData or playersData */}
                  {teamsData.length > 0 ? (
                    teamsData.map((team, index) => (
                      <React.Fragment key={team.id}>
                        {team.playeremails.map((email, emailIndex) => (
                          <tr key={`${team.id}-${emailIndex}`}>
                            {emailIndex === 0 && (
                              <td
                                className="px-4 py-2 text-left"
                                rowSpan={team.playeremails.length}
                              >
                                {team.name}
                              </td>
                            )}
                            <td className="px-4 py-2 text-left">{email}</td>
                            {/* Check if playerIds is not empty and render accordingly */}
                            {team.playerids && team.playerids.length > 0 ? (
                              team.playerids.map((playerId, idIndex) => (
                                <React.Fragment key={idIndex}>
                                  {idIndex === emailIndex && (
                                    <>
                                      <td className="px-4 py-2 text-left">
                                        {extractDuprId(playerId)}
                                      </td>
                                      <td className="px-4 py-2 text-left">
                                        {extractPlayerName(playerId)}
                                      </td>
                                    </>
                                  )}
                                </React.Fragment>
                              ))
                            ) : emailIndex === 0 ? (
                              <React.Fragment>
                                <td className="px-4 py-2 text-left">N/A</td>
                                <td className="px-4 py-2 text-left">N/A</td>
                              </React.Fragment>
                            ) : null}
                            {/* Always show delete button for the first row of each team */}
                            {emailIndex === 0 && (
                              <td
                                className="px-4 py-2"
                                rowSpan={team.playeremails.length}
                              >
                                <MdDelete
                                  className="cursor-pointer text-red-600 hover:text-red-800"
                                  onClick={() => deleteTeam(team.id)}
                                />
                              </td>
                            )}
                          </tr>
                        ))}
                      </React.Fragment>
                    ))
                  ) : (
                    playersData.map((player, index) => (
                      <tr key={index}>
                        <td className="px-4 py-2 text-left">{player.firstName} {player.lastName}</td>
                        <td className="px-4 py-2 text-left">{player.email}</td>
                        <td className="px-4 py-2 text-left">{player.duprId}</td>
                        <td className="px-4 py-2">
                        {/* <MdDelete
                                  className="cursor-pointer text-red-600 hover:text-red-800"
                                  onClick={() => deleteTeam(team.id)}
                                /> */}
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateEvent;
