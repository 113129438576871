import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { TextField, Button, Typography, Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  textField: {
    marginBottom: theme.spacing(2),
  },
  submitButton: {
    marginTop: theme.spacing(2),
  },
}));

const UpdateDoubles = () => {
  const { matchId } = useParams();
  const [opponent1, setOpponent1] = useState(0);
  const [opponent2, setOpponent2] = useState(0);
  const [winnerID1, setWinnerID1] = useState(0);
  const [winnerID2, setWinnerID2] = useState(0);
  const [score, setScore] = useState('');
  const [winner, setWinner] = useState(0);
  const [date, setDate] = useState('');
  const [status, setStatus] = useState('');
  const [location, setLocation] = useState('');
  const [opponent1Name, setOpponent1Name] = useState('');
  const [opponent2Name, setOpponent2Name] = useState('');
  const [winnerName, setWinnerName] = useState('');
  const [institutionId, setInstitutionId] = useState('');

  const navigate = useNavigate();
  const classes = useStyles();

  useEffect(() => {
    fetchMatch(matchId);
  }, [matchId]);

  const fetchMatch = async (matchId) => {
    try {
      const response = await fetch(`https://picknats-staging.azurewebsites.net/doubles_matches/${matchId}`);
      const match = await response.json();
      setOpponent1(match.team1);
      setOpponent2(match.team2);
      setScore(match.score);
      setWinnerID1(match.winner.player1)
      setWinnerID2(match.winner.player2)
      setDate(match.date);
      setStatus(match.status);
      setLocation(match.location);
      setOpponent1Name(match.team1Name);
      setOpponent2Name(match.team2Name);
      setWinnerName(match.winnerName);
      setInstitutionId(match.institutionId)
    } catch (error) {
      console.error('Error fetching match:', error);
    }
  };

  const handleOpponent1Change = (e) => {
    setOpponent1(e.target.value);
  };

  const handleOpponent2Change = (e) => {
    setOpponent2(e.target.value);
  };

  const handleScoreChange = (e) => {
    setScore(e.target.value);
  };

  const handleWinnerChange = (e) => {
    setWinner(e.target.value);
  };

  const handleDateChange = (e) => {
    setDate(e.target.value);
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleLocationChange = (e) => {
    setLocation(e.target.value);
  };

  const handleWinnerNameChange = (e) => {
    setWinnerName(e.target.value);
  };

  const handleWinnerID1Change = (e) => {
    setWinnerID1(e.target.value);
  };

  const handleWinnerID2Change = (e) => {
    setWinnerID2(e.target.value);
  };

  const updatedMatch = {
    team1: opponent1,
    team2: opponent2,
    score,
    winner: {
        "player1": parseInt(winnerID1),
        "player2": parseInt(winnerID2)
    },
    date,
    location,
    status,
    team1Name: opponent1Name,
    team2Name: opponent2Name,
    winnerName,
    institutionId
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (updatedMatch.winnerName !== opponent1Name && updatedMatch.winnerName !== opponent2Name) {
      alert("Winner's name must be either Opponent 1's name or Opponent 2's name");
      return;
    }
    
    if (!(JSON.stringify(updatedMatch.winner) === JSON.stringify(updatedMatch.team1)) 
        && !(JSON.stringify(updatedMatch.winner) === JSON.stringify(updatedMatch.team2))) {
        alert("Winner's ID must be either Opponent 1's IDs or Opponent 2's IDs");
        return
    }

    if (updatedMatch.status !== 'COMPLETED' && updatedMatch.status !== 'INPROGRESS') {
      alert('Either INPROGRESS or COMPLETED');
      return;
    }

    if (!updatedMatch.team1) {
      alert('Please fill in opponent 1');
      return;
    }
    if (!updatedMatch.team2) {
      alert('Please fill in opponent 2');
      return;
    }
    if (!updatedMatch.score) {
      alert('Please fill in score');
      return;
    }
    if (!updatedMatch.winner) {
      alert('Please fill in winner');
      return;
    }
    if (!updatedMatch.date) {
      alert('Please fill in date');
      return;
    }
    if (!updatedMatch.status) {
      alert('Please fill in status');
      return;
    }
    if (!updatedMatch.location) {
      alert('Please fill in location');
      return;
    }
    if (!updatedMatch.winnerName) {
      alert("Please fill in winner's name");
      return;
    }
    if (!updatedMatch.team1Name) {
      alert("Please fill in opp1's name");
      return;
    }
    if (!updatedMatch.team2Name) {
      alert("Please fill in opp2's name");
      return;
    }

    // console.log(JSON.stringify(updatedMatch));

    try {
      const url = `https://picknats-staging.azurewebsites.net/doubles_matches/${matchId}`;
      await axios.put(url, updatedMatch);
      navigate('/all-matches');
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Container maxWidth="md" className={classes.container}>
      <form className={classes.form} onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" style={{ paddingBottom: '15px' }}>Opponent 1</Typography>
            <TextField
              variant="outlined"
              fullWidth
              className={classes.textField}
              value={opponent1.player1}
            />
            <TextField
              variant="outlined"
              fullWidth
              className={classes.textField}
              value={opponent1.player2}
            />
            <TextField
              label="Opponent 1 Name"
              variant="outlined"
              fullWidth
              className={classes.textField}
              value={opponent1Name}
            />
            <TextField
              label="Winner ID 1"
              variant="outlined"
              fullWidth
              className={classes.textField}
              value={winnerID1}
              onChange={handleWinnerID1Change}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" style={{ paddingBottom: '15px' }}>Opponent 2</Typography>
            <TextField
              variant="outlined"
              fullWidth
              className={classes.textField}
              value={opponent2.player1}
            />
            <TextField
              variant="outlined"
              fullWidth
              className={classes.textField}
              value={opponent2.player2}
            />
            <TextField
              label="Opponent 2 Name"
              variant="outlined"
              fullWidth
              className={classes.textField}
              value={opponent2Name}
            />
            <TextField
                label="Winner ID 2"
                variant="outlined"
                fullWidth
                className={classes.textField}
                value={winnerID2}
                onChange={handleWinnerID2Change}
                />
          </Grid>
        </Grid>
        <TextField
          label="Score"
          variant="outlined"
          fullWidth
          className={classes.textField}
          value={score}
          onChange={handleScoreChange}
        />
        <TextField
          label="Winner Name"
          variant="outlined"
          fullWidth
          className={classes.textField}
          value={winnerName}
          onChange={handleWinnerNameChange}
        />
        <TextField
          label="Date (YYYY-MM-DD)"
          variant="outlined"
          fullWidth
          className={classes.textField}
          value={date}
          onChange={handleDateChange}
        />
        <TextField
          label="Status (INPROGRESS or COMPLETED)"
          variant="outlined"
          fullWidth
          className={classes.textField}
          value={status}
          onChange={handleStatusChange}
        />
        <TextField
          label="Location"
          variant="outlined"
          fullWidth
          className={classes.textField}
          value={location}
          onChange={handleLocationChange}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={classes.submitButton}
        >
          Update Match
        </Button>
      </form>
    </Container>
  );
};

export default UpdateDoubles;
