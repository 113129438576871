import axios from 'axios';

const GLOBAL_API_KEY = process.env.REACT_APP_API_KEY;

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL, // Your common base URL
  headers: {
    'x-api-key': GLOBAL_API_KEY,
  },
});

// Request interceptor
axiosInstance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('firebaseToken'); // Retrieve the token
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token; // Attach token to the header
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
