import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  TextField,
  Button,
  Container,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper as MuiPaper,
  Switch,
  FormControlLabel
} from '@material-ui/core';
import PlayerList from '../PlayersList/PlayerList';
import Matches from './Matches';
import { useContext } from 'react';
import { InstitutionContext } from '../../../App';


const PlayerSearch = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [players, setPlayers] = useState([]);
  const [matches, setMatches] = useState(null); // State to hold the created matches
  const [roundRobins, setRoundRobins] = useState(null);
  const [isDoubles, setIsDoubles] = useState(false);
  const {institutionId, permissions} = useContext(InstitutionContext);

  useEffect(() => {
    fetchPlayers();
  }, []);

  const fetchPlayers = async () => {
    try {
      const response = await axios.get(`https://picknats-staging.azurewebsites.net/players?institutionalId=${institutionId}`);
      setPlayers(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchQuery(value);

    const filteredResults = players.filter(
      (player) =>
        player.firstName.toLowerCase().includes(value.toLowerCase()) ||
        player.lastName.toLowerCase().includes(value.toLowerCase()) ||
        player.email.toLowerCase().includes(value.toLowerCase())
    );

    setSearchResults(filteredResults);
  };

  const toggleDoubles = () => {
    setIsDoubles(!isDoubles);
  };

  const addPlayer = (player) => {
    const isPlayerSelected = selectedPlayers.some((p) => p.id === player.id);

    if (!isPlayerSelected) {
      setSelectedPlayers([...selectedPlayers, player]);
    }
  };

  const removePlayer = (player) => {
    setSelectedPlayers(selectedPlayers.filter((p) => p.id !== player.id));
  };

  const createMatches = async () => {
    const sortedPlayers = selectedPlayers.slice().sort((a, b) => b.win - a.win);
    const pairings = [];

    if (isDoubles) {
      while (sortedPlayers.length >= 4) {
        const team1Player1 = sortedPlayers.pop();
        const team2Player1 = sortedPlayers.pop();
        const team2Player2 = sortedPlayers.pop();
        const team1Player2 = sortedPlayers.pop();

        const result = {
          team1: {
            player1: team1Player1.id,
            player2: team1Player2.id,
          },
          team2: {
            player1: team2Player1.id,
            player2: team2Player2.id,
          },
          score: '0-0',
          winner: {
            player1: 0,
            player2: 0,
          },
          date: new Date().toISOString(),
          location: 'ASU Pickleball Courts', // Update with the actual location if needed
          status: 'INPROGRESS',
          team1Name:
            team1Player1.firstName +
            ' ' +
            team1Player1.lastName +
            ' / ' +
            team1Player2.firstName +
            ' ' +
            team1Player2.lastName,
          team2Name:
            team2Player1.firstName +
            ' ' +
            team2Player1.lastName +
            ' / ' +
            team2Player2.firstName +
            ' ' +
            team2Player2.lastName,
          winnerName: 'UNDECIDED',
          institutionId: institutionId
        };

        try {
          const response = await axios.post('https://picknats-staging.azurewebsites.net/doubles_matches', result);
          // console.log(response.data);

          const updatedData = {
            ...response.data,
            team1: {
              player1: team1Player1,
              player2: team1Player2,
            },
            team2: {
              player1: team2Player1,
              player2: team2Player2,
            },
          };
          pairings.push(updatedData);
        } catch (error) {
          console.error(error);
        }
      }
    } else {
      while (sortedPlayers.length >= 2) {
        const player1 = sortedPlayers.pop();
        const player2 = sortedPlayers.pop();

        const result = {
          opponent1: player1.id,
          opponent2: player2.id,
          score: '0-0',
          winner: 0,
          date: new Date().toISOString(),
          location: 'ASU Pickleball Courts', // Update with the actual location if needed
          status: 'INPROGRESS',
          opponent1Name: player1.firstName + ' ' + player1.lastName,
          opponent2Name: player2.firstName + ' ' + player2.lastName,
          winnerName: 'UNDECIDED',
          institutionId: institutionId
        };

        try {
          const response = await axios.post('https://picknats-staging.azurewebsites.net/matches', result);
          // console.log(response.data);

          const updatedData = {
            ...response.data,
            player1: player1,
            player2: player2,
          };
          pairings.push(updatedData);
        } catch (error) {
          console.error(error);
        }
      }
    }
    setMatches(pairings);
  };

  if (matches) {
    return <Matches matches={matches} isDouble={isDoubles} />;
  }

  const isButtonDisabled = (isDoubles && selectedPlayers.length < 4) || (!isDoubles && selectedPlayers.length < 2);

  return (
    <Container maxWidth="md" style={{ paddingTop: '20px' }}>
      <div style={{ background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)', padding: '24px', borderRadius: '10px', marginBottom: '32px' }}>
        <Typography variant="h4" component="h1" align="center" style={{ color: '#ffffff', marginTop: '16px' }}>
          Organize Ladder Matches
        </Typography>
        <Typography variant="subtitle1" component="p" align="center" style={{ color: '#ffffff', marginTop: '8px' }}>
          Ladder matches are a competitive format where players or teams are ranked on a ladder based on their performance.
          Matches are organized between players based on their rankings.
        </Typography>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            type="text"
            label="Search players"
            variant="outlined"
            fullWidth
            value={searchQuery}
            onChange={handleSearch}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Switch checked={isDoubles} onChange={toggleDoubles} />}
            label="Doubles Matches"
            style={{ alignSelf: 'flex-end' }}
          />
        </Grid>
        <Grid item xs={12}>
          <PlayerList title="Search Results" players={searchResults} onPlayerClick={addPlayer} buttonLabel="Add" />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>
            Selected Players
          </Typography>
          <PlayerList title="Selected Players" players={selectedPlayers} onPlayerClick={removePlayer} buttonLabel="Remove" />
        </Grid>
        <Grid item xs={12}>
          <div style={{ marginBottom: '16px' }}>
            <Button
              variant="contained"
              color="primary"
              fullWidth
              disabled={isButtonDisabled}
              onClick={createMatches}
              style={{
                background: isButtonDisabled ? 'gray' : 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
                color: '#ffffff',
              }}
            >
              Create Ladder Matches
            </Button>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PlayerSearch;
