import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import {
  TextField, Button, Typography, Container, Grid, Paper,
  makeStyles, Avatar, CircularProgress
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';


const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4), // Add bottom padding
    backgroundColor: '#FFFFFF',
    minHeight: '100vh',
  },
  paper: {
    padding: theme.spacing(4),
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.15)',
  },

  infoText: {
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
  },
  editButton: {
    marginRight: theme.spacing(2),
    backgroundColor: '#FF9800',
    '&:hover': {
      backgroundColor: '#FFB74D',
    },
  },
  cancelButton: {
    backgroundColor: '#F44336',
    '&:hover': {
      backgroundColor: '#D32F2F',
    },
  },
  saveButton: {
    backgroundColor: '#42A5F5',
    '&:hover': {
      backgroundColor: '#388E3C',
    },
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    marginBottom: theme.spacing(2),
  },
  saveButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  buttonContainerPadding: {
    padding: theme.spacing(2), // Add padding to button containers
  },
  inputField: {
    marginBottom: theme.spacing(2), // Add margin to form inputs
  },
  savedTag: {
    marginLeft: theme.spacing(2),
    color: '#4CAF50',
    fontWeight: 'bold',
  },
  playerInfoContainer: {
    marginBottom: theme.spacing(4.5), // Add margin between containers
  },
  notesContainer: {
    marginTop: theme.spacing(4), // Add margin between containers
  },
}));

const PlayerProfile = () => {
  const { playerId } = useParams();
  const [player, setPlayer] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [notes, setNotes] = useState('');
  const classes = useStyles();

  useEffect(() => {
    fetchPlayer(playerId);
  }, [playerId]);

  const fetchPlayer = async (playerId) => {
    try {
      const response = await fetch(`https://picknats-staging.azurewebsites.net/players/${playerId}`);
      const playerData = await response.json();
      setPlayer(playerData);
      setNotes(playerData.notes);
    } catch (error) {
      console.error('Error fetching player:', error);
    }
  };

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleCancelClick = () => {
    setEditMode(false);
  };

  const handleSaveClick = async () => {
    try {
      const updatedPlayer = {
        ...player,
        notes: notes,
      };
      await axios.put(`https://picknats-staging.azurewebsites.net/players/${playerId}`, updatedPlayer);
      setEditMode(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPlayer((prevPlayer) => ({
      ...prevPlayer,
      [name]: value,
    }));
  };

  const handleNotesChange = (e) => {
    setNotes(e.target.value);
  };

  return (
    <Container maxWidth="lg" className={classes.container}>
      {player ? (
        <Grid container spacing={4}>
          
          {/* This column contains the Player Information and Notes */}
          <Grid item xs={12} md={6}>

            {/* Player Information */}
            <Paper className={`${classes.paper} ${classes.playerInfoContainer}`}>
              <Avatar src={player.profilePicture} alt="Profile Picture" className={classes.avatar} />
              <Typography variant="h4" component="h1" className={classes.infoText}>
                Player Information
              </Typography>
              <Typography variant="body1"><strong>Name:</strong> {player.firstName} {player.middleInitials} {player.lastName}</Typography>
              <Typography variant="body1"><strong>Email:</strong> {player.email}</Typography>
              <Typography variant="body1"><strong>Rating:</strong> {player.rating}</Typography>
              <Typography variant="body1"><strong>W/L%:</strong> {player.win / (parseInt(player.win) + parseInt(player.loss))}</Typography>
            </Paper>

            {/* Notes Container */}
            <Paper className={`${classes.paper} ${classes.notesContainer}`}>
              <TextField
                label="Notes"
                variant="outlined"
                fullWidth
                multiline
                rows={8}
                value={notes}
                onChange={handleNotesChange}
                disabled={!editMode}
              />
              <div className={classes.saveButtonContainer}>
                {!editMode && <span className={classes.savedTag}>Saved</span>}
              </div>
            </Paper>
          </Grid>

          <Grid item xs={12} md={6}>
            <Paper className={classes.paper}>
              <Typography variant="h4" component="h1" className={classes.infoText}>
                Edit Player
              </Typography>
              <form noValidate autoComplete="off">
                <TextField
                  label="First Name"
                  variant="outlined"
                  fullWidth
                  name="firstName"
                  value={player.firstName}
                  onChange={handleInputChange}
                  disabled={!editMode}
                  margin="normal"  // Add this

                />
                <TextField
                  label="Middle Initials"
                  variant="outlined"
                  fullWidth
                  name="middleInitials"
                  value={player.middleInitials}
                  onChange={handleInputChange}
                  disabled={!editMode}
                  margin="normal"  // Add this

                />
                <TextField
                  label="Last Name"
                  variant="outlined"
                  fullWidth
                  name="lastName"
                  value={player.lastName}
                  onChange={handleInputChange}
                  disabled={!editMode}
                  margin="normal"  // Add this

                />
                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  name="email"
                  value={player.email}
                  onChange={handleInputChange}
                  disabled={!editMode}
                  margin="normal"  // Add this

                />
                <TextField
                  label="Rating"
                  variant="outlined"
                  fullWidth
                  name="rating"
                  value={player.rating}
                  onChange={handleInputChange}
                  disabled={!editMode}
                  margin="normal"  // Add this

                />
                <TextField
                  label="Membership Status"
                  variant="outlined"
                  fullWidth
                  name="membership_status"
                  value={player.membership_status}
                  onChange={handleInputChange}
                  disabled={!editMode}
                  margin="normal"  // Add this

                />
              {editMode ? (
                  <>
                    <Button
                      variant="contained"
                      startIcon={<SaveIcon />}
                      className={classes.saveButton}
                      onClick={handleSaveClick}
                    >
                      Save Profile
                    </Button>
                  </>
                ) : (
                  <Button
                    variant="contained"
                    startIcon={<EditIcon />}
                    className={classes.editButton}
                    onClick={handleEditClick}
                  >
                    Edit Profile
                  </Button>
                )}
              </form>
            </Paper>
            </Grid>
            </Grid>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </div>
      )}
    </Container>
  );
}

export default PlayerProfile
