import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: 900,
    margin: '0 auto',
    marginTop: 40,
    justifyContent: 'center',
  },
  card: {
    width: 300,
    height: 220,
    margin: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 16,
    boxShadow: '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)',
    transition: 'box-shadow 0.3s ease-in-out',
    '&:hover': {
      boxShadow: '0 6px 12px rgba(0, 0, 0, 0.16), 0 6px 12px rgba(0, 0, 0, 0.23)',
    },
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    color: '#fff',
  },
  cardContent: {
    textAlign: 'center',
  },
  cardTitle: {
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
  },
  cardDescription: {
    fontSize: 16,
    marginBottom: theme.spacing(2),
  },
  link: {
    textDecoration: 'none',
  },
  comingSoonContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 40,
    fontFamily: 'PickleballFont, sans-serif', // Replace with the custom font name
  },
  comingSoonEmoji: {
    fontSize: 60,
  },
}));

const RoundRobinCard = ({ title, description, link }) => {
  const classes = useStyles();

  return (
    <Link to={link} className={classes.link}>
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <Typography variant="h5" component="h2" className={classes.cardTitle}>
            {title}
          </Typography>
          <Typography color="inherit" className={classes.cardDescription}>
            {description}
          </Typography>
        </CardContent>
      </Card>
    </Link>
  );
};

const RoundRobinCards = () => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.cardContainer}>
        <RoundRobinCard
          title="8-11 Players"
          description="A Round Robin format for 8-11 players, ideal for 2 courts, ensuring each player plays with and against different partners in each match."
          link="/round-robin"
        />
        <RoundRobinCard
          title="12-15 Players"
          description="A Round Robin format for 12-15 players, optimal for 3 courts, providing an opportunity for all players to compete with various partners and opponents."
          link="/round-robin"
        />
        <RoundRobinCard
          title="16-18 Players"
          description="A Round Robin format for 16-18 players, suitable for 4 courts, allowing participants to experience diverse partnerships and challenging matchups."
          link="/round-robin"
        />
      </div>
      <div className={classes.comingSoonContainer}>
        <Typography color="inherit" className={classes.cardDescription}>
          🔮 Stay tuned for additional Round Robin formats for larger player groups.
        </Typography>
      </div>
    </>
  );
};

export default RoundRobinCards;
