import React, { useState } from 'react';
import { TextField, Button, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Typography, Box, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import roundRobinTemplates from './roundRobinTemplates';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    textAlign: 'center',
    backgroundColor: '#f5f5f5',
  },
  container: {
    width: '60%',
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(3),
    borderRadius: theme.shape.borderRadius,
  },
  button: {
    marginTop: theme.spacing(3),
  },
  table: {
    marginTop: theme.spacing(3),
  },
  player: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function RoundRobinSchedule({ schedule }) {
  const classes = useStyles();
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Round</TableCell>
            <TableCell>Court</TableCell>
            <TableCell>Team One</TableCell>
            <TableCell>Team Two</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {schedule.map((round, roundIndex) => round.courts.map((court, courtIndex) => (
            <TableRow key={`${roundIndex}-${courtIndex}`}>
              {courtIndex === 0 && <TableCell rowSpan={round.courts.length}>{roundIndex + 1}</TableCell>}
              <TableCell>{court.court}</TableCell>
              <TableCell>{court.teamOne.join(' & ')}</TableCell>
              <TableCell>{court.teamTwo.join(' & ')}</TableCell>
            </TableRow>
          )))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default function RoundRobinTester() {
  const classes = useStyles();

  const [inputPlayers, setInputPlayers] = useState('');
  const [players, setPlayers] = useState([]);
  const [schedule, setSchedule] = useState([]);

  const handleGenerate = () => {
    const playersList = inputPlayers.split(',').map(item => item.trim());
    let selectedTemplate = roundRobinTemplates.find(template => template.players === playersList.length);

    if (!selectedTemplate) {
      alert("We only accept 8, 9, 10, 11, or 12 players.");
      return;
    }

    setPlayers(playersList);
    const newSchedule = selectedTemplate.schedule.map((round) => {
      const { courts } = round;
      return {
        courts: courts.map((court) => {
          const { teamOne, teamTwo } = court;
          return {
            court: court.court,
            teamOne: teamOne.map((player) => playersList[parseInt(player.replace('Player ', '')) - 1]),
            teamTwo: teamTwo.map((player) => playersList[parseInt(player.replace('Player ', '')) - 1]),
          };
        }),
      };
    });
    setSchedule(newSchedule);
  };

  
  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              value={inputPlayers}
              onChange={(e) => setInputPlayers(e.target.value)}
              label="Enter Players"
              variant="outlined"
              fullWidth
              helperText="Enter players' names separated by commas."
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={handleGenerate}
              fullWidth
              style = {{
                backgroundColor: '#0D47A1'
              }}
            >
              Generate Schedule
            </Button>
          </Grid>
        </Grid>
        {schedule.length > 0 && (
          <Box mt={3}>
            <Typography variant="h5" className={classes.player}>Players</Typography>
            <Grid container spacing={3}>
              {players.map((player, index) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                  <Paper elevation={2}>
                    <Box p={2}>
                      <Typography variant="h6">{`Player ${index + 1}`}</Typography>
                      <Typography variant="subtitle1">{player}</Typography>
                    </Box>
                  </Paper>
                </Grid>
              ))}
            </Grid>
            <Typography variant="h5" className={classes.player}>Schedule</Typography>
            <RoundRobinSchedule schedule={schedule} />
          </Box>
        )}
      </Box>
    </Box>
  );
}