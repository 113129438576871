// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getAuth } from "firebase/auth";


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAvmX47WfC4UBLJzIamXfnmk4OId8ep_4E",
  authDomain: "collegiate-pickleball-hub.firebaseapp.com",
  projectId: "collegiate-pickleball-hub",
  storageBucket: "collegiate-pickleball-hub.appspot.com",
  messagingSenderId: "1019204200395",
  appId: "1:1019204200395:web:14b785e01e47769504384e"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export default app;
