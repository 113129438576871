import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
  Divider,
  Fade,
} from '@material-ui/core';
import { useContext } from 'react';
import { InstitutionContext } from '../../../App';

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 600,
    margin: '0 auto',
    padding: theme.spacing(4),
  },
  rankingItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  rankNumber: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
  },
  gold: {
    backgroundColor: '#ffe082',
  },
  silver: {
    backgroundColor: '#e0e0e0',
  },
  bronze: {
    backgroundColor: '#dca770',
  },
  scrollableContainer: {
    maxHeight: 750,
    overflow: 'auto',
  },
}));

function PlayerRanking() {
  const classes = useStyles();
  const [players, setPlayers] = useState([]);
  const [showRanking, setShowRanking] = useState(false);
  const {institutionId, permissions} = useContext(InstitutionContext);

  useEffect(() => {
    fetchPlayerData();
  }, []);

  const fetchPlayerData = async () => {
    try {
      const response = await axios.get(`https://picknats-staging.azurewebsites.net/players?institutionalId=${institutionId}`);
      const playerData = response.data.data;
      calculateRanking(playerData);
    } catch (error) {
      console.error(error);
    }
  };

  const calculateRanking = (playerData) => {
    // Same ranking calculation logic
    const rankedPlayers = playerData.map((player) => {
      const win = parseInt(player.win);
      const loss = parseInt(player.loss);
      const totalGames = win + loss;
      const winLossPercentage = totalGames > 0 ? parseFloat(((win / totalGames) * 100).toFixed(2)) : 0;

      return {
        ...player,
        winLossPercentage,
      };
    });

    rankedPlayers.sort((a, b) => {
      if (b.win !== a.win) {
        return b.win - a.win;
      }
      return a.loss - b.loss;
    });

    setPlayers(rankedPlayers);
    setShowRanking(true);
  };

  const getPlayerRankColor = (index) => {
    if (index === 0) return classes.gold;
    if (index === 1) return classes.silver;
    if (index === 2) return classes.bronze;
    return '';
  };

  return (
    <div className={classes.container}>
      <Fade in={showRanking}>
        <div>
          <Typography variant="h4" gutterBottom>
            Player Rankings (Based on Wins)
          </Typography>
          <div className={classes.scrollableContainer}>
            <List>
              {players.map((player, index) => (
                <React.Fragment key={player.id}>
                  {index !== 0 && <Divider />}
                  <ListItem className={`${classes.rankingItem} ${getPlayerRankColor(index)}`}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item>
                        <Typography className={classes.rankNumber}>{index + 1}</Typography>
                      </Grid>
                      <Grid item xs>
                        <ListItemText
                          primary={`${player.firstName} ${player.lastName}`}
                          secondary={`${player.win} Wins and ${player.loss} Losses`}
                        />
                      </Grid>
                    </Grid>
                  </ListItem>
                </React.Fragment>
              ))}
            </List>
          </div>
        </div>
      </Fade>
    </div>
  );
}

export default PlayerRanking;
