import React, { useEffect, useState } from 'react';
// import axios from 'axios';
import axiosInstance from '../../../axiosInstance';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardHeader, CardMedia, CardContent, CardActions, Avatar, IconButton, Typography, Collapse, Grid, Container, CircularProgress, Button } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteIcon from '@material-ui/icons/Delete'; // Import the DeleteIcon
import { useNavigate } from "react-router-dom";
import clsx from 'clsx';
import moment from 'moment';
import { InstitutionContext } from '../../../App';
import { useContext } from 'react';

const images = [
  "image1.jpeg",
  "image2.jpeg",
  "image3.jpeg",
  "image4.jpeg",
  "image5.jpeg",
  "image6.jpeg",
];

function getRandomImage() {
  const randomIndex = Math.floor(Math.random() * images.length);
  return images[randomIndex];
}

const useStyles = makeStyles((theme) => ({
  loading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '80vh',
  },
}));

function renderDescription(description) {
  return description.split('\n').map((line, index) => (
    <span key={index}>
      {line}
      <br />
    </span>
  ));
}

export default function Bracket() {
  const { eventId } = useParams();
  const classes = useStyles();
  const navigate = useNavigate();

  const [expandedId, setExpandedId] = useState(-1);
  const [events, setEvents] = useState([]);
  const [eventName, setEventName] = useState("");
  const [loading, setLoading] = useState(true);
  const {institutionId, permissions} = useContext(InstitutionContext);


  const handleExpandClick = (id) => {
    // console.log(eventId);
    setExpandedId(expandedId === id ? -1 : id);
  };

  const handleDeleteEvent = (event_id) => {
    axiosInstance.delete(`/events/${event_id}`)
      .then(response => {
        setEvents(prevEvents => prevEvents.map(event => {
          if (event.id === event_id) {
            return { ...event, isdeleted: true };
          }
          return event;
        }));
      })
      .catch(error => {
        console.error('Error deleting the event!', error);
      });
  };

  useEffect(() => {
    axiosInstance.get(`/group_event/${eventId}`)
    .then(response => {
        setEventName(response.data.name);
        // console.log("eventName")
        setLoading(false);
      })
      .catch(error => {
        console.error('There was an error!', error);
      });


      axiosInstance.get(`/events/group_event/${eventId}`)
      .then(response => {
        setEvents(response.data.data);
        setLoading(false);
      })
      .catch(error => {
        console.error('There was an error!', error);
      });
  }, []);

  if (loading) {
    return <div className={classes.loading}><CircularProgress /></div>;
  }

  return (
    <div className="bg-gray-100 min-h-screen p-8">
      <div className="flex justify-between items-center mb-6">
        <header className="text-4xl font-bold text-black">
          Brackets for {eventName}
        </header>
        <button
          className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
          onClick={() => navigate(`/create-event/${eventId}`)}
        >
          Create Bracket
        </button>
      </div>

      {/* Main content container */}
      <div className="container mx-auto">
        {/* Grid container */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {/* Map over events */}
          {events.map((event, index) => (
            <div key={event.id} className={`rounded-lg overflow-hidden shadow-lg ${event.isdeleted ? 'opacity-50' : ''}`}>
              {event.isdeleted && (
                <p className="text-red-500 text-xs italic p-2">This event has been deleted</p>
              )}
              <div className="flex items-center p-4">
                <div className="rounded-full h-10 w-10 flex items-center justify-center bg-blue-500 text-white mr-4">
                  {event.name.charAt(0)}
                </div>
                <div>
                  <h5 className="text-xl font-bold">{event.name}</h5>
                  <p className="text-sm text-gray-600">{moment(event.startdate).format('MMMM Do, h:mm a')}</p>
                </div>
              </div>
              <img className="w-full h-48 object-cover" src={getRandomImage()} alt={event.name} />
              <div className="p-4">
                <p className="text-gray-700 text-base">{renderDescription(event.description)}</p>
                <p className="text-gray-600 text-sm mt-2">Location: {event.location}</p>
                <p className="text-gray-600 text-sm">Ends on: {moment(event.endate).format('MMMM Do, h:mm a')}</p>
                <p className="text-gray-600 text-sm">Capacity: {event.capacity}</p>
                <p className="text-gray-600 text-sm">Cost Per Team: ${event.cost}</p>
                <p className="text-gray-600 text-sm">Cost Per Player: ${event.cost / event.teamsize}</p>
                <div className="flex justify-between items-center mt-4">
                  <span className="text-gray-500 text-sm">{event.count} / {event.capacity}</span>
                  <button
                    className="text-gray-500 hover:text-gray-700"
                    onClick={() => handleDeleteEvent(event.id)}
                  >
                    {/* SVG for delete icon */}
                  </button>
                </div>
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-full mt-4"
                  onClick={() => navigate(`/edit-event/${event.id}`)}
                >
                  View Registration
                </button>
                <button
                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded w-full mt-4"
                  onClick={() => handleDeleteEvent(event.id)}
                  >
                  Delete Bracket
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>


    </div>
  );
}
