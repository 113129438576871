import React, { useState, useEffect } from "react";
import { InstitutionContext } from "../../App";
import { signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { useContext } from "react";
import { auth } from "../../firebase";
import { NavLink, useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress"; // Import from Material-UI
import {
  Button,
  TextField,
  Typography,
  Container,
  Grid,
  Link,
  Avatar,
  CssBaseline,
} from "@mui/material";
import SportsTennisIcon from "@mui/icons-material/SportsTennis";
import InstitutionDropdown from "../InstitutionDropdown/InstitutionDropdown";
import WebFont from "webfontloader";
import axios from "axios";
import axiosInstance from "../../axiosInstance";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, setUser] = useState(null); // New state for user
  const [error, setError] = useState(null); // New state for error message
  const { institutionId, permissions } = useContext(InstitutionContext);
  const [loading, setLoading] = useState(false); // New state for loading indicator
  const [isLoading, setIsLoading] = useState(false);
  const [buttonText, setButtonText] = useState("Sign In");



  const unsubscribe = onAuthStateChanged(auth, (user) => {
    if (user) {
      // Refresh the token every time the auth state changes
      user.getIdToken(true).then((refreshedToken) => {
        // Use the refreshed token as needed, for example, save it to the local storage
        localStorage.setItem('firebaseToken', refreshedToken);
      });
  
      // navigate('/home');
    }
  });
  
  const onLogin = async (e) => {
    e.preventDefault();
    setButtonText("Signing in..."); // Update button text when login starts

    if (email === "asupickleball@gmail.com" && institutionId !== "ASU") {
      setError("You are not registered for this University");
      setButtonText("Sign In")
      return;
    }

    if (email === "ben@pickleball-insights.com" && institutionId !== "IPA") {
      setError("You are not registered for this University");
      setButtonText("Sign In")
      return;
    }


    try {
        if (
          email !== "asupickleball@gmail.com" &&
          email !== "demo.admin@gmail.com" &&
          email !== "ben@pickleball-insights.com"
        ) {
            const playerResponse = await axiosInstance.get(
              `/players/email/${email}`
            );
            const check = playerResponse.data.institutionId;
            if (check !== institutionId) {
                setError("You are not registered for this University");
                return;
            }
        }
        signInWithEmailAndPassword(auth, email, password)
          .then(async (userCredential) => {
            // Signed in successfully
            const user = userCredential.user;

            user.getIdToken().then((idToken) => {
              // Save the token in the local storage or state
              localStorage.setItem("firebaseToken", idToken);
        
              // Redirect to '/home'
              navigate("/home");
            });        
          })
          .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;

            if (errorCode === "auth/user-not-found") {
                setError("Email is not registered.");
            } else if (errorCode === "auth/wrong-password") {
                setError("Incorrect email or password.");
            } else {
                setError(errorMessage);
            }
          });
          setIsLoading(false); // Stop loading after login process is done
    } catch (error) {
        // Handle the error from playerResponse
        setError("Account not found");
        setIsLoading(false); // Stop loading after login process is done

    } finally {
      setButtonText("Sign In"); // Revert button text back to original
    }

};


useEffect(() => {
  WebFont.load({
    google: {
      families: ['Oswald:regular', 'Baloo Thambi'] // Add 'Baloo Thambi' here
    },
  });
}, []);


  return (
    <div className="flex h-screen">

      <div className="flex-1 flex justify-center items-center bg-gray-800">
        <div className="container mx-auto max-w-lg bg-white p-8 rounded-xl shadow-2xl">
          <div className="flex flex-col items-center">
            <div className="flex items-center mb-6">
              <img
                src="/logo_without_name.png"
                alt="Your Alt Text"
                className="h-24 w-24"
              />{" "}
              {/* Adjust the size classes as needed */}
              <h1 style={{ letterSpacing: '2px', fontFamily: "'Baloo Thambi', sans-serif", fontSize: '48px' }}>
                PICKLE
                <span style={{ color: '#ffbd59' }}>U</span> {/* Replace '#FF0000' with the color you want */}
              </h1>
                </div>

            <div className="flex flex-col items-center">
              <div className=" text-6xl text-primary-main">
                {/* Replace with a larger tennis icon */}
              </div>
              <h2 className="text-2xl font-semibold mb-8">
                Sign in to your club! 
              </h2>
            </div>

            {/* Conditional Rendering for institutions */}

            <form className="w-full">
              <InstitutionDropdown />
              <div className="mb-4 mt-4">
                <input
                  className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="email"
                  type="email"
                  placeholder="Email Address"
                  autoComplete="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mb-6">
                <input
                  className="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                  id="password"
                  type="password"
                  placeholder="Password"
                  autoComplete="current-password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <button
                className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline transform transition duration-200 ease-in"
                onClick={onLogin}
              >
                {buttonText} {/* Use buttonText state here */}
              </button>
              {error && (
                <p className="text-center text-red-500 mt-4">{error}</p>
              )}
            </form>

            <div className="text-center mt-6">
              <NavLink
                to="/sign-up"
                className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800"
              >
                Don't have an account? Register here
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
