import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import CheckoutForm from "./CheckoutForm";
import {
  CircularProgress,
  Typography,
  Container,
  Box,
  Card,
  CardContent,
  Grid,
  CardMedia,
  Paper,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PaymentIcon from "@material-ui/icons/Payment";
import EventIcon from "@material-ui/icons/Event";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import ScheduleIcon from "@material-ui/icons/Schedule";
import { TextField } from "@material-ui/core";
import { useParams } from "react-router-dom";
import { InstitutionContext } from "../../App";
import { useContext } from "react";
import path from "path";
import axiosInstance from "../../axiosInstance";

const stripe_key_dictionary = {
  "IPA": "pk_live_51OIy9yG6QrV2CYm2fRVuG0WDOihqkpWZquJNGKseQhPWnW7JVMDr72LQWfDunGu6cVRHwUX6O3i4nz8iptk4bAU900vXVzEvUF",
  "ASU": "pk_live_51OVNSfI7JfzA7bw8ANQsG6nKiSmuxSf9rFOkrRUthPiF2Rh31lFENSmZ5pqfED5Dd7rNp6jq1z8WWmLObDPG9u8U00o1NJKvYR",
  "GCU": "pk_live_51OVNSfI7JfzA7bw8ANQsG6nKiSmuxSf9rFOkrRUthPiF2Rh31lFENSmZ5pqfED5Dd7rNp6jq1z8WWmLObDPG9u8U00o1NJKvYR",
  "UofA": "pk_live_51OVNSfI7JfzA7bw8ANQsG6nKiSmuxSf9rFOkrRUthPiF2Rh31lFENSmZ5pqfED5Dd7rNp6jq1z8WWmLObDPG9u8U00o1NJKvYR",
  "DEMO": "pk_test_51NarfeFarHSDOBPzLzbQkGwdV3WBpPq0hWQVD8LK7EIWAYvwuVOCuVVicxrA2sme8YTT6aKZTrpfTjH2PD3AoEmi008XQpq8zW"
}

function renderDescription(description) {
  return description.split('\n').map((line, index) => (
    <span key={index}>
      {line}
      <br />
    </span>
  ));
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    minHeight: "100vh",
    backgroundColor: "#ffffff", // Update the background color here
  },
  card: {
    minWidth: 275,
    maxWidth: 500,
    margin: "auto",
    padding: theme.spacing(3),
    backgroundColor: "#ffffff",
    boxShadow: "0 8px 40px -12px rgba(0,0,0,0.3)",
  },
  loadingBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(3),
  },
  title: {
    marginBottom: theme.spacing(3),
    color: "#333",
    fontWeight: "bold",
  },
  icon: {
    fontSize: 60,
    marginBottom: theme.spacing(2),
  },
  eventTitle: {
    marginBottom: theme.spacing(2),
    fontWeight: "bold",
  },
  eventDescription: {
    marginBottom: theme.spacing(4),
  },
  eventPrice: {
    color: "green",
    fontSize: "1.5em",
    marginBottom: theme.spacing(2),
  },
  media: {
    height: 0,
    paddingTop: "56.25%",
    marginBottom: theme.spacing(2),
    borderRadius: "10px",
  },
  dateBox: {
    display: "flex",
    flexDirection: "row",
    marginBottom: theme.spacing(1),
    alignItems: "center",
  },
  dateLabel: {
    fontWeight: "bold",
    marginRight: theme.spacing(1),
  },
  eventInfo: {
    marginBottom: theme.spacing(1),
    display: "flex",
    alignItems: "center",
  },
  infoIcon: {
    marginRight: theme.spacing(1),
  },
  teamMemberInput: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  teamMemberCard: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
  },
  checkoutCard: {
    margin: theme.spacing(2),
    padding: theme.spacing(2),
  },
  sectionTitle: {
    marginBottom: theme.spacing(2),
  },
}));

function Payment({ emailId }) {
  const classes = useStyles();
  const { eventId } = useParams();
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [location, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [teamsize, setTeamSize] = useState("");
  const [cost, setCost] = useState("");
  const [eventInstitutionId, setEventInstitutionId] = useState("");
  const { institutionId, permissions } = useContext(InstitutionContext);

  const [teamName, setTeamName] = useState(""); // New state variable for team name

  const [showPayment, setShowPayment] = useState(false);

  const [teamMembers, setTeamMembers] = useState([]);

  const handleShowPayment = () => {
    setShowPayment(true);
  };

  const formatTeamData = () => {
    // console.log(teamMembers);
    const playerEmails = teamMembers.map((member) => member.email);
    const duprIds = teamMembers.map(
      (member) => `DUPR ID: ${member.duprId}, Player Name: ${member.name}`
    );

    return {
      playerids: duprIds, // Currently ignoring playerIds as per your instruction
      playeremails: playerEmails,
      name: teamName, // Static name for now
      institutionId: institutionId, // Replace with actual institutionId if needed
    };
  };

  async function getPlayerIds(teamMembers) {
    for (const member of teamMembers) {
      try {
        const response = await axiosInstance.get(
          `/players/email/${member.email}`
        );
        const data = {
          playerid: response.data.id,
          eventid: eventId,
          structure: "Individual Sign Up For Team",
        };
        await axiosInstance.post(`/ep-map-entry`, data);
      } catch (error) {
        // If there's an Internal Server Error, ignore this email
        if (error.response && error.response.status === 404) {
          // console.log(`Email not found in database: ${member.email}`);
          const ghostPlayerData = {
            firstName: "GHOST",
            middleInitials: "G",
            lastName: "GHOST",
            email: member.email,
            rating: 0.0,
            win: "0",
            loss: "0",
            notes: "GHOST",
            membership_status: "GHOST",
            institutionId: institutionId,
          };
          // console.log(ghostPlayerData);
          const response = await axiosInstance.post(
            `/players`,
            ghostPlayerData
          );
          const data = {
            playerid: response.data.id,
            eventid: eventId,
            structure: "Individual Sign Up For Team",
          };
          await axiosInstance.post(
            `/ep-map-entry`,
            data
          );
          continue;
        }
        // Handle other potential errors
        throw error;
      }
    }
  }

  const handleTeamRegistration = async () => {
    const event = await axiosInstance.get(
      `/events/${eventId}`
    );
    const event_data = event.data;
    // Logic to register the team
    const startDate = event_data.startDate;
    const formatted =
      (new Date(startDate).getUTCMonth() + 1).toString().padStart(2, "0") +
      "/" +
      new Date(startDate).getUTCDate().toString().padStart(2, "0") +
      "/" +
      new Date(startDate).getUTCFullYear() +
      " " +
      (new Date(startDate).getUTCHours() % 12 || 12) +
      ":" +
      new Date(startDate).getUTCMinutes().toString().padStart(2, "0") +
      " " +
      (new Date(startDate).getUTCHours() >= 12 ? "PM" : "AM");
    // await axios.post(`${process.env.REACT_APP_API_URL}/send_email/${emailId}?event=${event_data.name}&location=${event_data.location}&date=${formatted}`)
    window.location.href = `${window.location.origin}/#/home`;
    const teamData = formatTeamData();
    const response = await axiosInstance.post(
      `/team`,
      teamData
    );
    // console.log(response.data);
    const data = {
      playerid: response.data.id,
      eventid: eventId,
      structure: "Team",
    };

    // Attempt to create the entry for the player for the event
    await axiosInstance.post(`/ep-map-entry`, data);

    // If successful, update the count and proceed with payment
    event_data.count = event_data.count + 1;
    await axiosInstance.put(
      `/events/${eventId}`,
      event_data
    );

    getPlayerIds(teamMembers);
  };

  // Update teamMembers state based on teamsize
  useEffect(() => {
    setTeamMembers(
      Array.from({ length: teamsize }, () => ({
        email: "",
        duprId: "",
        name: "",
      }))
    );
  }, [teamsize]);

  // Handle changes in team member input fields including player ID and name
  const handleTeamMemberChange = (index, key, value) => {
    const updatedTeamMembers = [...teamMembers];
    updatedTeamMembers[index][key] = value;
    setTeamMembers(updatedTeamMembers);
  };

  // Render textboxes for email, player ID, and name based on teamsize
  const renderTeamMemberInputs = () => {
    return teamMembers.map((member, index) => (
      <div key={index}>
        <TextField
          type="email"
          placeholder="Team Member Email"
          value={member.email}
          onChange={(e) =>
            handleTeamMemberChange(index, "email", e.target.value)
          }
          className={classes.teamMemberInput}
          fullWidth
          variant="outlined"
        />
        <TextField
          type="text"
          placeholder="Player ID"
          value={member.playerId}
          onChange={(e) =>
            handleTeamMemberChange(index, "playerId", e.target.value)
          }
          className={classes.teamMemberInput}
          fullWidth
          variant="outlined"
        />
        <TextField
          type="text"
          placeholder="Player Name"
          value={member.name}
          onChange={(e) =>
            handleTeamMemberChange(index, "name", e.target.value)
          }
          className={classes.teamMemberInput}
          fullWidth
          variant="outlined"
        />
      </div>
    ));
  };

  const imageLink = "/image3.jpeg";

  const formatDateTime = (dateString) => {
    const options = {
      month: "long",
      day: "numeric",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    return new Date(dateString).toLocaleString(undefined, options);
  };

  useEffect(() => {
    fetchEventData(eventId);
  }, [eventId]);

  const fetchEventData = async (eventId) => {
    try {
      const response = await axiosInstance.get(
        `/events/${eventId}`
      );
      const eventData = response.data;
      // console.log("here");
      // console.log(eventData);
      setName(eventData.name);
      setStartDate(formatDateTime(eventData.startdate)); // Format date and time
      setLocation(eventData.location);
      setDescription(eventData.description);
      setCost(parseFloat(eventData.cost).toFixed(2));
      setTeamSize(eventData.teamsize);
    } catch (error) {
      console.error("Error fetching event:", error);
    }
  };

  useEffect(() => {
    // console.log(stripe_key_dictionary[institutionId])
    console.log("Institution Id:" + institutionId)
    fetch("/config")
      .then(async (r) => {
        setStripePromise(
          loadStripe(
            stripe_key_dictionary[institutionId]
          )
        );
      })
      .catch((error) => {
        console.error("Error loading Stripe:", error);
      });
  }, []);

  useEffect(async () => {
    const response = await axiosInstance.get(
      `/events/${eventId}`
    );
    const eventData = response.data;
    axiosInstance
      .post(`/create_payment_intent`, {
        amount: eventData.cost * 100,
        email: emailId,
        name: eventData.name,
        location: eventData.location,
        date: eventData.startdate,
        institutionId: institutionId,
      })
      .then((result) => {
        var { clientSecret } = result.data;
        setClientSecret(clientSecret);
        // setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error creating payment intent:", error.response.data);
        console.error("Status Text:", error.response.statusText);
      });
  }, []);

  return (
    <div className="container mx-auto px-4 py-8">
      <div className="pl-4 mb-8">
        <h1 className="text-2xl font-bold mb-2">Payment for {name}</h1>
        <p className="text-xl text-gray-600">
          Secure and fast payment processing
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="bg-white shadow-lg rounded-lg p-6">
          <div className="mb-4">
            <img className="rounded-lg w-full" src={imageLink} alt={name} />
          </div>
          <h2 className="text-2xl font-bold mb-2">{name}</h2>
          <p className="text-gray-700 mb-4">{renderDescription(description)}</p>
          <div className="flex items-center mb-2">
            <span className="mr-2 text-lg font-medium">Start Date:</span>
            <span>{startDate}</span>
          </div>
          <div className="flex items-center mb-2">
            <span className="mr-2 text-lg font-medium">Location:</span>
            <span>{location}</span>
          </div>
          <div className="text-lg font-bold text-green-600 mb-4">
            Price: ${cost}
          </div>
          <p className="text-red-500 text-sm">
            ** All Payments are Non-refundable **
          </p>
        </div>

        <div className="bg-white shadow-lg rounded-lg p-6">
          {teamsize !== 1 ? (
            <div>
              <h2 className="text-2xl font-bold mb-4">Team Information</h2>
              <input
                type="text"
                placeholder="Team Name"
                value={teamName}
                onChange={(e) => setTeamName(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded mb-4"
              />
              <div className="max-w-2xl mx-auto p-6 bg-white rounded shadow">
                <h2 className="text-2xl font-semibold mb-6">
                  Team Information
                </h2>

                {teamMembers.map((member, index) => (
                  <div
                    key={index}
                    className="mb-6 p-4 border border-gray-200 rounded"
                  >
                    <h3 className="text-lg font-medium mb-4">
                      Player {index + 1}
                    </h3>
                    <div className="mb-4">
                      <label
                        htmlFor={`team-member-email-${index}`}
                        className="block text-sm font-medium text-gray-700 mb-2"
                      >
                        Team Member Email
                      </label>
                      <input
                        type="email"
                        id={`team-member-email-${index}`}
                        className="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        placeholder="Enter email"
                        value={member.email}
                        onChange={(e) =>
                          handleTeamMemberChange(index, "email", e.target.value)
                        }
                      />
                    </div>
                    <div className="mb-4">
                      <label
                        htmlFor={`dupr-id-${index}`}
                        className="block text-sm font-medium text-gray-700 mb-2"
                      >
                        DUPR ID
                      </label>
                      <input
                        type="text"
                        id={`dupr-id-${index}`}
                        className="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        placeholder="Enter DUPR ID"
                        value={member.duprId}
                        onChange={(e) =>
                          handleTeamMemberChange(
                            index,
                            "duprId",
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <div className="mb-4">
                      <label
                        htmlFor={`name-${index}`}
                        className="block text-sm font-medium text-gray-700 mb-2"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        id={`name-${index}`}
                        className="block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                        placeholder="Enter name"
                        value={member.name}
                        onChange={(e) =>
                          handleTeamMemberChange(index, "name", e.target.value)
                        }
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
          {isLoading ? (
            <div className="flex flex-col items-center justify-center">
              <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
                {/* SVG path for spinner */}
              </svg>
              Preparing your secure transaction...
            </div>
          ) : cost > 1 ? (
            clientSecret &&
            stripePromise && (
              <Elements stripe={stripePromise} options={{ clientSecret }}>
                <h2 className="text-2xl font-bold mb-4 mt-4">
                  Payment Information
                </h2>
                <CheckoutForm
                  emailId={emailId}
                  eventId={eventId}
                  teamMembers={teamMembers}
                  teamName={teamName}
                />
              </Elements>
            )
          ) : (
            <button
              onClick={handleTeamRegistration}
              className="bg-blue-500 hover:bg-blue-700 my-4 text-white font-bold py-2 px-4 rounded text-right self-start"
              >
              Register Team
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

export default Payment;
