const roundRobinTemplates = [
    {
      players: 8,
      schedule: [
        {
          courts: [
            { court: 1, teamOne: ['Player 1', 'Player 5'], teamTwo: ['Player 2', 'Player 6'] },
            { court: 2, teamOne: ['Player 3', 'Player 7'], teamTwo: ['Player 4', 'Player 8'] },
          ],
        },
        {
          courts: [
            { court: 1, teamOne: ['Player 1', 'Player 6'], teamTwo: ['Player 5', 'Player 7'] },
            { court: 2, teamOne: ['Player 2', 'Player 8'], teamTwo: ['Player 3', 'Player 4'] },
          ],
        },
        {
          courts: [
            { court: 1, teamOne: ['Player 1', 'Player 7'], teamTwo: ['Player 6', 'Player 8'] },
            { court: 2, teamOne: ['Player 5', 'Player 4'], teamTwo: ['Player 2', 'Player 3'] },
          ],
        },
        {
          courts: [
            { court: 1, teamOne: ['Player 1', 'Player 8'], teamTwo: ['Player 7', 'Player 4'] },
            { court: 2, teamOne: ['Player 6', 'Player 3'], teamTwo: ['Player 5', 'Player 2'] },
          ],
        },
        {
          courts: [
            { court: 1, teamOne: ['Player 1', 'Player 4'], teamTwo: ['Player 8', 'Player 3'] },
            { court: 2, teamOne: ['Player 7', 'Player 2'], teamTwo: ['Player 6', 'Player 5'] },
          ],
        },
        {
          courts: [
            { court: 1, teamOne: ['Player 1', 'Player 3'], teamTwo: ['Player 4', 'Player 2'] },
            { court: 2, teamOne: ['Player 8', 'Player 5'], teamTwo: ['Player 7', 'Player 6'] },
          ],
        },
        {
          courts: [
            { court: 1, teamOne: ['Player 1', 'Player 2'], teamTwo: ['Player 3', 'Player 5'] },
            { court: 2, teamOne: ['Player 4', 'Player 6'], teamTwo: ['Player 8', 'Player 7'] },
          ],
        },
      ],
    },
    {
      players: 9,
      schedule: [
        {
          courts: [
            { court: 1, teamOne: ['Player 1', 'Player 6'], teamTwo: ['Player 2', 'Player 7'] },
            { court: 2, teamOne: ['Player 3', 'Player 8'], teamTwo: ['Player 4', 'Player 9'] },
          ],
        },
        {
          courts: [
            { court: 1, teamOne: ['Player 1', 'Player 7'], teamTwo: ['Player 6', 'Player 8'] },
            { court: 2, teamOne: ['Player 2', 'Player 9'], teamTwo: ['Player 4', 'Player 5'] },
          ],
        },
        {
          courts: [
            { court: 1, teamOne: ['Player 1', 'Player 8'], teamTwo: ['Player 7', 'Player 9'] },
            { court: 2, teamOne: ['Player 2', 'Player 5'], teamTwo: ['Player 3', 'Player 4'] },
          ],
        },
        {
          courts: [
            { court: 1, teamOne: ['Player 1', 'Player 9'], teamTwo: ['Player 7', 'Player 5'] },
            { court: 2, teamOne: ['Player 6', 'Player 4'], teamTwo: ['Player 2', 'Player 3'] },
          ],
        },
        {
          courts: [
            { court: 1, teamOne: ['Player 9', 'Player 5'], teamTwo: ['Player 8', 'Player 4'] },
            { court: 2, teamOne: ['Player 7', 'Player 3'], teamTwo: ['Player 6', 'Player 2'] },
          ],
        },
        {
          courts: [
            { court: 1, teamOne: ['Player 1', 'Player 5'], teamTwo: ['Player 9', 'Player 3'] },
            { court: 2, teamOne: ['Player 8', 'Player 2'], teamTwo: ['Player 7', 'Player 6'] },
          ],
        },
        {
          courts: [
            { court: 1, teamOne: ['Player 6', 'Player 2'], teamTwo: ['Player 1', 'Player 5'] },
            { court: 2, teamOne: ['Player 9', 'Player 4'], teamTwo: ['Player 8', 'Player 7'] },
          ],
        },
      ],
    },
    {
      players: 10,
      schedule: [
        {
          courts: [
            { court: 1, teamOne: ['Player 1', 'Player 6'], teamTwo: ['Player 2', 'Player 7'] },
            { court: 2, teamOne: ['Player 3', 'Player 8'], teamTwo: ['Player 4', 'Player 9'] },
          ],
        },
        {
          courts: [
            { court: 1, teamOne: ['Player 5', 'Player 10'], teamTwo: ['Player 1', 'Player 7'] },
            { court: 2, teamOne: ['Player 6', 'Player 8'], teamTwo: ['Player 2', 'Player 9'] },
          ],
        },
        {
          courts: [
            { court: 1, teamOne: ['Player 3', 'Player 10'], teamTwo: ['Player 4', 'Player 5'] },
            { court: 2, teamOne: ['Player 1', 'Player 8'], teamTwo: ['Player 7', 'Player 9'] },
          ],
        },
        {
          courts: [
            { court: 1, teamOne: ['Player 6', 'Player 10'], teamTwo: ['Player 2', 'Player 5'] },
            { court: 2, teamOne: ['Player 3', 'Player 4'], teamTwo: ['Player 1', 'Player 9'] },
          ],
        },
        {
          courts: [
            { court: 1, teamOne: ['Player 8', 'Player 10'], teamTwo: ['Player 7', 'Player 5'] },
            { court: 2, teamOne: ['Player 6', 'Player 4'], teamTwo: ['Player 2', 'Player 3'] },
          ],
        },
        {
          courts: [
            { court: 1, teamOne: ['Player 1', 'Player 10'], teamTwo: ['Player 9', 'Player 5'] },
            { court: 2, teamOne: ['Player 8', 'Player 4'], teamTwo: ['Player 7', 'Player 3'] },
          ],
        },
        {
          courts: [
            { court: 1, teamOne: ['Player 6', 'Player 2'], teamTwo: ['Player 1', 'Player 5'] },
            { court: 2, teamOne: ['Player 10', 'Player 4'], teamTwo: ['Player 9', 'Player 3'] },
          ],
        },
      ],
    },
    {
      players: 12,
      schedule: [
        {
          courts: [
            { court: 1, teamOne: ['Player 1', 'Player 7'], teamTwo: ['Player 2', 'Player 8'] },
            { court: 2, teamOne: ['Player 3', 'Player 9'], teamTwo: ['Player 4', 'Player 10'] },
            { court: 3, teamOne: ['Player 5', 'Player 11'], teamTwo: ['Player 6', 'Player 12'] },
          ],
        },
        {
          courts: [
            { court: 1, teamOne: ['Player 1', 'Player 8'], teamTwo: ['Player 7', 'Player 9'] },
            { court: 2, teamOne: ['Player 2', 'Player 10'], teamTwo: ['Player 3', 'Player 11'] },
            { court: 3, teamOne: ['Player 4', 'Player 12'], teamTwo: ['Player 5', 'Player 6'] },
          ],
        },
        {
          courts: [
            { court: 1, teamOne: ['Player 1', 'Player 9'], teamTwo: ['Player 8', 'Player 10'] },
            { court: 2, teamOne: ['Player 7', 'Player 11'], teamTwo: ['Player 2', 'Player 12'] },
            { court: 3, teamOne: ['Player 3', 'Player 6'], teamTwo: ['Player 4', 'Player 5'] },
          ],
        },
        {
          courts: [
            { court: 1, teamOne: ['Player 1', 'Player 10'], teamTwo: ['Player 9', 'Player 11'] },
            { court: 2, teamOne: ['Player 8', 'Player 12'], teamTwo: ['Player 7', 'Player 6'] },
            { court: 3, teamOne: ['Player 2', 'Player 5'], teamTwo: ['Player 3', 'Player 4'] },
          ],
        },
        {
          courts: [
            { court: 1, teamOne: ['Player 1', 'Player 11'], teamTwo: ['Player 10', 'Player 12'] },
            { court: 2, teamOne: ['Player 9', 'Player 6'], teamTwo: ['Player 8', 'Player 5'] },
            { court: 3, teamOne: ['Player 7', 'Player 4'], teamTwo: ['Player 2', 'Player 3'] },
          ],
        },
        {
          courts: [
            { court: 1, teamOne: ['Player 1', 'Player 12'], teamTwo: ['Player 11', 'Player 6'] },
            { court: 2, teamOne: ['Player 10', 'Player 5'], teamTwo: ['Player 9', 'Player 4'] },
            { court: 3, teamOne: ['Player 8', 'Player 3'], teamTwo: ['Player 7', 'Player 2'] },
          ],
        },
        {
          courts: [
            { court: 1, teamOne: ['Player 1', 'Player 6'], teamTwo: ['Player 12', 'Player 5'] },
            { court: 2, teamOne: ['Player 11', 'Player 4'], teamTwo: ['Player 10', 'Player 3'] },
            { court: 3, teamOne: ['Player 9', 'Player 2'], teamTwo: ['Player 8', 'Player 7'] },
          ],
        },
        {
          courts: [
            { court: 1, teamOne: ['Player 1', 'Player 5'], teamTwo: ['Player 6', 'Player 4'] },
            { court: 2, teamOne: ['Player 12', 'Player 3'], teamTwo: ['Player 11', 'Player 2'] },
            { court: 3, teamOne: ['Player 10', 'Player 7'], teamTwo: ['Player 9', 'Player 8'] },
          ],
        },
      ],
    },
    {
        players: 13,
        schedule: [
          {
            courts: [
              { court: 1, teamOne: ['Player 1', 'Player 8'], teamTwo: ['Player 2', 'Player 9'] },
              { court: 2, teamOne: ['Player 3', 'Player 10'], teamTwo: ['Player 4', 'Player 11'] },
              { court: 3, teamOne: ['Player 5', 'Player 12'], teamTwo: ['Player 6', 'Player 13'] },
            ],
          },
          {
            courts: [
              { court: 1, teamOne: ['Player 1', 'Player 9'], teamTwo: ['Player 8', 'Player 10'] },
              { court: 2, teamOne: ['Player 2', 'Player 11'], teamTwo: ['Player 3', 'Player 12'] },
              { court: 3, teamOne: ['Player 4', 'Player 13'], teamTwo: ['Player 6', 'Player 7'] },
            ],
          },
          {
            courts: [
              { court: 1, teamOne: ['Player 1', 'Player 10'], teamTwo: ['Player 9', 'Player 11'] },
              { court: 2, teamOne: ['Player 8', 'Player 12'], teamTwo: ['Player 2', 'Player 13'] },
              { court: 3, teamOne: ['Player 4', 'Player 7'], teamTwo: ['Player 5', 'Player 6'] },
            ],
          },
          {
            courts: [
              { court: 1, teamOne: ['Player 1', 'Player 11'], teamTwo: ['Player 10', 'Player 12'] },
              { court: 2, teamOne: ['Player 9', 'Player 13'], teamTwo: ['Player 2', 'Player 7'] },
              { court: 3, teamOne: ['Player 3', 'Player 6'], teamTwo: ['Player 4', 'Player 5'] },
            ],
          },
          {
            courts: [
              { court: 1, teamOne: ['Player 1', 'Player 12'], teamTwo: ['Player 11', 'Player 13'] },
              { court: 2, teamOne: ['Player 9', 'Player 7'], teamTwo: ['Player 8', 'Player 6'] },
              { court: 3, teamOne: ['Player 2', 'Player 5'], teamTwo: ['Player 3', 'Player 4'] },
            ],
          },
          {
            courts: [
              { court: 1, teamOne: ['Player 1', 'Player 13'], teamTwo: ['Player 11', 'Player 7'] },
              { court: 2, teamOne: ['Player 10', 'Player 6'], teamTwo: ['Player 9', 'Player 5'] },
              { court: 3, teamOne: ['Player 8', 'Player 4'], teamTwo: ['Player 2', 'Player 3'] },
            ],
          },
          {
            courts: [
              { court: 1, teamOne: ['Player 13', 'Player 7'], teamTwo: ['Player 12', 'Player 6'] },
              { court: 2, teamOne: ['Player 11', 'Player 5'], teamTwo: ['Player 10', 'Player 4'] },
              { court: 3, teamOne: ['Player 9', 'Player 3'], teamTwo: ['Player 8', 'Player 2'] },
            ],
          },
          {
            courts: [
              { court: 1, teamOne: ['Player 1', 'Player 7'], teamTwo: ['Player 13', 'Player 5'] },
              { court: 2, teamOne: ['Player 12', 'Player 4'], teamTwo: ['Player 11', 'Player 3'] },
              { court: 3, teamOne: ['Player 10', 'Player 2'], teamTwo: ['Player 9', 'Player 8'] },
            ],
          },
        ],
      },
      {
        players: 14,
        schedule: [
          {
            courts: [
              { court: 1, teamOne: ['Player 1', 'Player 8'], teamTwo: ['Player 2', 'Player 9'] },
              { court: 2, teamOne: ['Player 3', 'Player 10'], teamTwo: ['Player 4', 'Player 11'] },
              { court: 3, teamOne: ['Player 5', 'Player 12'], teamTwo: ['Player 6', 'Player 13'] },
            ],
          },
          {
            courts: [
              { court: 1, teamOne: ['Player 7', 'Player 14'], teamTwo: ['Player 1', 'Player 9'] },
              { court: 2, teamOne: ['Player 8', 'Player 10'], teamTwo: ['Player 2', 'Player 11'] },
              { court: 3, teamOne: ['Player 3', 'Player 12'], teamTwo: ['Player 4', 'Player 13'] },
            ],
          },
          {
            courts: [
              { court: 1, teamOne: ['Player 5', 'Player 14'], teamTwo: ['Player 6', 'Player 7'] },
              { court: 2, teamOne: ['Player 1', 'Player 10'], teamTwo: ['Player 9', 'Player 11'] },
              { court: 3, teamOne: ['Player 8', 'Player 12'], teamTwo: ['Player 2', 'Player 13'] },
            ],
          },
          {
            courts: [
              { court: 1, teamOne: ['Player 3', 'Player 14'], teamTwo: ['Player 4', 'Player 7'] },
              { court: 2, teamOne: ['Player 5', 'Player 6'], teamTwo: ['Player 1', 'Player 11'] },
              { court: 3, teamOne: ['Player 10', 'Player 12'], teamTwo: ['Player 9', 'Player 13'] },
            ],
          },
          {
            courts: [
              { court: 1, teamOne: ['Player 8', 'Player 14'], teamTwo: ['Player 2', 'Player 7'] },
              { court: 2, teamOne: ['Player 3', 'Player 6'], teamTwo: ['Player 4', 'Player 5'] },
              { court: 3, teamOne: ['Player 1', 'Player 12'], teamTwo: ['Player 11', 'Player 13'] },
            ],
          },
          {
            courts: [
              { court: 1, teamOne: ['Player 10', 'Player 14'], teamTwo: ['Player 9', 'Player 7'] },
              { court: 2, teamOne: ['Player 8', 'Player 6'], teamTwo: ['Player 2', 'Player 5'] },
              { court: 3, teamOne: ['Player 3', 'Player 4'], teamTwo: ['Player 1', 'Player 13'] },
            ],
          },
          {
            courts: [
              { court: 1, teamOne: ['Player 12', 'Player 14'], teamTwo: ['Player 11', 'Player 7'] },
              { court: 2, teamOne: ['Player 10', 'Player 6'], teamTwo: ['Player 9', 'Player 5'] },
              { court: 3, teamOne: ['Player 8', 'Player 4'], teamTwo: ['Player 2', 'Player 3'] },
            ],
          },
        ],
      },
      {
        players: 11,
        schedule: [
          {
            courts: [
              { court: 1, teamOne: ['Player 1', 'Player 7'], teamTwo: ['Player 2', 'Player 8'] },
              { court: 2, teamOne: ['Player 3', 'Player 9'], teamTwo: ['Player 4', 'Player 10'] },
            ],
          },
          {
            courts: [
              { court: 1, teamOne: ['Player 5', 'Player 11'], teamTwo: ['Player 1', 'Player 8'] },
              { court: 2, teamOne: ['Player 7', 'Player 9'], teamTwo: ['Player 2', 'Player 10'] },
            ],
          },
          {
            courts: [
              { court: 1, teamOne: ['Player 3', 'Player 11'], teamTwo: ['Player 5', 'Player 6'] },
              { court: 2, teamOne: ['Player 1', 'Player 9'], teamTwo: ['Player 8', 'Player 10'] },
            ],
          },
          {
            courts: [
              { court: 1, teamOne: ['Player 7', 'Player 11'], teamTwo: ['Player 3', 'Player 6'] },
              { court: 2, teamOne: ['Player 4', 'Player 5'], teamTwo: ['Player 1', 'Player 10'] },
            ],
          },
          {
            courts: [
              { court: 1, teamOne: ['Player 9', 'Player 11'], teamTwo: ['Player 7', 'Player 6'] },
              { court: 2, teamOne: ['Player 2', 'Player 5'], teamTwo: ['Player 3', 'Player 4'] },
            ],
          },
          {
            courts: [
              { court: 1, teamOne: ['Player 1', 'Player 11'], teamTwo: ['Player 9', 'Player 6'] },
              { court: 2, teamOne: ['Player 8', 'Player 5'], teamTwo: ['Player 7', 'Player 4'] },
            ],
          },
          {
            courts: [
              { court: 1, teamOne: ['Player 2', 'Player 3'], teamTwo: ['Player 11', 'Player 6'] },
              { court: 2, teamOne: ['Player 10', 'Player 5'], teamTwo: ['Player 9', 'Player 4'] },
            ],
          },
        ],
      },
      {
        players: 15,
        schedule: [
          {
            courts: [
              { court: 1, teamOne: ['Player 1', 'Player 9'], teamTwo: ['Player 2', 'Player 10'] },
              { court: 2, teamOne: ['Player 3', 'Player 11'], teamTwo: ['Player 4', 'Player 12'] },
              { court: 3, teamOne: ['Player 5', 'Player 13'], teamTwo: ['Player 6', 'Player 14'] },
            ],
          },
          {
            courts: [
              { court: 1, teamOne: ['Player 7', 'Player 15'], teamTwo: ['Player 1', 'Player 10'] },
              { court: 2, teamOne: ['Player 9', 'Player 11'], teamTwo: ['Player 2', 'Player 12'] },
              { court: 3, teamOne: ['Player 3', 'Player 13'], teamTwo: ['Player 4', 'Player 14'] },
            ],
          },
          {
            courts: [
              { court: 1, teamOne: ['Player 5', 'Player 15'], teamTwo: ['Player 7', 'Player 8'] },
              { court: 2, teamOne: ['Player 1', 'Player 11'], teamTwo: ['Player 10', 'Player 12'] },
              { court: 3, teamOne: ['Player 9', 'Player 13'], teamTwo: ['Player 2', 'Player 14'] },
            ],
          },
          {
            courts: [
              { court: 1, teamOne: ['Player 3', 'Player 15'], teamTwo: ['Player 5', 'Player 8'] },
              { court: 2, teamOne: ['Player 6', 'Player 7'], teamTwo: ['Player 1', 'Player 12'] },
              { court: 3, teamOne: ['Player 11', 'Player 13'], teamTwo: ['Player 10', 'Player 14'] },
            ],
          },
          {
            courts: [
              { court: 1, teamOne: ['Player 9', 'Player 15'], teamTwo: ['Player 3', 'Player 8'] },
              { court: 2, teamOne: ['Player 4', 'Player 7'], teamTwo: ['Player 5', 'Player 6'] },
              { court: 3, teamOne: ['Player 1', 'Player 13'], teamTwo: ['Player 12', 'Player 14'] },
            ],
          },
          {
            courts: [
              { court: 1, teamOne: ['Player 11', 'Player 15'], teamTwo: ['Player 9', 'Player 8'] },
              { court: 2, teamOne: ['Player 2', 'Player 7'], teamTwo: ['Player 3', 'Player 6'] },
              { court: 3, teamOne: ['Player 4', 'Player 5'], teamTwo: ['Player 1', 'Player 14'] },
            ],
          },
          {
            courts: [
              { court: 1, teamOne: ['Player 13', 'Player 15'], teamTwo: ['Player 11', 'Player 8'] },
              { court: 2, teamOne: ['Player 10', 'Player 7'], teamTwo: ['Player 9', 'Player 6'] },
              { court: 3, teamOne: ['Player 2', 'Player 5'], teamTwo: ['Player 3', 'Player 4'] },
            ],
          },
        ],
      },
      {
        players: 16,
        schedule: [
          {
            courts: [
              { court: 1, teamOne: ['Player 1', 'Player 9'], teamTwo: ['Player 2', 'Player 10'] },
              { court: 2, teamOne: ['Player 3', 'Player 11'], teamTwo: ['Player 4', 'Player 12'] },
              { court: 3, teamOne: ['Player 5', 'Player 13'], teamTwo: ['Player 6', 'Player 14'] },
              { court: 4, teamOne: ['Player 7', 'Player 15'], teamTwo: ['Player 8', 'Player 16'] },
            ],
          },
          {
            courts: [
              { court: 1, teamOne: ['Player 1', 'Player 10'], teamTwo: ['Player 9', 'Player 11'] },
              { court: 2, teamOne: ['Player 2', 'Player 12'], teamTwo: ['Player 3', 'Player 13'] },
              { court: 3, teamOne: ['Player 4', 'Player 14'], teamTwo: ['Player 5', 'Player 15'] },
              { court: 4, teamOne: ['Player 6', 'Player 16'], teamTwo: ['Player 7', 'Player 8'] },
            ],
          },
          {
            courts: [
              { court: 1, teamOne: ['Player 1', 'Player 11'], teamTwo: ['Player 10', 'Player 12'] },
              { court: 2, teamOne: ['Player 9', 'Player 13'], teamTwo: ['Player 2', 'Player 14'] },
              { court: 3, teamOne: ['Player 3', 'Player 15'], teamTwo: ['Player 4', 'Player 16'] },
              { court: 4, teamOne: ['Player 5', 'Player 8'], teamTwo: ['Player 6', 'Player 7'] },
            ],
          },
          {
            courts: [
              { court: 1, teamOne: ['Player 1', 'Player 12'], teamTwo: ['Player 11', 'Player 13'] },
              { court: 2, teamOne: ['Player 10', 'Player 14'], teamTwo: ['Player 9', 'Player 15'] },
              { court: 3, teamOne: ['Player 2', 'Player 16'], teamTwo: ['Player 3', 'Player 8'] },
              { court: 4, teamOne: ['Player 4', 'Player 7'], teamTwo: ['Player 5', 'Player 6'] },
            ],
          },
          {
            courts: [
              { court: 1, teamOne: ['Player 1', 'Player 13'], teamTwo: ['Player 12', 'Player 14'] },
              { court: 2, teamOne: ['Player 11', 'Player 15'], teamTwo: ['Player 10', 'Player 16'] },
              { court: 3, teamOne: ['Player 9', 'Player 8'], teamTwo: ['Player 2', 'Player 7'] },
              { court: 4, teamOne: ['Player 3', 'Player 6'], teamTwo: ['Player 4', 'Player 5'] },
            ],
          },
          {
            courts: [
              { court: 1, teamOne: ['Player 1', 'Player 14'], teamTwo: ['Player 13', 'Player 15'] },
              { court: 2, teamOne: ['Player 12', 'Player 16'], teamTwo: ['Player 11', 'Player 8'] },
              { court: 3, teamOne: ['Player 10', 'Player 7'], teamTwo: ['Player 9', 'Player 6'] },
              { court: 4, teamOne: ['Player 2', 'Player 5'], teamTwo: ['Player 3', 'Player 4'] },
            ],
          },
          {
            courts: [
              { court: 1, teamOne: ['Player 1', 'Player 15'], teamTwo: ['Player 14', 'Player 16'] },
              { court: 2, teamOne: ['Player 13', 'Player 8'], teamTwo: ['Player 12', 'Player 7'] },
              { court: 3, teamOne: ['Player 11', 'Player 6'], teamTwo: ['Player 10', 'Player 5'] },
              { court: 4, teamOne: ['Player 9', 'Player 4'], teamTwo: ['Player 2', 'Player 3'] },
            ],
          },
          {
            courts: [
              { court: 1, teamOne: ['Player 1', 'Player 16'], teamTwo: ['Player 15', 'Player 8'] },
              { court: 2, teamOne: ['Player 14', 'Player 7'], teamTwo: ['Player 13', 'Player 6'] },
              { court: 3, teamOne: ['Player 12', 'Player 5'], teamTwo: ['Player 11', 'Player 4'] },
              { court: 4, teamOne: ['Player 10', 'Player 3'], teamTwo: ['Player 9', 'Player 2'] },
            ],
          },
        ],
      },
      {
        players: 17,
        schedule: [
          {
            courts: [
              { court: 1, teamOne: ['Player 1', 'Player 10'], teamTwo: ['Player 2', 'Player 11'] },
              { court: 2, teamOne: ['Player 3', 'Player 12'], teamTwo: ['Player 4', 'Player 13'] },
              { court: 3, teamOne: ['Player 5', 'Player 14'], teamTwo: ['Player 6', 'Player 15'] },
              { court: 4, teamOne: ['Player 7', 'Player 16'], teamTwo: ['Player 8', 'Player 17'] },
            ],
          },
          {
            courts: [
              { court: 1, teamOne: ['Player 1', 'Player 11'], teamTwo: ['Player 10', 'Player 12'] },
              { court: 2, teamOne: ['Player 2', 'Player 13'], teamTwo: ['Player 3', 'Player 14'] },
              { court: 3, teamOne: ['Player 4', 'Player 15'], teamTwo: ['Player 5', 'Player 16'] },
              { court: 4, teamOne: ['Player 6', 'Player 17'], teamTwo: ['Player 8', 'Player 9'] },
            ],
          },
          {
            courts: [
              { court: 1, teamOne: ['Player 1', 'Player 12'], teamTwo: ['Player 11', 'Player 13'] },
              { court: 2, teamOne: ['Player 10', 'Player 14'], teamTwo: ['Player 2', 'Player 15'] },
              { court: 3, teamOne: ['Player 3', 'Player 16'], teamTwo: ['Player 4', 'Player 17'] },
              { court: 4, teamOne: ['Player 6', 'Player 9'], teamTwo: ['Player 7', 'Player 8'] },
            ],
          },
          {
            courts: [
              { court: 1, teamOne: ['Player 1', 'Player 13'], teamTwo: ['Player 12', 'Player 14'] },
              { court: 2, teamOne: ['Player 11', 'Player 15'], teamTwo: ['Player 10', 'Player 16'] },
              { court: 3, teamOne: ['Player 2', 'Player 17'], teamTwo: ['Player 4', 'Player 9'] },
              { court: 4, teamOne: ['Player 5', 'Player 8'], teamTwo: ['Player 6', 'Player 7'] },
            ],
          },
          {
            courts: [
              { court: 1, teamOne: ['Player 1', 'Player 14'], teamTwo: ['Player 13', 'Player 15'] },
              { court: 2, teamOne: ['Player 12', 'Player 16'], teamTwo: ['Player 11', 'Player 17'] },
              { court: 3, teamOne: ['Player 2', 'Player 9'], teamTwo: ['Player 3', 'Player 8'] },
              { court: 4, teamOne: ['Player 4', 'Player 7'], teamTwo: ['Player 5', 'Player 6'] },
            ],
          },
          {
            courts: [
              { court: 1, teamOne: ['Player 1', 'Player 15'], teamTwo: ['Player 14', 'Player 16'] },
              { court: 2, teamOne: ['Player 13', 'Player 17'], teamTwo: ['Player 11', 'Player 9'] },
              { court: 3, teamOne: ['Player 10', 'Player 8'], teamTwo: ['Player 2', 'Player 7'] },
              { court: 4, teamOne: ['Player 3', 'Player 6'], teamTwo: ['Player 4', 'Player 5'] },
            ],
          },
          {
            courts: [
              { court: 1, teamOne: ['Player 1', 'Player 16'], teamTwo: ['Player 15', 'Player 17'] },
              { court: 2, teamOne: ['Player 13', 'Player 9'], teamTwo: ['Player 12', 'Player 8'] },
              { court: 3, teamOne: ['Player 11', 'Player 7'], teamTwo: ['Player 10', 'Player 6'] },
              { court: 4, teamOne: ['Player 2', 'Player 5'], teamTwo: ['Player 3', 'Player 4'] },
            ],
          },
          {
            courts: [
              { court: 1, teamOne: ['Player 1', 'Player 17'], teamTwo: ['Player 15', 'Player 9'] },
              { court: 2, teamOne: ['Player 14', 'Player 8'], teamTwo: ['Player 13', 'Player 7'] },
              { court: 3, teamOne: ['Player 12', 'Player 6'], teamTwo: ['Player 11', 'Player 5'] },
              { court: 4, teamOne: ['Player 10', 'Player 4'], teamTwo: ['Player 2', 'Player 3'] },
            ],
          },
        ],
      },
      {
        players: 18,
        schedule: [
          {
            courts: [
              { court: 1, teamOne: ['Player 1', 'Player 10'], teamTwo: ['Player 2', 'Player 11'] },
              { court: 2, teamOne: ['Player 3', 'Player 12'], teamTwo: ['Player 4', 'Player 13'] },
              { court: 3, teamOne: ['Player 5', 'Player 14'], teamTwo: ['Player 6', 'Player 15'] },
              { court: 4, teamOne: ['Player 7', 'Player 16'], teamTwo: ['Player 8', 'Player 17'] },
            ],
          },
          {
            courts: [
              { court: 1, teamOne: ['Player 9', 'Player 18'], teamTwo: ['Player 1', 'Player 11'] },
              { court: 2, teamOne: ['Player 10', 'Player 12'], teamTwo: ['Player 2', 'Player 13'] },
              { court: 3, teamOne: ['Player 3', 'Player 14'], teamTwo: ['Player 4', 'Player 15'] },
              { court: 4, teamOne: ['Player 5', 'Player 16'], teamTwo: ['Player 6', 'Player 17'] },
            ],
          },
          {
            courts: [
              { court: 1, teamOne: ['Player 7', 'Player 18'], teamTwo: ['Player 8', 'Player 9'] },
              { court: 2, teamOne: ['Player 1', 'Player 12'], teamTwo: ['Player 11', 'Player 13'] },
              { court: 3, teamOne: ['Player 10', 'Player 14'], teamTwo: ['Player 2', 'Player 15'] },
              { court: 4, teamOne: ['Player 3', 'Player 16'], teamTwo: ['Player 4', 'Player 17'] },
            ],
          },
          {
            courts: [
              { court: 1, teamOne: ['Player 5', 'Player 18'], teamTwo: ['Player 6', 'Player 9'] },
              { court: 2, teamOne: ['Player 7', 'Player 8'], teamTwo: ['Player 1', 'Player 13'] },
              { court: 3, teamOne: ['Player 12', 'Player 14'], teamTwo: ['Player 11', 'Player 15'] },
              { court: 4, teamOne: ['Player 10', 'Player 16'], teamTwo: ['Player 2', 'Player 17'] },
            ],
          },
          {
            courts: [
              { court: 1, teamOne: ['Player 3', 'Player 18'], teamTwo: ['Player 4', 'Player 9'] },
              { court: 2, teamOne: ['Player 5', 'Player 8'], teamTwo: ['Player 6', 'Player 7'] },
              { court: 3, teamOne: ['Player 1', 'Player 14'], teamTwo: ['Player 13', 'Player 15'] },
              { court: 4, teamOne: ['Player 12', 'Player 16'], teamTwo: ['Player 11', 'Player 17'] },
            ],
          },
          {
            courts: [
              { court: 1, teamOne: ['Player 10', 'Player 18'], teamTwo: ['Player 2', 'Player 9'] },
              { court: 2, teamOne: ['Player 3', 'Player 8'], teamTwo: ['Player 4', 'Player 7'] },
              { court: 3, teamOne: ['Player 5', 'Player 6'], teamTwo: ['Player 1', 'Player 15'] },
              { court: 4, teamOne: ['Player 14', 'Player 16'], teamTwo: ['Player 13', 'Player 17'] },
            ],
          },
          {
            courts: [
              { court: 1, teamOne: ['Player 12', 'Player 18'], teamTwo: ['Player 11', 'Player 9'] },
              { court: 2, teamOne: ['Player 10', 'Player 8'], teamTwo: ['Player 2', 'Player 7'] },
              { court: 3, teamOne: ['Player 3', 'Player 6'], teamTwo: ['Player 4', 'Player 5'] },
              { court: 4, teamOne: ['Player 1', 'Player 16'], teamTwo: ['Player 15', 'Player 17'] },
            ],
          },
          {
            courts: [
              { court: 1, teamOne: ['Player 14', 'Player 18'], teamTwo: ['Player 13', 'Player 9'] },
              { court: 2, teamOne: ['Player 12', 'Player 8'], teamTwo: ['Player 11', 'Player 7'] },
              { court: 3, teamOne: ['Player 10', 'Player 6'], teamTwo: ['Player 2', 'Player 5'] },
              { court: 4, teamOne: ['Player 3', 'Player 4'], teamTwo: ['Player 1', 'Player 17'] },
            ],
          },
          {
            courts: [
              { court: 1, teamOne: ['Player 16', 'Player 18'], teamTwo: ['Player 15', 'Player 9'] },
              { court: 2, teamOne: ['Player 14', 'Player 8'], teamTwo: ['Player 13', 'Player 7'] },
              { court: 3, teamOne: ['Player 12', 'Player 6'], teamTwo: ['Player 11', 'Player 5'] },
              { court: 4, teamOne: ['Player 10', 'Player 4'], teamTwo: ['Player 2', 'Player 3'] },
            ],
          },
        ],
      },
  ];
  
  export default roundRobinTemplates;
  