import React, { useState, useContext } from "react";
import axiosInstance from "../../../axiosInstance";
import { useNavigate } from "react-router-dom";
import { InstitutionContext } from "../../../App";
import { useParams } from "react-router-dom";

export default function CreateGroupEvent() {
  const navigate = useNavigate();
  const { eventId } = useParams();
  const { institutionId } = useContext(InstitutionContext);
  const [selectedInstitutions, setSelectedInstitutions] = useState([]);

  const [currentStep, setCurrentStep] = useState(1);
  const [formData, setFormData] = useState({
    name: "",
    startdate: "",
    endate: "",
    location: "",
    description: "",
    cost: "",
    capacity: "", // Assuming this should be a string or number, not a boolean
    isdeleted: false,
    memberships: [],
    teamsize: "",
    eventtype: "",
    institutionId: institutionId, // Defaulting to context's institutionId
  });

  const totalSteps = 4; // Update this to reflect the total number of steps in your form

  const progressBarWidth = `${(currentStep / totalSteps) * 100}%`;

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
  
    if (type === "checkbox") {
      setFormData((prevFormData) => {
        const updatedMemberships = checked
          ? [...prevFormData.memberships, value]
          : prevFormData.memberships.filter(membership => membership !== value);
  
        return { ...prevFormData, memberships: updatedMemberships };
      });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };
  

  const nextStep = () => {
    if (currentStep < totalSteps) {
      setCurrentStep(currentStep + 1);
    }
  };

  const prevStep = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    }
  };

  const goToSummaryStep = () => {
    if (currentStep < totalSteps) {
      setCurrentStep(currentStep + 1);
    }
  };

  const formatDate = (datetimeStr) => {
    if (!datetimeStr) return "";

    // Create a date object from the datetime-local input value
    const dateObj = new Date(datetimeStr);

    // Options for the toLocaleString to display a more friendly date format
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };

    // Format the date object into a readable string
    return dateObj.toLocaleString("en-US", options);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    let selectedInstitutionIds;
    
    try {
      const response = await axiosInstance.get(`/group_event/${eventId}`);
      selectedInstitutionIds = response.data.institutionId;
      setSelectedInstitutions(selectedInstitutionIds);
      // console.log(selectedInstitutionIds); // This will log the correct, updated institutions
  
      // Construct the event data object using the formData state and the fetched institution IDs
      const eventData = {
        name: formData.name,
        startdate: formData.startdate,
        endate: formData.endate,
        location: formData.location,
        description: formData.description,
        capacity: Number(formData.capacity),
        cost: Number(formData.cost),
        count: 0, // Assuming 'count' is part of formData
        isdeleted: formData.isdeleted,
        memberships: formData.memberships.join(", "), // Assuming 'memberships' is an array in formData
        teamsize: Number(formData.teamsize),
        eventtype: formData.eventtype, // Assuming you still want to send this even if it's "Unnecessary"
        institutionId: selectedInstitutionIds, // Use the fetched IDs directly
      };
  
      // POST request to create the event
      const eventResponse = await axiosInstance.post(`/events`, eventData);

      const bracketId = eventResponse.data.id; // Assuming the response will have an 'id' for the created event
  
      const data = {
        groupeventid: eventId, // Assuming 'eventId' is part of formData
        eventid: bracketId,
      };
      
      // console.log(data);
      // POST request to create the ege-map-entry
      await axiosInstance.post(`/ege-map-entry`, data);
  
      // Navigate to the events page upon success
      navigate("/events");
    } catch (error) {
      console.error('There was an error!', error);
    }
  };
  
  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <div>
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700 mb-4"
            >
              What's your event called?
            </label>
            <input
              className="w-full px-5 py-3 text-gray-700 bg-gray-200 rounded"
              id="name"
              name="name"
              type="text"
              required
              placeholder="Enter Event Name"
              value={formData.name}
              onChange={handleChange}
            />
          </div>
        );
      case 2:
        return (
          <div>
            <label
              htmlFor="startdate"
              className="block text-sm font-medium text-gray-700 mb-4"
            >
              What time does your event start?
            </label>
            <input
              className="w-full px-5 py-3 text-gray-700 bg-gray-200 rounded"
              id="startdate"
              name="startdate"
              type="datetime-local"
              required
              placeholder="Start Date and Time"
              value={formData.startdate}
              onChange={handleChange}
            />
            <label
              htmlFor="endate"
              className="block text-sm font-medium text-gray-700 mt-4 mb-4"
            >
              What time does your event end?
            </label>
            <input
              className="w-full px-5 py-3 text-gray-700 bg-gray-200 rounded"
              id="endate"
              name="endate"
              type="datetime-local"
              required
              placeholder="End Date and Time"
              value={formData.endate}
              onChange={handleChange}
            />
          </div>
        );
      case 3:
        return (
          <div>
            <label
              htmlFor="location"
              className="block text-sm font-medium text-gray-700 mb-4"
            >
              Where is your event held?
            </label>
            <input
              className="w-full px-5 py-3 text-gray-700 bg-gray-200 rounded"
              id="location"
              name="location"
              type="text"
              required
              placeholder="Enter Event Location"
              value={formData.location}
              onChange={handleChange}
            />
            <label
              htmlFor="capacity"
              className="block text-sm font-medium text-gray-700 mt-4 mb-4"
            >
              What's the capacity?
            </label>
            <input
              className="w-full px-5 py-3 text-gray-700 bg-gray-200 rounded"
              id="capacity"
              name="capacity"
              type="number"
              required
              placeholder="Enter Capacity"
              value={formData.capacity}
              onChange={handleChange}
            />
            <label
              htmlFor="teamsize"
              className="block text-sm font-medium text-gray-700 mb-4 mt-4"
            >
              What's the team size?
            </label>
            <input
              className="w-full px-5 py-3 text-gray-700 bg-gray-200 rounded"
              id="teamsize"
              name="teamsize"
              type="number"
              required
              placeholder="Enter Team Size"
              value={formData.teamsize}
              onChange={handleChange}
            />
            <label
              htmlFor="cost"
              className="block text-sm font-medium text-gray-700 mb-4 mt-4"
            >
              What's the cost per team?
            </label>
            <input
              className="w-full px-5 py-3 text-gray-700 bg-gray-200 rounded"
              id="cost"
              name="cost"
              type="text"
              required
              placeholder="Enter Cost"
              value={formData.cost}
              onChange={handleChange}
            />
            <fieldset className="mb-4 mt-4">
              <legend className="block text-sm font-medium text-gray-700 mb-2">
                Select Membership Types
              </legend>
              <div className="flex gap-4">
                {["Basic", "Associate", "Premium"].map((type) => (
                  <label key={type} className="flex items-center">
                    <input
                      type="checkbox"
                      name="memberships"
                      value={type}
                      onChange={handleChange}
                      className="mr-2"
                    />
                    {type}
                  </label>
                ))}
              </div>
            </fieldset>
          </div>
        );
      case 4:
        return (
          <div>
            <label
              htmlFor="description"
              className="block text-sm font-medium text-gray-700 mb-4"
            >
              Give a short description for members (minimum 100 characters).
            </label>
            <textarea
              className="w-full px-5 py-3 text-gray-700 bg-gray-200 rounded"
              id="description"
              name="description"
              required
              minLength={100}
              placeholder="Enter a short description of the event"
              value={formData.description}
              onChange={handleChange}
              rows={5} // Set the number of rows to make the textbox bigger
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50">
      {/* Title */}
      <h1 className="text-2xl font-semibold py-4">Create Bracket</h1>

      <div className="w-full max-w-4xl bg-white rounded-lg shadow-md">
        {" "}
        {/* Increased width to max-w-4xl */}
        <div className="w-full h-2 bg-gray-200">
          <div
            className="h-2 bg-blue-600"
            style={{ width: progressBarWidth }}
          ></div>
        </div>
        <form className="p-8" onSubmit={handleSubmit}>
          <div className="mb-4">{renderStep()}</div>
          <div className="flex justify-between mt-6">
            {currentStep > 1 && (
              <button
                className="inline-flex items-center py-2 px-4 rounded-md text-sm font-medium text-gray-600 bg-gray-300 hover:bg-gray-400"
                type="button"
                onClick={prevStep}
              >
                <span className="material-icons">chevron_left</span>
                Prev
              </button>
            )}
            {currentStep < totalSteps ? (
              <button
                className="inline-flex items-center py-2 px-4 rounded-md text-sm font-medium text-white bg-blue-600 hover:bg-blue-700"
                type="button"
                onClick={goToSummaryStep} // Use the new function for the last "Next" button
              >
                Next
                <span className="material-icons">chevron_right</span>
              </button>
            ) : (
              <button
                className="inline-flex items-center py-2 px-4 rounded-md text-sm font-medium text-white bg-green-600 hover:bg-green-700"
                type="submit"
              >
                Submit
                <span className="material-icons">check</span>
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
}
