import React, { useContext } from 'react';
import { InstitutionContext } from "../../App"

const StyledCalendarComponent = () => {
  const { institutionId } = useContext(InstitutionContext);

  // Determine the calendar URL based on the institution ID
  const calendarUrl = institutionId === 'ASU'
    ? "https://embed.styledcalendar.com/#te7FrxuqJYL75T00bppU"
    : "https://embed.styledcalendar.com/#vZX6UYUPOBBprbjiGQY5";

  return (
    <div>
      <header className="mt-4 w-full py-8 text-left text-black text-4xl font-bold px-8">
        Calendar
      </header>
      <iframe
        src={calendarUrl}
        title="Styled Calendar"
        className="styled-calendar-container"
        style={{ width: '100%', border: 'none', minHeight: '700px' }} // Set a minimum height
        data-cy="calendar-embed-iframe"
      ></iframe>
    </div>
  );
};

export default StyledCalendarComponent;
