import React, { useEffect, useState } from "react";
import axios from "axios";
import axiosInstance from "../../../axiosInstance";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  Avatar,
  IconButton,
  Typography,
  Collapse,
  Grid,
  Container,
  CircularProgress,
  Button,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DeleteIcon from "@material-ui/icons/Delete"; // Import the DeleteIcon
import { useNavigate, useParams } from "react-router-dom";
import clsx from "clsx";
import moment from "moment";
import { InstitutionContext } from "../../../App";
import { useContext } from "react";

const images = [
  "image1.jpeg",
  "image2.jpeg",
  "image3.jpeg",
  "image4.jpeg",
  "image5.jpeg",
  "image6.jpeg",
];

function getRandomImage() {
  const randomIndex = Math.floor(Math.random() * images.length);
  return images[randomIndex];
}

function renderDescription(description) {
  return description.split("\n").map((line, index) => (
    <span key={index}>
      {line}
      <br />
    </span>
  ));
}

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    margin: "20px",
    backgroundColor: "#FFFFFF",
    borderRadius: "15px",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
    transition: "0.3s",
    "&:hover": {
      boxShadow: "0px 8px 30px rgba(0, 0, 0, 0.12)",
    },
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: "#4CABCE",
  },
  header: {
    textAlign: "center",
    marginTop: "30px",
    marginBottom: "0px",
    color: "#0D47A1",
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "80vh",
  },
  container: {
    paddingTop: "20px",
    paddingBottom: "50px",
    borderRadius: "15px",
  },
  buttons: {
    display: "flex",
    justifyContent: "center",
    marginBottom: "20px",
  },
  button: {
    margin: "10px",
    backgroundColor: "#0D47A1",
    color: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#4090bd",
    },
  },
  // Add new style for grayed-out card
  deletedCard: {
    opacity: 0.5,
  },
  deletedText: {
    textAlign: "center",
    marginTop: "30px",
    marginBottom: "40px",
    color: "#f44336", // Use a red color to indicate deletion
  },
}));

export default function EventPlayerView({ email_id }) {
  const classes = useStyles();
  const navigate = useNavigate();
  const { eventId } = useParams();
  const [expandedId, setExpandedId] = useState(-1);
  const [player, setPlayer] = useState();
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [registeredEvents, setRegisteredEvents] = useState([]);
  const [registrationTrigger, setRegistrationTrigger] = useState(false); // New state variable
  const { institutionId, permissions } = useContext(InstitutionContext);
  const [eventName, setEventName] = useState("");

  const handleExpandClick = (id) => {
    setExpandedId(expandedId === id ? -1 : id);
  };

  const handleRegisterAnotherTeam = async (event_id) => {
    try {
      const event = await axiosInstance.get(`/events/${event_id}`);
      const event_data = event.data;
      navigate(`/payment/${event_id}`);
      return;
    } catch (error) {
      // console.log(error);
    }
  };

  const handleViewTeams = (event_id) => {
    navigate(`/view-teams/${event_id}`);
  };

  const handleRegisterEvent = async (event_id) => {
    try {
      const response = await axiosInstance.get(`/players/email/${email_id}`);

      const player_id = response.data.id;
      // console.log(player_id);

      const isRegistered = registeredEvents.includes(event_id);

      if (isRegistered) {
        // If the event is registered, make a delete request to unregister
        await axiosInstance.delete(`/event/${event_id}/player/${player_id}?structure=INDIVIDUAL`);

        alert("You have successfully unregistered from this event!");

        const response = await axiosInstance.get(`/events/${event_id}?institutionId=${institutionId}`);

        const event_data = response.data;
        event_data.count = event_data.count - 1;

        await axiosInstance.put(`/events/${event_id}`, event_data);

      } else {
        const event = await axiosInstance.get(`/events/${event_id}`);

        const event_data = event.data;

        // console.log(event_data);
        if (event_data.cost === 0 && event_data.teamsize === 1) {
          // If the event is free, make a post request to register
          const data = {
            playerid: player_id,
            eventid: event_id,
            structure: "Individual",
          };
          // console.log(data);
          await axiosInstance.post(`/ep-map-entry`, data);
          alert("Congrats! You have successfully registered for this event!");

          event_data.count = event_data.count + 1;
          await axiosInstance.put(`/events/${event_id}`, event_data);
          } else {
          // If the event has a cost, navigate the user to the payment page
          navigate(`/payment/${event_id}`);
          return;
        }
      }

      // Set the registrationTrigger to trigger useEffect and fetch updated registered events
      setRegistrationTrigger(!registrationTrigger);
      navigate("/home");
    } catch (error) {
      // console.log(error);
    }
  };

  const isEventPast = (event) => {
    const eventStartTime = moment(event.startdate);
    return eventStartTime.isBefore(moment());
  };

  useEffect(() => {
    axiosInstance
      .get(`/group_event/${eventId}`)
      .then((response) => {
        setEventName(response.data.name);
        // console.log("eventName");
        setLoading(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });

    // On initial load, if the email_id is defined, store it in local storage
    if (email_id) {
      localStorage.setItem("email_id", email_id);
    }
  }, [email_id]);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const email = localStorage.getItem("email_id");
        // console.log(email); // Use the email_id from local storage
        // console.log(`${process.env.REACT_APP_API_URL}/players/email/${email}`);
        const playerResponse = await axiosInstance.get(
          `/players/email/${email}`
        );
        const playerMembership = playerResponse.data.membership_status;
        const response = await axiosInstance.get(
          `/events/group_event/${eventId}`
        );

        // Filter events based on membership status
        const filteredEvents = response.data.data.filter(
          (event) =>
            event.memberships.includes(playerMembership) &&
            !isEventPast(event) &&
            !event.isdeleted
        );

        setEvents(filteredEvents);
        setLoading(false);
      } catch (error) {
        console.error("There was an error!", error);
      }
    };
    fetchEvents();
  }, []);

  useEffect(async () => {
    // console.log("Logging");
    const response = await axiosInstance.get(
      `/players/email/${email_id}`
    );
    // console.log("Logging");
    setPlayer(response.data.membership_status);
    const player_id = response.data.id;
    axiosInstance
      .get(`/events/player/${player_id}`)
      .then((response) => {
        const registeredEventIds = response.data.data.map((event) => event.id);
        setRegisteredEvents(registeredEventIds);
      })
      .catch((error) => {
        // console.log("Error fetching registered events:", error);
      });
  }, [email_id, registrationTrigger]);

  if (loading) {
    return (
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    );
  }

  if (player == "Unconfirmed") {
    return (
      <div>
        Your account is still being confirmed by the club administrators. If you
        think this is an issue, please contact anindyasharma705@gmail.com
      </div>
    );
  }

  return (
    <div className="bg-gray-100 min-h-screen p-8">
      <header className="mt-4 text-4xl font-bold text-black mb-2 px-4 ">
        Brackets for {eventName}
      </header>

      <div className="container mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {events.map((event, index) => (
            <div
              key={event.id}
              className={`rounded-lg overflow-hidden shadow-lg ${
                event.isdeleted ? "opacity-50" : ""
              } p-4`}
            >
              {event.isdeleted && (
                <span className="text-red-500 text-xs italic">
                  This event has been deleted
                </span>
              )}
              <div className="flex items-center">
                <div
                  className={`rounded-full h-10 w-10 flex items-center justify-center bg-blue-500 text-white mr-4`}
                >
                  {event.name.charAt(0)}
                </div>
                <div className="py-4">
                  <h5 className="text-xl font-bold">{event.name}</h5>
                  <p className="text-sm text-gray-600">
                    Starts: {moment(event.startdate).format("MMMM Do, h:mm a")}
                  </p>
                </div>
              </div>
              <img
                className="w-full h-48 object-cover"
                src={getRandomImage()}
                alt={event.name}
              />

              <div className="p-4">
                <p className="text-gray-700 text-base">
                  {renderDescription(event.description)}
                </p>
                <p className="text-sm text-gray-600">
                  Ends: {moment(event.startdate).format("MMMM Do, h:mm a")}
                </p>
                <p className="text-sm text-gray-500">
                  Spots: {event.count} / {event.capacity}
                </p>
                <p className="text-sm text-gray-500">
                  Team Size: {event.teamsize}
                </p>
                <p className="text-sm text-gray-500">
                  Cost Per Team: ${event.cost}
                </p>
                <p className="text-sm text-gray-500">
                  Cost Per Player: ${event.cost / event.teamsize}
                </p>
                <div className="space-y-2 mt-4">
                  <div className="flex justify-between">
                    <button
                      className={`text-base ${
                        event.isdeleted ||
                        event.count >= event.capacity ||
                        (event.cost > 0 &&
                          registeredEvents.includes(event.id)) ||
                        (event.teamsize > 1 &&
                          registeredEvents.includes(event.id))
                          ? "bg-gray-400"
                          : "bg-blue-500 hover:bg-blue-700"
                      } text-white font-bold py-2 px-4 rounded`}
                      onClick={() => handleRegisterEvent(event.id)}
                      disabled={
                        event.isdeleted ||
                        event.count >= event.capacity ||
                        (event.cost > 0 &&
                          registeredEvents.includes(event.id)) ||
                        (event.teamsize > 1 &&
                          registeredEvents.includes(event.id))
                      }
                    >
                      {event.isdeleted
                        ? "Deleted"
                        : event.count >= event.capacity
                        ? "Event Full"
                        : registeredEvents.includes(event.id) &&
                          event.cost === 0 &&
                          event.teamsize === 1
                        ? "Unregister"
                        : registeredEvents.includes(event.id)
                        ? "Registered"
                        : "Register"}
                    </button>

                    {event.teamsize !== 1 && (
                      <button
                        className="text-base bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                        onClick={() => handleViewTeams(event.id)}
                      >
                        View Teams
                      </button>
                    )}
                  </div>
                  {!event.isdeleted &&
                    event.teamsize !== 1 &&
                    registeredEvents.includes(event.id) && (
                      <div className="flex justify-center">
                        <button
                          className="text-base bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 mt-3 rounded"
                          onClick={() => handleRegisterAnotherTeam(event.id)}
                        >
                          Register Another Team
                        </button>
                      </div>
                    )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
