import React from 'react';
import styled, { keyframes } from 'styled-components';

const rainbow = keyframes`
  0%{color: orange;}
  14%{color: purple;}
  28%{color: red;}
  42%{color: CadetBlue;}
  57%{color: yellow;}
  71%{color: coral;}
  85%{color: green;}
  100%{color: orange;}
`

const VideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
//   height: 100vh;
`;

const RainbowText = styled.h1`
  font-size: 2.5em;
  animation: ${rainbow} 5s linear infinite;
  text-align: center;
  padding-bottom: 20px;
`;
function YoutubeVideo() {
    return (
      <VideoContainer>
        <RainbowText>Let's Pickleball!!!</RainbowText>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/zuqXCvQYc40"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        ></iframe>
      </VideoContainer>
    );
  }
  
  export default YoutubeVideo;
