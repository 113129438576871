import React from 'react';

export default function EmbeddedApps({ url }) {
  // Inline CSS styles
  const iframeContainerStyle = {
    marginTop: '0px', // This value should be equal to the height of your Navbar
    height: 'calc(100vh - 0px)', // Adjust this if your Navbar height is different
    width: '100%', // Full width
    overflow: 'hidden', // Prevents scrollbars if the iframe content is too large
    border: 'none', // Removes the border
  };

  return (
    <div style={{ paddingTop: '-10px' }}> {/* This pushes the iframe down by the height of the Navbar */}
      <iframe
        src={url}
        style={iframeContainerStyle}
        title="Embedded App"
        frameBorder="0" // Removes the border around the iframe
      ></iframe>
    </div>
  );
}
