import React, { useState } from 'react';
import axios from 'axios';
import {
  Typography,
  Grid,
  Paper,
  TextField,
  makeStyles,
  Box,
  Button,
} from '@material-ui/core';
import { useContext } from 'react';
import { InstitutionContext } from '../../../App';

const useStyles = makeStyles((theme) => ({
  matchPaper: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    backgroundColor: '#D3D3D3 '  ,
  },
  playerName: {
    fontWeight: 'bold',
  },
  scoreInput: {
    width: '80px',
    marginRight: theme.spacing(2),
  },
  court: {
    backgroundColor: '#ffffff',
    border: '1px solid #000',
    position: 'relative',
    height: '300px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  courtEndLeft: { 
    width: '37.5%',
    backgroundColor: '#3CB371    ',
    border: '1px dashed #000',
    padding: theme.spacing(0.5),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    position: 'relative',
  },
  courtEndRight: {
    width: '37.5%',
    backgroundColor: '#3CB371    ',
    border: '1px dashed #000',
    padding: theme.spacing(0.5),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    position: 'relative',
  },
  midLine: {
    position: 'absolute',
    width: '99%',
    height: '1px',
    backgroundColor: '#000',
    top: '50%',
  },
  net: {
    width: '2px',
    height: '100%',
    backgroundColor: '#000',
    position: 'absolute',
    top: '0',
    left: '50%',
  },
  playerBox: {
    textAlign: 'left',
    paddingLeft: '5px',
  },
  courtMiddle: { // New class
    width: '25%',
    backgroundColor: '#87CEEB    ', // Change this color as needed
  },
  rightPlayerBox: {
    textAlign: 'right',
  },
  scoreBox: {
    backgroundColor: '#fff',
    padding: theme.spacing(0.5),
  },
  endMatchButton: {
    marginTop: theme.spacing(2),
  },
  endedMatch: {
    backgroundColor: theme.palette.success.light,
  },
}));

const DoublesMatch = ({ match, index, handleScoreChange }) => {
  const classes = useStyles();
  const [isMatchEnded, setIsMatchEnded] = useState(false);
  const [team1Score, setTeam1Score] = useState('');
  const [team2Score, setTeam2Score] = useState('');
  const [isScoreValid, setIsScoreValid] = useState(true);
  const {institutionId, permissions} = useContext(InstitutionContext);


  const handleEndMatch = async () => {
    if (team1Score === '' || team2Score === '') {
      setIsScoreValid(false);
      return;
    }
  
    const team1ScoreNum = parseInt(team1Score);
    const team2ScoreNum = parseInt(team2Score);
    const winner = team1ScoreNum > team2ScoreNum ? match.team1 : match.team2;
    const winnerNames = team1ScoreNum > team2ScoreNum ? `${match.team1Name}` : 
      `${match.team2Name}`;
    const score = `${team1ScoreNum}-${team2ScoreNum}`;
  
    const result = {
      team1: {
        "player1": match.team1.player1.id,
        "player2": match.team1.player2.id
      },
      team2:{
        "player1": match.team2.player1.id,
        "player2": match.team2.player2.id
      },
      score: score,
      winner: {
        "player1": winner.player1.id,
        "player2": winner.player2.id
      },
      date: new Date().toISOString(),
      location: 'ASU Pickleball Courts', // Update with the actual location if needed
      status: 'COMPLETED',
      team1Name: `${match.team1Name}`,
      team2Name: `${match.team2Name}`,
      winnerName: winnerNames,
      institutionId: institutionId
    };

    // console.log(result)
    try {
      const response = await axios.put(`https://picknats-staging.azurewebsites.net/doubles_matches/${match.id}`, result);
      // console.log(response.data);
      setIsMatchEnded(true);
    } catch (error) {
      console.error(error);
    }    
  };
  

  const handleTeam1ScoreChange = (event) => {
    setTeam1Score(event.target.value);
    handleScoreChange(index, 'team1', event);
    setIsScoreValid(true);
  };

  const handleTeam2ScoreChange = (event) => {
    setTeam2Score(event.target.value);
    handleScoreChange(index, 'team2', event);
    setIsScoreValid(true);
  };

  return (
    <Grid item xs={12}>
      <Paper
        elevation={3}
        className={`${classes.matchPaper} ${isMatchEnded && classes.endedMatch}`}
      >
        <Typography variant="h6" align="center">
          Game {index + 1}
        </Typography>
        <Box className={classes.court}>
          <Box className={classes.courtEndLeft}>
            <div className={classes.midLine} />
            <Box className={classes.playerBox}>
              <Typography variant="h6" className={classes.playerName}>
                {match.team1Name}
              </Typography>
              <TextField
                label="T1"
                variant="outlined"
                className={classes.scoreInput}
                onChange={handleTeam1ScoreChange}
                disabled={isMatchEnded}
                error={!isScoreValid && team1Score === ''}
                helperText={!isScoreValid && team1Score === '' ? 'Score is required' : ''}
              />
            </Box>
          </Box>
          <Box className={classes.courtMiddle} /> 
          <Box className={classes.net} />
          <Box className={classes.courtEndRight}>
            <div className={classes.midLine} />
            <Box className={classes.rightPlayerBox}>
              <Typography variant="h6" className={classes.playerName}>
                {match.team2Name}
              </Typography>
              <TextField
                label="T2"
                variant="outlined"
                className={classes.scoreInput}
                onChange={handleTeam2ScoreChange}
                disabled={isMatchEnded}
                error={!isScoreValid && team2Score === ''}
                helperText={!isScoreValid && team2Score === '' ? 'Score is required' : ''}
              />
            </Box>
          </Box>
        </Box>
        {!isMatchEnded && (
          <Button
            variant="contained"
            color="primary"
            fullWidth
            className={classes.endMatchButton}
            onClick={handleEndMatch}
            style = {{
              backgroundColor: "#42A5F5"
            }}
          >
            End Match
          </Button>
        )}
      </Paper>
    </Grid>
  );  
};

export default DoublesMatch;
