import React, { useState } from 'react';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { useContext } from 'react';
import { InstitutionContext } from '../../App';
import axios from 'axios';
import { auth } from '../../firebase';
import { NavLink, useNavigate } from 'react-router-dom';
import InstitutionDropdown from '../InstitutionDropdown/InstitutionDropdown';
import {
  Button,
  TextField,
  Typography,
  Container,
  Grid,
  Link,
  Avatar,
  CssBaseline,
} from '@mui/material';
import SportsTennisIcon from '@mui/icons-material/SportsTennis';
import WebFont from 'webfontloader';
import axiosInstance from '../../axiosInstance';

const SignUp = () => {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const {institutionId, permissions} = useContext(InstitutionContext);
  const [universityCode, setUniversityCode] = useState('');


 const onSignUp = (e) => {
    e.preventDefault();
    // console.log(institutionId)
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (institutionId === 'ASU' && universityCode !== process.env.REACT_APP_ASU_ID) {
      // console.log(universityCode)
      // console.log(process.env.REACT_APP_ASU_ID)
      setError('Invalid university code.');
      return;
    }

    if (!emailRegex.test(email)) {
      setError('Invalid email format');
      return;
    }

    if (!firstName || !lastName) {
        setError('Please enter your first name and last name.');
        return;
    }
  
    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    createUserWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        // User registered successfully
        const user = userCredential.user;
        // You can use 'firstName' and 'lastName' here to store the names in your database
        const player = {
            firstName: firstName,
            middleInitials: 'N/A',
            lastName: lastName,
            email: email,
            rating: 2.0,
            win: '0',
            loss: '0',
            notes: 'No Notes',
            membership_status: 'Basic',
            institutionId: institutionId
        }

        // console.log(player);
        try {
          const response =  await axiosInstance.post(`/players`, player);   
          // console.log(player)
      
          // Delay navigation for 2 seconds
          setTimeout(() => {
              navigate('/home');
          }, 2000);
      } catch (error) {
          if (error.response && error.response.status === 409) {
              const response = await axiosInstance.put(`/players/email/${email}`, player);
              // console.log(response)
      
              // Delay navigation for 2 seconds
              setTimeout(() => {
                  navigate('/home');
              }, 5000);
          } else {
              setError("Our fault! " + error)
          }
      }
      
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        if (errorCode === 'auth/email-already-in-use') {
          setError('This email is already registered.');
        } else {
          setError(errorMessage);
        }
      });
  };

  WebFont.load({
    google: {
      families: ['Oswald:regular'],
    },
  });

  return (
    <div className="flex h-screen">  
      <div className="flex-1 flex justify-center items-center bg-gray-800">
        <div className="container mx-auto max-w-lg bg-white p-8 rounded-xl shadow-2xl">
          <div className="flex flex-col items-center">
            <div className="flex items-center">
                <img
                  src="/logo_without_name.png"
                  alt="Your Alt Text"
                  className="h-24 w-24"
                />{" "}
                {/* Adjust the size classes as needed */}
                <h1 style={{ letterSpacing: '2px', fontFamily: "'Baloo Thambi', sans-serif", fontSize: '48px' }}>
                PICKLE
                <span style={{ color: '#ffbd59' }}>U</span> {/* Replace '#FF0000' with the color you want */}
              </h1>              </div>

            <h2 className="text-2xl font-semibold mb-8 text-gray-700">
              Create an account!
            </h2>
            <form className="w-full">
              {/* First Name Field */}
              <InstitutionDropdown />
              
              <input
                className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="firstName"
                type="text"
                placeholder="First Name"
                autoComplete="given-name"
                onChange={(e) => setFirstName(e.target.value)}
              />
              {/* Last Name Field */}
              <input
                className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="lastName"
                type="text"
                placeholder="Last Name"
                autoComplete="family-name"
                onChange={(e) => setLastName(e.target.value)}
              />
              {/* Email Field */}
              <input
                className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="email"
                type="email"
                placeholder="Email Address"
                autoComplete="email"
                onChange={(e) => setEmail(e.target.value)}
              />
              {/* Password Field */}
              <input
                className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="password"
                type="password"
                placeholder="Password"
                autoComplete="new-password"
                onChange={(e) => setPassword(e.target.value)}
              />
              {/* Confirm Password Field */}
              <input
                className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                id="confirmPassword"
                type="password"
                placeholder="Confirm Password"
                autoComplete="new-password"
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              {institutionId === 'ASU' && (
                <input
                  className="appearance-none block w-full bg-gray-100 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                  id="universityCode"
                  type="text"
                  placeholder="Enter Club Code"
                  onChange={(e) => setUniversityCode(e.target.value)}
                />
              )}
              {error && (
                <p className="text-center text-red-500 mt-4">{error}</p>
              )}
              <button
                className="w-full bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-4 rounded focus:outline-none focus:shadow-outline transform transition duration-200 ease-in"
                onClick={onSignUp}
              >
                Sign Up
              </button>
              <div className="flex justify-center mt-4">
                <NavLink
                  to="/"
                  className="text-blue-400 hover:text-blue-200"
                >
                  Already have an account? Sign In
                </NavLink>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );  
};

export default SignUp;
