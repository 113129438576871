import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axiosInstance from '../../../axiosInstance'; // Adjust the path according to your project structure
import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  Avatar,
  IconButton,
  Typography,
  Collapse,
  Grid,
  Container,
  CircularProgress,
  Button,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DeleteIcon from "@material-ui/icons/Delete"; // Import the DeleteIcon
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import moment from "moment";
import { InstitutionContext } from "../../../App";
import { useContext } from "react";

const images = [
  "image1.jpeg",
  "image2.jpeg",
  "image3.jpeg",
  "image4.jpeg",
  "image5.jpeg",
  "image6.jpeg",
];

function getRandomImage() {
  const randomIndex = Math.floor(Math.random() * images.length);
  return images[randomIndex];
}

const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 640);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 640);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobile;
};

function renderDescription(description) {
  return description.split('\n').map((line, index) => (
    <span key={index}>
      {line}
      <br />
    </span>
  ));
}



const useStyles = makeStyles((theme) => ({
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "80vh",
  },toggleSwitch: {
    position: "relative",
    display: "inline-block",
    width: "60px",
    height: "34px",
    '& input': {
      opacity: 0,
      width: 0,
      height: 0,
    },
    '& .slider': {
      position: "absolute",
      cursor: "pointer",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "#ccc",
      transition: ".4s",
      borderRadius: "34px",
    },
    '& .slider:before': {
      position: "absolute",
      content: '""',
      height: "26px",
      width: "26px",
      left: "4px",
      bottom: "4px",
      backgroundColor: "white",
      transition: ".4s",
      borderRadius: "50%",
    },
    '& input:checked + .slider': {
      backgroundColor: "#2196F3",
    },
    '& input:focus + .slider': {
      boxShadow: "0 0 1px #2196F3",
    },
    '& input:checked + .slider:before': {
      transform: "translateX(26px)",
    },
    searchContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      flexWrap: 'wrap', // Allow items to wrap in smaller screens
    },
  
  },
}));


export default function Event() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [expandedId, setExpandedId] = useState(-1);
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const { institutionId, permissions } = useContext(InstitutionContext);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortByLatest, setSortByLatest] = useState(true);
  const [showFutureEventsOnly, setShowFutureEventsOnly] = useState(false);
  const isMobile = useIsMobile(); // Using custom hook


  
  


  const handleExpandClick = (id) => {
    setExpandedId(expandedId === id ? -1 : id);
  };

  // const handleDeleteEvent = (event_id) => {
  //   axios.delete(`https://picknats-staging.azurewebsites.net/events/${event_id}`)
  //     .then(response => {
  //       setEvents(prevEvents => prevEvents.map(event => {
  //         if (event.id === event_id) {
  //           return { ...event, isdeleted: true };
  //         }
  //         return event;
  //       }));
  //     })
  //     .catch(error => {
  //       console.error('Error deleting the event!', error);
  //     });
  // };

  useEffect(() => {
    let retryCount = 0;
    const GLOBAL_API_KEY = "your_global_api_key"; // Ideally, use process.env.YOUR_API_KEY_VARIABLE

  
    const fetchEvents = () => {
      console.log(institutionId)
      axiosInstance.get('/group_event', {
        params: { institutionId: institutionId }
      })     
        .then(response => {
          setEvents(response.data.data);
          setLoading(false);
        })
        .catch(error => {
          console.error("There was an error!", error);
          if (retryCount < 3) {
            retryCount++;
            console.log(`Retrying... Attempt ${retryCount}`);
            setTimeout(fetchEvents, 2000); // Retry after 2 seconds
          } else {
            // Handle the failure after maximum retries
            console.error("Max retries reached. Failed to fetch events.");
          }
        });
    };
  
    fetchEvents();
  }, [institutionId]);
  
  
  
  // Function to sort events
  const sortEvents = (eventsArray) => {
    return eventsArray.sort((a, b) => 
      sortByLatest ? new Date(b.startdate) - new Date(a.startdate) 
                   : new Date(a.startdate) - new Date(b.startdate)
    );
  };
  
  // Filter and sort events based on search query and sort order
  const toggleFutureEvents = () => {
    setShowFutureEventsOnly(!showFutureEventsOnly);
  };
  
  // Updated filtering and sorting logic
  const now = new Date();
  const filteredAndSortedEvents = sortEvents(
    events.filter(event => {
      const eventDate = new Date(event.startdate);
      return (!showFutureEventsOnly || eventDate >= now) &&
             (event.name.toLowerCase().includes(searchQuery) ||
              event.description.toLowerCase().includes(searchQuery));
    })
  );
  
  

  // Function to handle search input changes
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  // Function to toggle sort order
  const toggleSortOrder = () => {
    setSortByLatest(!sortByLatest);
  };

  // Filter events based on search query
  const filteredEvents = events.filter(event =>
    event.name.toLowerCase().includes(searchQuery) ||
    event.description.toLowerCase().includes(searchQuery)
  );

  if (loading) {
    return (
      <div className={classes.loading}>
        <CircularProgress />
      </div>
    );
  }

  return (
<div className="bg-gray-100 min-h-screen p-8">
  {/* Header */}
  <header className="text-4xl my-4 font-bold text-black mb-6">
    Events and Tournaments
  </header>

  {/* Search Bar */}
  <div className="flex items-center justify-between mb-4">
      <div className="flex flex-grow items-center space-x-4">
      <input
        type="text"
        className="border-2 w-searchmob border-gray-300 bg-white h-10 px-5 pr-16 rounded-lg text-sm focus:outline-none"
        placeholder="Search events..."
        value={searchQuery}
        onChange={handleSearchChange}
      />
      {!isMobile && (
            <>
              <div className="flex items-center space-x-2">
                <label className="toggle-switch">
                  <input type="checkbox" checked={sortByLatest} onChange={() => setSortByLatest(!sortByLatest)} />
                  <span className="slider"></span>
                </label>
                <span>Newest First</span>
              </div>
              
              <div className="flex items-center space-x-2 ml-4">
                <label className="toggle-switch">
                  <input type="checkbox" checked={showFutureEventsOnly} onChange={() => setShowFutureEventsOnly(!showFutureEventsOnly)} />
                  <span className="slider"></span>
                </label>
                <span>Only Future Events</span>
              </div>
            </>
          )}
      </div>

      <button
        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
        onClick={() => navigate("/create-group-event")}
      >
        Create
      </button>
    </div>


  {/* Event Cards Container */}
  <div className="container mx-auto">
    <div className="grid grid-cols-1 gap-4"> {/* Updated line */}
      {filteredAndSortedEvents.map((event, index) => (
        <div
          key={event.id}
          className="bg-white rounded-lg overflow-hidden shadow-lg"
        >
          <img
            className="w-full h-48 object-cover"
            src={`${process.env.PUBLIC_URL}/` + getRandomImage()} 
            alt={event.name}
          />
          <div className={`p-4 ${event.isdeleted ? "opacity-50" : ""}`}>
            {event.isdeleted && (
              <span className="text-red-500 text-xs italic">
                This event has been deleted
              </span>
            )}
            {!event.isdeleted && (
              <>
                <div className="font-bold text-xl mb-2">{event.name}</div>
                <p className="text-gray-700 text-base mb-4">
                  {renderDescription(event.description)}
                </p>
                <p className="text-gray-600 text-sm">
                  {moment(event.startdate).format("MMMM Do, YYYY, h:mm a")}
                </p>
                <div className="mt-4">
                  <button
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
                    onClick={() => navigate(`/brackets/${event.id}`)}
                  >
                    View Events
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  </div>

  {/* Create Event Button */}
  
</div>
  );
}