import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FaHome, FaCalendarAlt, FaUsers, FaExternalLinkAlt, FaSignOutAlt, FaBars } from 'react-icons/fa';
import logo from './logo_wout_nam.png'; // Adjust the path as per your project structure
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase'; // Adjust the path as per your project structure
import WebFont from "webfontloader";


const sidebarSections = {
  main: [
    { title: 'Home', path: '/home', icon: <FaHome /> },
    { title: 'Events', path: '/events', icon: <FaCalendarAlt /> },
    { title: 'Players', path: '/players', icon: <FaUsers /> },
    { title: 'Calendar', path: '/calendar', icon: <FaCalendarAlt /> },
  ],
  external: [
    { title: 'DUPR', path: '/dupr', icon: <FaExternalLinkAlt /> },
  ],
};



const SidebarItem = ({ item, onClick, shouldToggle }) => (
  <li className="flex items-center pl-4 py-2 hover:bg-gray-700 rounded-md">
    <Link to={item.path} className="flex items-center space-x-2 w-full text-white" onClick={() => shouldToggle && onClick()}>
      {item.icon}
      <span>{item.title}</span>
    </Link>
  </li>
);

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const isMobileDevice = () => window.innerWidth <= 768;

  const toggleSidebarIfMobile = () => {
    if (isMobileDevice()) {
      toggleSidebar();
    }
  };

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Oswald:regular', 'Baloo Thambi'] // Add 'Baloo Thambi' here
      },
    });
  }, []);


  useEffect(() => {
    const handleResize = () => {
      setIsSidebarOpen(window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set the initial state based on the current window width

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Logout Error:', error);
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const getCurrentPageTitle = () => {
    const route = sidebarSections.main.find(section => section.path === location.pathname);
    return route ? route.title : '';
  };


  return (
    <>
      {isSidebarOpen && isMobileDevice() && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-10"
          onClick={toggleSidebar}
        ></div>
      )}

      {!isSidebarOpen && (
        <div className="p-4 text-black fixed top-0 left-0 z-20 flex items-center">
          <button onClick={toggleSidebar} className="text-black">
            <FaBars />
          </button>
          <span className="ml-2">{getCurrentPageTitle()}</span>
        </div>
      )}

      <div className={`fixed top-0 left-0 w-custom h-screen bg-gray-800 text-white flex flex-col ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"} transition-transform duration-300 ease-in-out z-20`}>
        <div className="flex items-center p-4 border-b border-gray-700">
          <Link to="/home" className="flex items-center">
            <img src={logo} alt="PickleU Logo" className="h-12 w-12" />
            <h1 style={{ letterSpacing: '2px', fontFamily: "'Baloo Thambi', sans-serif", fontSize: '20px' }}>
                PICKLE
                <span style={{ color: '#ffbd59' }}>U</span> {/* Replace '#FF0000' with the color you want */}
              </h1>
          </Link>
          {isSidebarOpen && isMobileDevice() && (
            <button onClick={toggleSidebar} className="ml-auto">
              <FaSignOutAlt />
            </button>
          )}
        </div>

        {/* Sidebar items */}
        <ul>
          {sidebarSections.main.map((item, index) => (
            <SidebarItem key={index} item={item} onClick={toggleSidebarIfMobile} shouldToggle={isMobileDevice()} />
          ))}
        </ul>

        {/* External Apps Section */}
        <div className="mt-4">
          <span className="text-lg font-semibold px-4 py-2">External Apps</span>
          {sidebarSections.external.map((item, index) => (
            <SidebarItem key={index} item={item} onClick={toggleSidebar} />
          ))}
        </div>

        <div className="mt-auto p-4">
          <button onClick={handleLogout} className="w-full flex items-center justify-start pl-4 py-2 hover:bg-gray-700 rounded-md text-white">
            <FaSignOutAlt className="mr-2" />
            Logout
          </button>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
